import i18n from '../../../../i18n/config';
import { showToast } from '../../../utils/notifications';
import { isHybridFramework } from '../sealing/data';

export const defaultSealingOptionsData = {
    app_framework: '',
    service_version: '',
    always_latest: false,
    mode: 'test',
    app_type: 'NON_GAME',
    dex_encrypt: 'yes',
    use_query_all_packages: 'no',
    dex_string_encrypt: 'no',
    block_dex_dump: 'no',
    rooting: 'forbid',
    vmenv: 'forbid',
    block_work_profile: 'yes',
    block_usb_debugging: 'yes',
    macro: 'yes',
    sniff: 'yes',
    block_keylogger: 'no',
    block_external_launch: 'no',
    block_mirroring: 'no',
    block_remote_access: 'no',
    block_screen_capture: 'no',
    allow_emulator: [],
    allow_work_profiles_owner_app_name: [],
    hide_overlay_windows: 'no',
    data_sealing: 'no',
    use_ota_service: 'no',
    block_developer_options: 'no',
    wifi_security_protocol: 'disable',
};

export const defaultPresetForm = {
    presetName: '',
    presetDescription: '',
    ...defaultSealingOptionsData,
};

const toCamelCase = (str: string): string => {
    if (!/_([a-z])/.test(str)) {
        return str;
    }
    return str.replace(/_([a-z])/g, (match: string, p1: string) => p1.toUpperCase());
};

export const mapKeys = (obj: any) => {
    return Object.keys(obj).reduce((result: any, key) => {
        const newKey = toCamelCase(key);
        result[newKey] = obj[key];
        return result;
    }, {});
};

const camelToSnake = (camelCaseString: string): string => {
    return camelCaseString.replace(/([A-Z])/g, '_$1').toLowerCase();
};

export const convertKeysToSnakeCase = (obj: { [key: string]: any }, skipKeys: string[] = []): { [key: string]: any } => {
    return Object.keys(obj).reduce(
        (acc, key) => {
            const newKey = skipKeys.includes(key) ? key : camelToSnake(key);

            if (key === 'alwaysLatest') {
                acc[newKey] = obj[key] === 'true' ? true : false;
            } else {
                acc[newKey] = obj[key];
            }

            return acc;
        },
        {} as { [key: string]: any },
    );
};

export const createPresetOptions = (values: any, serviceables: any, userDetails: any, id?: string) => {
  const data = values;
  const { t } = i18n;

  if (data.vmenv === 'optional' && data.allow_emulator.length === 0) {
      showToast('error', t('please_select_allow_emulator'));
      return false;
  }

  if (data.block_work_profile === 'optional' && data.allow_work_profiles_owner_app_name.length === 0) {
      showToast('error', t('please_select_block_work_profile'));
      return false;
  }

  const isHybrid = isHybridFramework(data.app_framework);
  let allow_external_tool: any = [];
  let service_type = isHybrid ? 'HYBRID_AOS' : 'NATIVE_AOS';
  let block_environment: any = [];
  
  if (data.macro === 'yes') allow_external_tool.push('macro');
  if (data.sniff === 'yes') allow_external_tool.push('sniff');
  if (data.rooting === 'forbid') block_environment.push('rooting');
  if (data.vmenv === 'forbid' || data.vmenv === 'optional') block_environment.push('emulator');

  const args: any = {
      presetName: data.presetName,
      presetDescription: data.presetDescription,
      alwaysLatest: data.always_latest ?? false,
      userId: userDetails.user_id,
      framework: data.app_framework,
      blockWorkProfile: data.block_work_profile === 'optional' ? 'yes' : data.block_work_profile,
      serviceType: service_type,
      dexEncrypt: data.dex_encrypt,
      selectDexEncrypt: data.dex_encrypt === 'selective' ? 'yes' : 'no',
      allowExternalTool: allow_external_tool,
      allowEmulator: data.allow_emulator,
      allowWorkProfilesOwnerAppName: data.allow_work_profiles_owner_app_name,
      blockEnvironment: block_environment,
      mode: data.mode,
      appType: data.app_type,
      useQueryAllPackages: data.use_query_all_packages,
      blockUsbDebugging: data.block_usb_debugging,
      blockKeylogger: data.block_keylogger,
      blockScreenCapture: data.block_screen_capture,
      hideOverlayWindows: data.hide_overlay_windows,
      dataSealing: data.data_sealing,
      useOtaService: data.use_ota_service,
      dexStringEncrypt: data.dex_string_encrypt ?? 'no',
      blockDexDump: data.block_dex_dump ?? 'no',
      blockDeveloperOptions: data.block_developer_options,
      wifiSecurityProtocol: data.wifi_security_protocol,
      serviceVersion: data.service_version,
      blockMirroring: data.block_mirroring ?? 'no',
      blockRemoteAccess: data.block_remote_access ?? 'no',
  };

  if (id) {
      args['id'] = id;
  }
  return args;
};

export const getPresetOptions = (values: any) => {
  const presetOptions: any = {
    "allow_emulator": values.allow_emulator,
    "allow_work_profiles_owner_app_name": values.allow_work_profiles_owner_app_name,
    "always_latest": values.always_latest,
    "app_framework": values.framework,
    "app_type": values.app_type,
    "block_developer_options": values.block_developer_options,
    "block_dex_dump": values.block_dex_dump,
    "block_external_launch": values.block_external_launch ?? "no",
    "block_keylogger": values.block_keylogger,
    "block_mirroring": values.block_mirroring ?? "no",
    "block_remote_access": values.block_remote_access ?? "no",
    "block_screen_capture": values.block_screen_capture,
    "block_usb_debugging": values.block_usb_debugging,
    "block_work_profile": values.block_work_profile,
    "data_sealing": values.data_sealing,
    "dex_encrypt": values.dex_encrypt,
    "dex_string_encrypt": values.dex_string_encrypt,
    "hide_overlay_windows": values.hide_overlay_windows,
    "macro": values.allow_external_tool.includes('macro') ? "yes" : "no",
    "mode": values.mode,
    "rooting": values.block_environment.includes('rooting') ? "forbid" : "allow",
    "service_version": values.service_version,
    "sniff": values.allow_external_tool.includes('sniff') ? "yes" : "no",
    "use_ota_service": values.use_ota_service,
    "use_query_all_packages": values.use_query_all_packages,
    "vmenv": values.block_environment.includes('emulator') ? values.allow_emulator.length > 0 ? "optional" : "forbid" : "allow",
    "wifi_security_protocol": values.wifi_security_protocol,
    "presetName": values.presetName,
    "presetDescription": values.presetDescription 
  }

  return presetOptions;
}
