import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, IconButton, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import PresetTable from './PresetTable';
import PresetDrawer from './PresetDrawer';
import CliFooter from './CliFooter';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { createPresetOptions } from './PresetFunction';
import { deletePreset, savePreset } from '../../../../services/sealing/android';
import { showToast } from '../../../utils/notifications';
import { setPresets } from '../../../../store/sealing/android';

const SealingPreset: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [key, setKey] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [preset, setPreset] = useState<any>(null);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const presetData = useSelector((state: RootState) => state.androidSealingReducer.presets);
    const serviceables = useSelector((state: RootState) => state.androidSealingReducer.serviceables);
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);

    useEffect(() => {
        if (preset !== presetData) setPreset(presetData);
    }, [preset, presetData]);

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const handleClearClick = () => {
        setSearchValue('');
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
        if (drawerOpen) {
            setTimeout(() => {
                setKey(key + 1);
            }, 1000);
        }
    };

    const handleDelete = async (id: string, presetName: string) => {
        const response = await deletePreset(id, authContext.token);
        const updatedData = preset.filter((item: any) => item.id !== id);
        dispatch(setPresets(updatedData));
        if (response) {
            showToast('success', t('deleted_preset_successfully', { presetName: presetName }));
        } else {
            showToast('error', t('try_again_later'));
        }
    };

    const handleSave = async (values: any) => {
        const data = values;
        const args = createPresetOptions(data, serviceables, userDetails);
        const response = await savePreset(args, authContext.token);
        if (!response?.result) {
            showToast('error', t('try_again_later'));
        }
        if (response?.result?.code === '0000') {
            setDrawerOpen(false);
            dispatch(setPresets([response.data, ...preset]));
            showToast('success', t('created_preset_successfully'));
        } else if (response?.result?.code === '14000') {
            showToast('error', t('SEALING_PRESET_NAME_IS_DUPLICATED'));
        } else {
            showToast('error', t('try_again_later'));
        }
    };

    return (
        <div className="relative" style={{ minHeight: 'calc(100vh - 244px)' }}>
            <div className="flex lg:flex-nowrap flex-wrap gap-6 mb-8">
                <div className="w-full lg:max-w-[316px] text-2xl font-medium">{t('manage_preset')}</div>
                <div className="lg:mt-0 w-full max-w-[656px]">
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={searchValue}
                        onChange={onSearchChange}
                        label={t('search')}
                        placeholder={t('search_preset_name')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    {searchValue && (
                                        <IconButton aria-label="clear text" onClick={handleClearClick} size="small" edge="end">
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div>
                    <Button variant="contained" className="whitespace-nowrap" onClick={handleDrawerToggle}>
                        {t('create_preset')}
                    </Button>
                </div>
            </div>
            <div className="flex-grow mt-4 overflow-auto min-h-[500px]">
                <PresetTable searchValue={searchValue} onSave={handleSave} onDelete={handleDelete} />
            </div>
            <PresetDrawer key={key} onSave={handleSave} open={drawerOpen} onClose={handleDrawerToggle} />
            <div className="w-full pt-6 border-t border-gray-200">
                <CliFooter />
            </div>
        </div>
    );
};

export default SealingPreset;
