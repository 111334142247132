import { FilterField } from '../../../../type/manage';

export const EditJson = (data: InputJson, filename: string) => {
    return {
        service_version: data.service_version || '',
        dex_string_encrypt: data.dex_string_encrypt || '',
        block_dex_dump: data.block_dex_dump || '',
        allow_emulator: data.allow_emulator || '',
        allow_external_tool: data.allow_external_tool || '',
        allow_work_profiles_owner_app_name: data.allow_work_profiles_owner_app_name || '',
        app_framework: data.app_framework || '',
        app_packaging_format: data.app_packaging_format || '',
        app_type: data.app_type || '',
        user_id: data.user_id || '',
        block_developer_options: data.block_developer_options || '',
        block_screen_capture: data.block_screen_capture || '',
        block_usb_debugging: data.block_usb_debugging || '',
        core_version:  data.service_version || '',
        download_id: data.download_id || '',
        error_code: '0',
        hide_overlay_windows: data.hide_overlay_windows || '',
        hybrid_platform: data.hybrid_platform || '',
        option_allowEmulator: data.allow_emulator || '',
        option_allowWorkProfileList: data.allow_work_profiles_owner_app_name || '',
        option_appType: data.app_type || '',
        option_blockExternalLaunch: data.block_external_launch || '',
        option_blockKeyLogger: data.block_keylogger || '',
        option_blockMirroring: data.block_mirroring || '',
        option_blockRemoteAccess: data.block_remote_access || '',
        option_blockWorkProfile: data.block_work_profile || '',
        option_dataSealing: data.data_sealing || '',
        option_dexEncrypt: data.dex_encrypt || '',
        option_emulator: data.vmenv === 'forbid' ? 'Block' : 'Allow' || '',
        option_macro: data.macro || 'Allow',
        option_mode: data.mode || '',
        option_rooting: data.rooting === 'forbid' ? 'Block' : 'Allow' || '',
        option_selectDexEncrypt: data.select_dex_encrypt || '',
        option_sniff: data.sniff || 'Allow',
        option_useOtaService: data.use_ota_service || '',
        option_useQueryAllPackages: data.use_query_all_packages || '',
        original_file_name: filename || '',
        pack_id: data.pack_id || '',
        package_name: data.package_name || '',
        sealed_date: data.sealed_date || '',
        service_type: data.service_type || '',
        status_code: '',
    };
};

type InputJson = {
    user_id: string;
    sealed_date: string;
    status_code: string;
    allow_emulator: string;
    allow_external_tool: string;
    allow_work_profiles_owner_app_name: string;
    app_framework: string;
    app_packaging_format: string;
    app_type: string;
    block_developer_options: string;
    block_dex_dump: string;
    block_external_launch: string;
    block_keylogger: string;
    block_mirroring: string;
    block_remote_access: string;
    block_screen_capture: string;
    block_usb_debugging: string;
    block_work_profile: string;
    core_version: string;
    data_sealing: string;
    dex_encrypt: string;
    dex_string_encrypt: string;
    download_id: string;
    hide_overlay_windows: string;
    hybrid_platform: string;
    lang: string;
    macro: string;
    mode: string;
    pack_id: string;
    package_name: string;
    rooting: string;
    select_dex_encrypt: string;
    service_type: string;
    service_version: string;
    sniff: string;
    use_ota_service: string;
    use_query_all_packages: string;
    vmenv: string;
    wifi_security_protocol: string;
};

export const FilterFields: FilterField[] = [
    {
        name: 'period_from',
        label: 'from_period',
        type: 'datepicker',
        required: true,
        errorMessage: 'please_enter_from_period',
    },
    {
        name: 'period_to',
        label: 'to_period',
        type: 'datepicker',
        required: false,
    },
];
