import React, { useState } from "react";
import {
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Box,
  TextField,
  Autocomplete,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface Package {
  app_name: string;
  package_name?: string;
  bundle_id?: string;
  key?: string; 
}

interface DashboardProps {
  title: string;
  subtitle: string;
  platform: string;
  sealingMode?: string;
  period?: moment.Moment | null;
  selectedApp: string | null | undefined;
  packages: any[] | null;
  setSelectedApp: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
  setPeriod?: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  setSealingMode?: React.Dispatch<React.SetStateAction<"R" | "T">>;
  setPlatform: React.Dispatch<React.SetStateAction<"android" | "ios">>;
  refreshRate?: string;
  onRefreshRateChange?: (event: React.MouseEvent<HTMLElement>) => void;
}

const DashboardForm = ({
  title,
  subtitle,
  platform,
  sealingMode,
  period,
  selectedApp,
  packages,
  setSelectedApp,
  setSealingMode,
  setPeriod,
  setPlatform,
  refreshRate,
  onRefreshRateChange,
}: DashboardProps) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const getUniquePackages = (
    packages: Package[] | null,
    platform: string
  ): Package[] => {
    const uniqueKeys = new Set();
    return (packages || []).filter((packageItem) => {
      const key =
        platform === "android"
          ? packageItem.package_name
          : packageItem.bundle_id;
      return key && !uniqueKeys.has(key) ? uniqueKeys.add(key) : false;
    });
  };

  const filteredPackages = getUniquePackages(packages, platform);
  const handlePlatformChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlatform(event.target.value as "android" | "ios");
    setInputValue('')
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box className='w-full flex flex-wrap xl:flex-nowrap mb-10 gap-4'>
        <div>
          <p className='text-2xl font-medium mb-4'>{title}</p>
          <p className="whitespace-nowrap">{subtitle}</p>
        </div>
        <Box className='flex gap-5 flex-wrap w-full 1xl:flex-nowrap justify-between xl:justify-end'>
          {sealingMode && (
            <FormControl component='fieldset'>
              <p className='pb-2 font-medium'>{t("sealing_mode")}</p>
              <RadioGroup
                row
                className="flex-nowrap"
                value={sealingMode}
                onChange={(e) =>
                  setSealingMode && setSealingMode(e.target.value as "R" | "T")
                }
              >
                <FormControlLabel
                  value='T'
                  control={<Radio />}
                  label={t("test")}
                />
                <FormControlLabel
                  value='R'
                  control={<Radio />}
                  label={t("release")}
                />
              </RadioGroup>
            </FormControl>
          )}
          <FormControl component='fieldset'>
            <p className='pb-2 font-medium'>{t("operating_system")}</p>
            <RadioGroup
              row
              className="flex-nowrap"
              value={platform}
              onChange={(e) => {
                handlePlatformChange(e);
              }}
            >
              <FormControlLabel
                value='android'
                control={<Radio />}
                label={t("android")}
              />
              <FormControlLabel
                value='ios'
                control={<Radio />}
                label={t("ios")}
              />
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth size='small' className='w-full md:max-w-[320px]'>
            <p className='pb-2 font-medium'>{t("application")}</p>
            <Autocomplete
              freeSolo
              openOnFocus
              className='bg-primary'
              value={selectedApp}
              onChange={(event, newValue) => {
                setSelectedApp(
                  newValue
                    ? typeof newValue === "string"
                      ? newValue
                      : newValue.package_name || newValue.bundle_id
                    : null
                );
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={filteredPackages}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                return `${option.app_name} : ${platform === "android" ? option.package_name : option.bundle_id}`;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  placeholder={t("select_your_application")}
                />
              )}
            />
          </FormControl>
          {period && (
            <FormControl className='w-full max-w-full md:max-w-[210px]'>
              <p className='pb-2 font-medium'>{t("period")}</p>
              <DatePicker
                className='bg-primary'
                slotProps={{ textField: { size: "small" } }}
                views={["year", "month"]}
                openTo='month'
                value={period}
                format='YYYY-MM'
                disableFuture
                onChange={(newValue) => setPeriod && setPeriod(newValue)}
              />
            </FormControl>
          )}
          {refreshRate && (
            <FormControl component='fieldset'>
              <p className='pb-2 font-medium'>{t("refresh_rate")}</p>
              <ToggleButtonGroup
                value={refreshRate}
                exclusive
                onChange={(e) => onRefreshRateChange && onRefreshRateChange(e)}
                color='primary'
                className="bg-primary"
              >
                <ToggleButton value='1' className='h-[37px] text-xs'>
                  {t("1_min")}
                </ToggleButton>
                <ToggleButton value='5' className='h-[37px] text-xs'>
                  {t("5_min")}
                </ToggleButton>
                <ToggleButton value='10' className='h-[37px] text-xs'>
                  {t("10_min")}
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default DashboardForm;
