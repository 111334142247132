import * as React from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { IconButton } from '@mui/material';
import { userLogoutFunction } from '../logout';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
interface UserMenuProps {
  userDetails: any;
}

const UserMenu = ({userDetails} : UserMenuProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const getUserName = () => {
    const english = /^[A-Za-z0-9]*$/;
    const isEnglish = english.test(userDetails?.first_name);
    if (!isEnglish) {
      return userDetails?.first_name?.length > 2
        ? userDetails?.last_name.slice(0, 1)
        : userDetails?.first_name;
    } else {
      return (
        userDetails?.first_name.slice(0, 1) + userDetails?.last_name.slice(0, 1)
      );
    }
  };

  const onLogoutClick = () => {
    userLogoutFunction();
  };

  return (

      <div onMouseLeave={handleClose}>
        <IconButton
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className="capitalize rounded-full text-xs font-bold bg-accent text-white w-[32px] h-[32px] leading-[32px]"
        >
            {getUserName().length > 0 ? getUserName() : <PersonIcon fontSize='small'/>}
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          className='z-20'
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  'left top' 
              }}
            >
              <Paper className='min-w-[150px]'>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={() => navigate("/profile")}><PersonIcon fontSize='small' className='mr-3'/><span className='text-sm'>{t('profile')}</span></MenuItem>
                    <MenuItem onClick={onLogoutClick}><LogoutIcon fontSize='small' className='mr-3'/><span className='text-sm'>{t('logout')}</span></MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

  );
}

export default UserMenu;