import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  addWorkingItem,
  clearPublishedList,
  disableOTAServiceUpdates,
  getPublishedList,
  getWorkingItemList,
  publishedWorkingItems,
  removeWorkingItem,
} from "../../../../../services/securityservices";
import { RootState } from "../../../../../store";
import { useTranslation } from "react-i18next";
import Modal from "../../../../Feedback/Modal";
import TextField from "@mui/material/TextField";
import { Button } from "../../../../Foundation/Button";
import DataTable from "../../../../DataDisplay/Table";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Typography } from "@mui/material";
import { showDialog, showToast } from "../../../../utils/notifications";

interface CustomBlacklistInterface {
  visible: boolean;
  appData?: any;
  onCancel: (reloadData?: any) => void;
}

const CustomBlacklist = (props: CustomBlacklistInterface) => {
  const { t } = useTranslation();
  const [action, setAction] = useState<string>("");
  const [blockPackage, setBlockPackage] = useState<string>("");
  const [publishedListData, setPublishedListData] = useState<Array<any>>([]);
  const [customBlacklistData, setCustomBlacklistData] = useState<Array<any>>([]);
  const region = useSelector((state: RootState) => state.mainReducer.region);
  const authContext = useSelector(
    (state: RootState) => state.mainReducer.authContext
  );

  const fetchCustomBlacklistWorkItems = useCallback(async () => {
    const params = {
      token: authContext.token,
      package_name: props.appData.package_name,
    };
    const response = await getWorkingItemList(params, region);
    const publishedResponse = await getPublishedList(params, region);
    if (
      (response?.result?.code === "0000" &&
        Array.isArray(response?.CustomBlacklist)) ||
      (publishedResponse?.result?.code === "0000" &&
        Array.isArray(publishedResponse?.CustomBlacklist))
    ) {
      let data = Array.isArray(response?.CustomBlacklist)
        ? response?.CustomBlacklist
        : [];
      if (Array.isArray(publishedResponse?.CustomBlacklist)) {
        setPublishedListData(publishedResponse?.CustomBlacklist);
        publishedResponse?.CustomBlacklist.forEach((e: any) => {
          const index = data.findIndex(
            (item: any) =>
              item.blocked_package === e.blocked_package &&
              item.act_type === e.act_type
          );
          if (index >= 0) data[index] = { ...e };
          else data.push({ ...e });
        });
      }

      setCustomBlacklistData(response.CustomBlacklist);
    } else setCustomBlacklistData([]);
  }, [region, props.appData, authContext.token]);

  useEffect(() => {
    if (props.visible && props.appData) {
      fetchCustomBlacklistWorkItems();
    }
  }, [
    authContext,
    props.visible,
    props.appData,
    fetchCustomBlacklistWorkItems,
  ]);

  const onAddCustomBlacklistItemClick = async () => {
    const act_type = action;
    const blocked_package = blockPackage;
    if (props.appData) {
      const params = {
        token: authContext.token,
        act_type,
        blocked_package,
        package_name: props.appData.package_name,
      };

      if (
        customBlacklistData.find(
          (e: any) => e.blocked_package === blocked_package
        )
      ) {
        showToast("error", t("package_already_available_in_blacklist"));
        return;
      }

      const response = await addWorkingItem(params, region);
      if (
        response?.result?.code === "0000" &&
        Array.isArray(response?.CustomBlacklist)
      ) {
        let data = Array.isArray(response?.CustomBlacklist)
          ? response?.CustomBlacklist
          : [];
        if (Array.isArray(publishedListData)) {
          publishedListData.forEach((e: any) => {
            const index = data.findIndex(
              (item: any) =>
                item.blocked_package === e.blocked_package &&
                item.act_type === e.act_type
            );
            if (index >= 0) data[index] = { ...e };
            else data.push({ ...e });
          });
        }

        setCustomBlacklistData(response.CustomBlacklist);
        showToast("success", t("custom_blacklist_updated_successfully"));
        setBlockPackage("");
        setAction("");
      } else if (response?.result?.code)
        showToast(
          "error",
          t(
            `RCM-${response?.result?.code}`,
            t("error_while_updating_custom_blacklist")
          )
        );
      else showToast("error", t("error_while_updating_custom_blacklist"));
    }
  };

  const onDeleteCustomBlacklistItemClick = async (record: any) => {
    const { blocked_package, act_type, package_name } = record;
    if (props.appData) {
      const params = {
        token: authContext.token,
        act_type,
        blocked_package,
        package_name,
      };

      const response = await removeWorkingItem(params, region);
      if (response?.result?.code === "0000") {
        let data = Array.isArray(response?.CustomBlacklist)
          ? response?.CustomBlacklist
          : [];
        if (Array.isArray(publishedListData)) {
          publishedListData.forEach((e: any) => {
            const index = data.findIndex(
              (item: any) =>
                item.blocked_package === e.blocked_package &&
                item.act_type === e.act_type
            );
            if (index >= 0) data[index] = { ...e };
            else data.push({ ...e });
          });
        }
        setCustomBlacklistData(response.CustomBlacklist);
        showToast("success", t("custom_blacklist_updated_successfully"));
      } else if (response?.result?.code)
        showToast(
          "error",
          t(
            `RCM-${response?.result?.code}`,
            t("error_while_updating_custom_blacklist")
          )
        );
      else showToast("error", t("error_while_updating_custom_blacklist"));
    }
  };

  const onPublishClick = async () => {
    const publishItems = async () => {
      const params = {
        token: authContext.token,
        package_name: props.appData.package_name,
      };
      const response = await publishedWorkingItems(params, region);
      if (response?.result?.code === "0000") {
        fetchCustomBlacklistWorkItems();
        showToast("success", t("custom_blacklist_published_successfully"));
      } else if (response?.result?.code)
        showToast(
          "error",
          t(
            `RCM-${response?.result?.code}`,
            t("error_while_publishing_custom_blacklist")
          )
        );
      else showToast("error", t("error_while_publishing_custom_blacklist"));
    };

    showDialog({
      title: t("confirm_publish"),
      content: t("confirm_publish_msg"),
      onOk: publishItems,
      okText: t("yes"),
      cancelText: t("no"),
    });
  };

  const onClearPublishClick = async () => {
    const clearPublished = async () => {
      const params = {
        token: authContext.token,
        package_name: props.appData.package_name,
      };

      const response = await clearPublishedList(params, region);
      if (response?.result?.code === "0000") {
        fetchCustomBlacklistWorkItems();
        showToast(
          "success",
          t("published_custom_blacklist_cleared_successfully")
        );
      } else if (response?.result?.code)
        showToast(
          "error",
          t(
            `RCM-${response?.result?.code}`,
            t("error_while_clearing_published_custom_blacklist")
          )
        );
      else
        showToast(
          "error",
          t("error_while_clearing_published_custom_blacklist")
        );
    };

    showDialog({
      title: t("confirm_clear_publish"),
      content: t(
        "published_blacklist_will_be_removed_and_appsealing_default_blacklist_will_be_applied"
      ),
      onOk: clearPublished,
      okText: t("yes"),
      cancelText: t("no"),
    });
  };

  const onDisableOTAClick = () => {
    const stopOTAUpdates = async () => {
      const params = {
        token: authContext.token,
        package_name: props.appData.package_name,
      };

      const response = await disableOTAServiceUpdates(params, region);
      if (response?.result?.code === "0000") {
        fetchCustomBlacklistWorkItems();
        showToast("success", t("ota_updates_are_disabled_now"));
      } else if (response?.result?.code)
        showToast(
          "error",
          t(
            `RCM-${response?.result?.code}`,
            t("error_while_disabling_ota_updates")
          )
        );
      else showToast("error", t("error_while_disabling_ota_updates"));
    };

    showDialog({
      title: `${t("disable_ota_updates")}?`,
      content: t("confirm_ota_update"),
      onOk: stopOTAUpdates,
      okText: t("yes"),
      cancelText: t("no"),
    });
  };
  const customBlacklistHeader = () => (
    <div>
      <Typography variant="h1">
        {t("custom_blacklist")}
      </Typography>
      <span className='block font-light text-base'>
        {props.appData?.package_name}
      </span>
    </div>
  );

  const createRows = (data: any[]) => {
    const rows = data.map((item: any, index: number) => {
      return { ...item, id: index + 1 };
    });

    return rows;
  };

  const columns: GridColDef[] = [
    {
      headerName: t("package_name"),
      field: "blocked_package",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      headerName: t("action_type"),
      field: "act_type",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <span>
            {params.row.act_type === "KILL" ? t("kill_report") : t("report")}
          </span>
        );
      },
    },
    {
      headerName: t("action"),
      field: "action",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const onClick = () => {
          onDeleteCustomBlacklistItemClick(params.row as any);
        };
        return (
          <Button
            variant='outlined'
            color='error'
            className='w-[70px] h-[30px] text-xs'
            onClick={onClick}
            label={t("delete")}
          />
        );
      },
    },
  ];
  return (
    <Modal
      open={props.visible}
      maxWidth={"lg"}
      header={customBlacklistHeader()}
      handelCancel={props.onCancel}
    >
      <div className='flex flex-none gap-6 w-full items-center max-w-[544px] mt-5'>
        <FormControl className='min-w-[160px] flex-1' size='small'>
          <TextField
            className='h-[37px]'
            size="small"
            required
            placeholder={t("enter_package_name")}
            label={t("package_name")}
            name='blocked_package'
            onChange={(e) => setBlockPackage(e.target.value)}
            value={blockPackage}
          />
        </FormControl>
        <FormControl className='min-w-[160px] flex-1' size='small'>
          <InputLabel id='act_type'>{t("action_type")}</InputLabel>
          <Select
            className='h-[37px]'
            required
            labelId='act_type'
            value={action}
            label={t("action_type")}
            onChange={(e) => setAction(e.target.value as string)}
          >
            <MenuItem value='KILL' className="text-sm">{t("kill_report")}</MenuItem>
            <MenuItem value='REPORTONLY' className="text-sm">{t("report")}</MenuItem>
          </Select>
        </FormControl>
        <Button
          disabled={action.length === 0 && blockPackage.length === 0}
          onClick={onAddCustomBlacklistItemClick}
          label={t("add")}
        />
      </div>

      <div className='flex flex-wrap md:flex-nowrap justify-between w-full gap-8 py-8'>
        <div className='w-full md:w-1/2'>
          <div>
            <Button
              className={"w-full"}
              variant='outlined'
              onClick={onPublishClick}
              label={t("PUBLISH")}
            />
          </div>
          <div className='border border-[#bdbdbd] rounded-[4px] mt-7 relative p-6'>
            <span className='text-xs bg-white absolute left-3 top-[-10px] px-1'>
              {t("custom_blacklist_list")}
            </span>
            <DataTable
              columns={columns}
              rows={createRows(customBlacklistData)}
              pageSize={10}
              pageSizeOptions={[5, 10]}
              height={400}
            />
          </div>
        </div>
        <div className='w-full md:w-1/2'>
          <div className='flex flex-none gap-4'>
            <Button
              variant='outlined'
              className={"w-1/2"}
              color={"error"}
              label={"STOP LIVE UPDATE"}
              onClick={onDisableOTAClick}
            />
            <Button
              variant='outlined'
              className={"w-1/2"}
              color={"secondary"}
              label={"CLEAR PUBLISHED"}
              onClick={onClearPublishClick}
            />
          </div>
          <div className='border border-[#bdbdbd] rounded-[4px] mt-7 relative p-6'>
            <span className='text-xs bg-white absolute left-3 top-[-10px] px-1'>
              {t("published_blacklist_list")}
            </span>
            <DataTable
              columns={columns}
              rows={createRows(publishedListData)}
              pageSize={10}
              pageSizeOptions={[5, 10]}
              height={400}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomBlacklist;
