import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Button } from '../../../Foundation/Button';
import { createCredential, getIOSProjectList, updateProjectName } from '../../../../services/sealing/ios';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { filterBy } from '../../../../utility/filtering';
import { showDialog, showToast } from '../../../utils/notifications';
import LoadMask from '../../../utils/loadmask';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import '../index.less';
import SdkCard from './SDKCard';
import CreateNewProject from './project/CreateIosProject';
import CredentialInformation from './project/CredentialInformation';
import DownloadSdk from './project/DownloadSdk';
import UpgradeYourPlan from '../../../utils/upgradePlan';

const IOSSealing = () => {
    const [filters, setFilters] = useState<any>([]);
    const [filterText, setFilterText] = useState('');

    const [buildData, setBuildData] = useState<any>({});
    const [pageSize, setPageSize] = useState<number>(20);
    const [selectedApp, setSelectedApp] = useState<any>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [appData, setAppData] = useState<Array<any> | null>(null);
    const [isPlanValid, setIsPlanValid] = useState<boolean | null>(null);
    const [isHybridPlanValid, setIsHybridPlanValid] = useState<boolean | null>(null);
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
    const [isCredentialModalVisible, setIsCredentialModalVisible] = useState(false);

    const { t } = useTranslation();
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const planDetails = useSelector((state: RootState) => state.mainReducer.planDetails);

    const [showMask, setShowMask] = useState<any>({
        msg: t('verifying_plan_details_for_ios'),
    });

    useEffect(() => {
        if (planDetails) {
            setShowMask(null);
            if (planDetails.plan?.ios?.type === 'E' || planDetails.plan?.ios?.type === 'P' || planDetails.plan?.hybrid?.type === 'E' || planDetails.plan?.hybrid?.type === 'P') {
                if (planDetails.plan?.ios?.type === 'E' || planDetails.plan?.ios?.type === 'P') setIsPlanValid(true);
                else setIsPlanValid(false);
                if (planDetails.plan?.hybrid?.type === 'E' || planDetails.plan?.hybrid?.type === 'P') setIsHybridPlanValid(true);
                else setIsHybridPlanValid(false);
            } else {
                setIsPlanValid(false);
                setIsHybridPlanValid(false);
            }
        }
    }, [planDetails]);

    useEffect(() => {
        if (appData === null && (isPlanValid || isHybridPlanValid)) {
            const fetchAppData = async () => {
                const params = {
                    service_type: 'NATIVE_IOS',
                    token: authContext.token,
                };
                const response = await getIOSProjectList(params, region);
                if (response?.result?.code === '0000' && Array.isArray(response?.projectList)) setAppData(response.projectList);
            };
            setAppData([]);
            fetchAppData();
            setSelectedApp(null);
        }
    }, [region, appData, isPlanValid, isHybridPlanValid, authContext.token]);

    const onSearchClick = (e: any) => {
        const value = e.target.value;
        let filter: any = [];
        if (value) {
            filter = {
                logic: 'or',
                filters: [
                    { field: 'project_name', value: value, operator: 'contains' },
                    { field: 'bundle_id_prefix', value: value, operator: 'contains' },
                ],
            };
        }
        setFilterText(value);
        setFilters(filter);
    };
    const handleClearClick = () => {
        setFilterText('');
        setFilters([]);
    };

    const onCreateNewProjectClick = () => {
        setIsModalVisible(true);
    };

    const oniOSProjectClose = (e?: any) => {
        if (e && e.data && Array.isArray(e.data)) setAppData(e.data);
        setIsModalVisible(false);
    };

    const onDownloadSDKClick = (data: any) => {
        setSelectedApp(data);
        setIsDownloadModalVisible(true);
    };

    const onCheckCredentialClick = (data: any) => {
        data.token = authContext.token;
        onCreateCredentialClick(data);
    };

    const onCloseDownloadSDKModal = () => {
        setSelectedApp(null);
        setIsDownloadModalVisible(false);
    };

    const onCloseCredentialSDKModal = () => {
        setSelectedApp(null);
        setIsCredentialModalVisible(false);
    };

    const onSaveClick = (newTitle: string, project_name: string) => {
        const renameProject = async () => {
            const params = {
                projectName: newTitle,
                projectNameBefore: project_name,
                service_type: 'NATIVE_IOS',
                token: authContext.token,
            };
            const response = await updateProjectName(params, region);
            if (response?.result.code === '0000' && response?.result.message === 'SUCCESS') {
                setAppData(response?.projectList);
                showToast('success', t('project_name_updated_successfully'));
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_update_project_name')));
            else showToast('error', t('failed_to_update_project_name'));
        };

        showDialog({
            title: t('confirm_update'),
            content: t('do_you_really_want_to_update_project_name'),
            onOk: renameProject,
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    const onShowMoreClick = () => setPageSize((oldPageSize: number) => oldPageSize + 20);

    const onCreateCredentialClick = async (value: any) => {
        const response = await createCredential(value, region);
        if (response?.result?.code === '0000') {
            value = response.projectInfo;
            setIsCredentialModalVisible(true);
            setSelectedApp(value);
        } else if (response?.result?.code)
            showToast('error', t(`RCM-${response?.result?.code}`, response?.result?.message ? response?.result?.message : t('error_while_checking_credential')) as string);
        else showToast('error', t('error_while_checking_credential'));
    };

    return (
        <React.Fragment>
            {showMask && <LoadMask msg={showMask.msg} />}
            {isPlanValid === false && isHybridPlanValid === false && <UpgradeYourPlan />}
            {typeof isPlanValid === 'boolean' && typeof isHybridPlanValid === 'boolean' && (isPlanValid === true || isHybridPlanValid === true) && (
                <React.Fragment>
                    {isModalVisible && (
                        <CreateNewProject
                            type={'CreateIOSProject'}
                            visible={isModalVisible}
                            onCancel={oniOSProjectClose}
                            iosData={selectedApp}
                            buildData={buildData}
                            setBuildData={setBuildData}
                            isPlanValid={isPlanValid}
                            isHybridPlanValid={isHybridPlanValid}
                        />
                    )}

                    {isDownloadModalVisible && (
                        <DownloadSdk
                            type={'DownloadIOSProject'}
                            visible={isDownloadModalVisible}
                            onCancel={onCloseDownloadSDKModal}
                            iosData={selectedApp}
                            buildData={buildData}
                            setBuildData={setBuildData}
                            isPlanValid={isPlanValid}
                            isHybridPlanValid={isHybridPlanValid}
                        />
                    )}
                    {isCredentialModalVisible && <CredentialInformation type="CredentialIOSProject" visible={isCredentialModalVisible} onCancel={onCloseCredentialSDKModal} iosData={selectedApp} />}

                    <div className="h-full flex flex-col sm:flex-row ">
                        <div className="h-full flex flex-col w-full gap-4 pr-2 content-start flex-grow">
                            <div className="flex flex-col xl:flex-row gap-6 mb-8">
                                <div className="w-full lg:w-[316px] text-2xl font-medium whitespace-nowrap">{t('manage_projects')}</div>
                                <div className="lg:mt-0 w-full max-w-[656px]">
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        value={filterText}
                                        onChange={onSearchClick}
                                        placeholder={t('search_project_name_app_name_or_bundle_id')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {filterText && (
                                                        <IconButton aria-label="clear text" onClick={handleClearClick} size="small" edge="end">
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className="w-full max-w-full lg:max-w-[316px]">
                                    <Button variant="contained" className="h-[37px]" fullWidth onClick={onCreateNewProjectClick}>
                                        {t('create_new_project')}
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-wrap gap-6 items-end w-full">
                                    {appData &&
                                        (filterBy(appData, filters) || [])
                                            .slice(0, pageSize)
                                            .map((item: any, index: number) => (
                                                <SdkCard
                                                    key={index}
                                                    item={item}
                                                    building={buildData?.[item?.bundle_id_prefix]}
                                                    onDownloadSDKClick={() => onDownloadSDKClick(item)}
                                                    onCredentialClick={() => onCheckCredentialClick(item)}
                                                    onSaveClick={onSaveClick}
                                                />
                                            ))}
                                </div>
                            </div>

                            {appData?.length && (filterBy(appData, filters) || []).length > pageSize ? (
                                <div className="w-full text-center mt-6">
                                    <Button variant="text" onClick={onShowMoreClick}>
                                        {t('show_more')}
                                    </Button>
                                </div>
                            ) : appData?.length === 0 ? (
                                <div className="text-xl text-center py-52 mx-auto whitespace-pre">{t('please_apply_appsealing_first')}</div>
                            ) : (
                                (filterBy(appData, filters) || []).length === 0 && <div className="text-xl text-center py-52 mx-auto whitespace-pre">{t('no_results_found')}</div>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default IOSSealing;
