import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const Base64Key = 'QWJjZGVmZ2hpamtsbW5vcA==';

interface MainReducerInterface {
    authContext?: any,
    userDetails?: any,
    planDetails?: any,
    accountDetails?: any,
    userPreferences?: any,
    region: 'AWS_TOKYO' | 'AWS_MUMBAI',
    localPreference?: {
        sealingMode: 'R' | 'T',
        platform: 'android' | 'ios',
        android_pkg?: any,
        ios_pkg?: any,
        refreshRate?: '1' | '5' | '10',
        period?: any
    }
};

const initialState: MainReducerInterface = {
    authContext: null,
    userDetails: null,
    planDetails: null,
    accountDetails: null,
    userPreferences: null,
    region: 'AWS_TOKYO'
};

const MainReducerSlice = createSlice({
    name: 'mainReducer',
    initialState,
    reducers: {
        setRegion: (state, action: PayloadAction<'AWS_TOKYO' | 'AWS_MUMBAI'>) => {
            state.region = action.payload;
        },
        setAuthContext: (state, action: PayloadAction<any>) => {
            state.authContext = action.payload;
        },
        setAccountDetails: (state, action: PayloadAction<any>) => {
            state.accountDetails = { ...state.accountDetails, ...action.payload };
        },
        setUserDetails: (state, action: PayloadAction<any>) => {
            state.userDetails = { ...state.userDetails, ...action.payload };
        },
        setPlanDetails: (state, action: PayloadAction<any>) => {
            let data = action.payload;
            if (action.payload) {
                data = {
                    ...data,
                    plan: {
                        hybrid: {
                            type: data['hybrid'].billing_type,
                            isExpired: data['hybrid'].trial_expired === 'Y' && data['hybrid'].billing_type === 'T' ? true : false,
                        },
                        android: {
                            type: data['android'].billing_type,
                            isExpired: data['android'].trial_expired === 'Y' && data['android'].billing_type === 'T' ? true : false,
                        },
                        ios: {
                            type: data['ios'].billing_type,
                            isExpired: (data['ios'].billing_type !== 'P' && data['ios'].billing_type !== 'E') ? true : true
                        }
                    }
                };
            }
            state.planDetails = data;
        },
        setUserPreferences: (state, action: PayloadAction<any>) => {
            state.userPreferences = action.payload;
        },
        setLocalPreferences: (state, action: PayloadAction<any>) => {
            state.localPreference = { ...state.localPreference, ...action.payload };
        },
        userLogout: (state, action: PayloadAction<any>) => {
            state = initialState;
        }
    }
});

export const { setRegion, setAuthContext, setAccountDetails, setUserDetails, setPlanDetails, setUserPreferences, setLocalPreferences, userLogout } = MainReducerSlice.actions;

export { Base64Key };
export default MainReducerSlice.reducer;