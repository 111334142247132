export const getApiUrls = (): { apiUrl: string; inApiUrl: string } => {
    const {
        REACT_APP_API_URL,
        REACT_APP_DEV_API_URL,
        REACT_APP_TST_API_URL,
        REACT_APP_IN_API_URL,
        REACT_APP_DEV_IN_API_URL,
        REACT_APP_TST_IN_API_URL,
        REACT_APP_LOCAL_API_URL,
        REACT_APP_LOCAL_IN_API_URL,
    } = process.env;

    const hostname = window.location.hostname;
    let apiUrls = {};

    if (hostname.includes('dev') || hostname.includes('develop')) {
        apiUrls = {
            apiUrl: REACT_APP_DEV_API_URL,
            inApiUrl: REACT_APP_DEV_IN_API_URL,
        };
    } else if (hostname.includes('tst')) {
        apiUrls = {
            apiUrl: REACT_APP_TST_API_URL,
            inApiUrl: REACT_APP_TST_IN_API_URL,
        };
    } else if (hostname.includes('localhost')) {
        apiUrls = {
            apiUrl: REACT_APP_LOCAL_API_URL,
            inApiUrl: REACT_APP_LOCAL_IN_API_URL,
        };
    } else {
        apiUrls = {
            apiUrl: REACT_APP_API_URL,
            inApiUrl: REACT_APP_IN_API_URL,
        };
    }

    return apiUrls as { apiUrl: string; inApiUrl: string };
};
