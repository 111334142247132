import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { RootState } from '../../../store';
import { changePwd, getAccountsInformation, saveProfileInfo, setPreferences } from '../../../services/manage/accounts';
import { setUserDetails, setUserPreferences } from '../../../store/main';
import UserInfo from './profile/userInfo';
import TwoFactor from './profile/twoFactor';
import ChangePassword from './profile/changePassword';
import Preferences from './profile/preferences';
import ContentLayout from '../../Layout/Content';
import { useTranslation } from 'react-i18next';
import { FormState } from '../../../type/user';
import { showToast } from '../../utils/notifications';

const UserProfile = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const [showMask, setShowMask] = useState<{ msg: string } | null>(null);
    const { authContext, userDetails } = useSelector((state: RootState) => state.mainReducer);
    const userPreferences = useSelector((state: RootState) => state.mainReducer.userPreferences);
    const defaultFormValues = {
        userInfo: {
            first_name: userDetails.first_name,
            last_name: userDetails.last_name,
            mobile: userDetails.mobile,
        },
        password: {
            user_pw: '',
            new_user_pw: '',
            new_user_pw_confirm: '',
        },
        preferences: {
            lang_set: userPreferences.lang_set,
            timezone_code: userPreferences.timezone_code,
            view_sign_yn: userPreferences.view_sign_yn,
        },
    };

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty },
        getValues,
        setValue,
    } = useForm<FormState>({
        defaultValues: defaultFormValues,
    });

    useEffect(() => {
        if (userPreferences) {
            const lang = userPreferences.lang_set;
            setValue('preferences.lang_set', lang);
        }
    }, [i18n, setValue, userPreferences]);

    useEffect(() => {
        const fetchAccountInfo = async () => {
            if (authContext?.token) {
                const response = await getAccountsInformation({
                    token: authContext.token,
                });
                if (response?.result.code === '0000') {
                    dispatch(setUserDetails(response.user));
                    dispatch(setUserPreferences(response.preference));
                    reset({
                        userInfo: response.user,
                        preferences: response.preference,
                    });
                }
            }
        };
        fetchAccountInfo();
    }, [authContext, dispatch, reset]);
    const checkIfDirty = () => {};
    const onSubmit = async () => {
        setShowMask({ msg: t('updating_user_profile') });

        const userInfo = {
            first_name: getValues('userInfo.first_name'),
            last_name: getValues('userInfo.last_name'),
            mobile: getValues('userInfo.mobile'),
        };
        const password = {
            user_pw: getValues('password.user_pw'),
            new_user_pw: getValues('password.new_user_pw'),
            new_user_pw_confirm: getValues('password.new_user_pw_confirm'),
        };
        const preferences = {
            lang_set: getValues('preferences.lang_set'),
            timezone_code: getValues('preferences.timezone_code'),
            view_sign_yn: getValues('preferences.view_sign_yn'),
        };

        if (password.user_pw || password.new_user_pw || password.new_user_pw_confirm) {
            if (!password.new_user_pw || password.new_user_pw.length < 8 || password.new_user_pw !== password.new_user_pw_confirm) {
                setShowMask(null);
                showToast('error', t('password_criteria'));
                return;
            }
        }
        let successFlag = false;
        let errorShown = false;

        try {
            const tasks = [];
            let successCount = 0;
            let passwordChangeRequested = password.new_user_pw || password.new_user_pw_confirm || password.user_pw;
            const isUserInfoChanged = JSON.stringify(userInfo) !== JSON.stringify(defaultFormValues.userInfo);
            const isPreferencesChanged = JSON.stringify(preferences) !== JSON.stringify(defaultFormValues.preferences);

            if (isUserInfoChanged) {
                tasks.push(saveProfileInfo({ ...userInfo, registered_date: userDetails.registered_date, token: authContext.token }));
            }
            if (passwordChangeRequested) {
                tasks.push(changePwd({ ...password, token: authContext.token }));
            }
            if (isPreferencesChanged) {
                let updatePreferences = { ...preferences };
                tasks.push(setPreferences({ ...updatePreferences, token: authContext.token }));
            }

            const results = await Promise.allSettled(tasks);
            results.forEach((result) => {
                if (result.status === 'fulfilled' && result.value && result.value.result) {
                    if (result.value.result.code === '0000') {
                        successCount++;
                        if (!successFlag) {
                            if (isPreferencesChanged) {
                                const lang = preferences.lang_set;
                                localStorage.setItem('user-lang', lang === 'KR' ? 'kr_KR' : 'en_US');
                                i18n.changeLanguage(lang === 'KR' ? 'kr_KR' : 'en_US');
                            }

                            reset(
                                {
                                    ...getValues(),
                                    password: { user_pw: '', new_user_pw: '', new_user_pw_confirm: '' },
                                },
                                {
                                    keepErrors: false,
                                    keepDirty: false,
                                    keepIsSubmitted: false,
                                    keepTouched: false,
                                    keepIsValid: false,
                                    keepSubmitCount: false,
                                },
                            );
                        }
                    } else if (!errorShown) {
                        showToast('error', t(`RCM-${result.value.result.code}`, result.value.result.message as string));
                        errorShown = true;
                    }
                } else if (!errorShown && result.status === 'rejected' && result.reason && result.reason.result && result.reason.result.code) {
                    showToast('error', t(`RCM-${result.reason.result.code}`, result.reason.result.message as string));
                    errorShown = true;
                }

                if (!errorShown && successCount === tasks.length) {
                    showToast('success', t('profile_information_updated_successfully'));
                    successFlag = true;
                }
            });

            setShowMask(null);
        } catch (error) {
            if (!errorShown) {
                showToast('error', t('failed_to_update_profile_information'));
            }
            setShowMask(null);
        }
    };

    return (
        <ContentLayout showMask={showMask} title={t('profile')} onClick={handleSubmit(onSubmit)} disabled={isDirty ? false : true} btnText={t('save')}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <UserInfo userDetails={userDetails} control={control} />
                <TwoFactor />
                <ChangePassword control={control} />
                <Preferences control={control} />
            </form>
        </ContentLayout>
    );
};

export default UserProfile;
