import React, { useEffect, useState } from 'react';
import { Drawer, Button, TextField, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EnvironmentConfiguration from '../sealing/EnvironmentConfiguration';
import ToolsAndPackages from '../sealing/ToolsAndPackages';
import { appFrameworks, isHybridFramework } from '../sealing/data';
import { AppURL } from '../../../../utility/AppURL';
import { openHelpCenter } from '../../../../services/main';
import AdvancedDetection from '../sealing/AdvancedDetection';
import BasicConfiguration from '../sealing/BasicConfiguration';
import { getServiceables } from '../../../../services/sealing/android';
import { setServiceables } from '../../../../store/sealing/android';
import { defaultPresetForm, getPresetOptions } from './PresetFunction';
import { getCommonEl, onFieldsChanges } from '../sealing/SealingFunction';

interface PresetDrawerProps {
    open: boolean;
    onSave: (data: any) => void;
    onClose: () => void;
    preset?: any;
}

const PresetDrawer: React.FC<PresetDrawerProps> = ({ open, onSave, onClose, preset = false }) => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const [nativePlanType, setNativePlanType] = useState<string | null>('E');
    const [hybridPlanType, setHybridPlanType] = useState<string | null>('E');
    const [sealingData, setSealingData] = useState<any>([]);
    const [fileType, setFileType] = useState('apk');
    const [formData, setFormData] = useState<any>(null);
    const [disableAccordion, setDisableAccordion] = useState(true);
    const serviceables = useSelector((state: RootState) => state.androidSealingReducer.serviceables);
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);
    const planDetails = useSelector((state: RootState) => state.mainReducer.planDetails);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const region = userDetails?.default_service_region;
    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: defaultPresetForm,
    });

    useEffect(() => {
        const getAppFramework = getValues('app_framework');
        const getServiceVersion = getValues('service_version');
        if (getAppFramework && getServiceVersion) setDisableAccordion(false);
        if (!getAppFramework || !getServiceVersion) setDisableAccordion(true);
    }, [formData]);

    useEffect(() => {
        if (preset && formData === null) {
            let data: any = getPresetOptions(preset);
            const isHybrid = ['REACT_NATIVE', 'ICONIC', 'CORDOVA'].includes(data.app_framework);
            const serviceVersions = (serviceables?.serviceVersions || []).filter((e) => e.type === (isHybrid ? 'hybrid' : 'native'));
            if (serviceVersions?.length) {
                const minVersion = appFrameworks.find((e) => e.detail === data.app_framework)?.minVersion;
                let sealingVersions = (serviceables?.serviceVersions || []).filter((e) => e.type === (isHybrid ? 'hybrid' : 'native'));
                if (minVersion) sealingVersions = sealingVersions.filter((e) => e.service_version >= minVersion);
                setSealingData(sealingVersions);
            }

            reset(data);
            setFormData(data);
        } else if (formData === null) {
            setFormData(defaultPresetForm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preset, formData]);

    useEffect(() => {
        if (planDetails) {
            setNativePlanType(planDetails.plan?.android?.type || 'T');
            setHybridPlanType(planDetails.plan?.hybrid?.type || 'T');
        }
    }, [planDetails]);

    useEffect(() => {
        if (serviceables === null) {
            const fetchServiceables = async () => {
                try {
                    const params = {
                        token: authContext.token,
                    };
                    const data = await getServiceables(params, region);
                    dispatch(setServiceables(data));
                } catch (error) {
                    console.error('Failed to fetch serviceables:', error);
                }
            };
            fetchServiceables();
        }
    }, [region, serviceables, authContext.token, dispatch]);

    const handleReset = async () => {
        reset(preset ? preset : defaultPresetForm);
        setFormData(null);
    };
    const onSubmit = (data: any) => {
        if (data.app_framework.length === 0) {
            setError('app_framework', { message: t('app_framework_required') }, { shouldFocus: true });
            return;
        }
        if (data.service_version.length === 0) {
            setError('service_version', { message: t('service_version_required') }, { shouldFocus: true });
            return;
        }
        onSave(data);
    };
    const isOTADisabled = (framework: any) => {
        let disable = true;
        const isHybrid = isHybridFramework(framework);
        if (framework) {
            if (isHybrid && hybridPlanType === 'E') disable = false;
            if (!isHybrid && nativePlanType === 'E') disable = false;
        }
        return disable;
    };

    const onFieldsChange = (fields: any) => {
        onFieldsChanges({
            fields,
            isOTADisabled,
            formData,
            setValue,
            getValues,
            appFrameworks,
            serviceables,
            setSealingData,
            nativePlanType,
            hybridPlanType,
            setFileType,
            setFormData,
        });
    };
    const ModalHeader = (
        <div className="mb-[50px] flex md:flex-nowrap flex-wrap justify-between items-start gap-10">
            <div>
                <h2 className="text-2xl font-medium m-0 whitespace-nowrap">{t(preset ? 'edit_preset' : 'create_preset')}</h2>
                <p className="text-[#A0A0A1] text-xs">{t(preset ? 'edit_preset_details' : 'create_preset_details')}</p>
            </div>
            <div>
                <div className="flex justify-end gap-4">
                    <Button variant="outlined" color="secondary" onClick={handleReset}>
                        <RefreshIcon />
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={onClose} className="font-medium text-[13px]">
                        {t('cancel')}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" className="font-medium text-[13px]">
                        {t('save')}
                    </Button>
                </div>
            </div>
        </div>
    );

    const getEl = (data: any, type: string) => {
        return getCommonEl(data, formData, control, onFieldsChange, serviceables, planDetails, onHelpCenterClick);
    };

    const validateInput = () => {
        let data = {
            basicSetting: true,
            features: true,
        };
        return data;
    };
    const onHelpCenterClick = (article: string, section?: boolean, helpDeskOnly = false) => {
        let redirectURL = '';
        if (helpDeskOnly) {
            redirectURL = AppURL.helpCenter + (i18n.language === 'en_US' ? 'en-us' : 'ko');
        } else {
            redirectURL = AppURL.helpCenter + (i18n.language === 'en_US' ? 'en-us' : 'ko') + (section ? '/sections/' : '/articles/') + article;
        }
        openHelpCenter(authContext.token, redirectURL);
    };

    const isAndroidExpired = (type: string) => {
        let isExpired = false;
        type = type === 'hybrid' ? 'hybrid' : 'android';
        if (planDetails?.plan?.[type]?.type === 'T' && planDetails?.plan?.[type]?.isExpired) isExpired = true;
        return isExpired;
    };
    const isReleaseEnabled = (framework: any) => {
        let isRelease = false;
        const isHybrid = isHybridFramework(framework);
        if (framework) {
            if (isHybrid) {
                if (hybridPlanType === 'E' || hybridPlanType === 'P') isRelease = true;
            } else {
                if (nativePlanType === 'E' || nativePlanType === 'P') isRelease = true;
            }
        }
        return isRelease;
    };
    return (
        <Drawer anchor="right" open={open} onClose={onClose} transitionDuration={1000}>
            <div className="p-10 min-w-[800px]">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>{ModalHeader}</div>
                    <div>
                        <div className="mb-8">
                            <div>
                                <p className="text-primary font-medium mb-4">{t('preset_name')}</p>
                            </div>
                            <div className="w-full">
                                <Controller
                                    name="presetName"
                                    control={control}
                                    rules={{ required: t('preset_name_required'), pattern: /^[a-zA-Z0-9._-]+$/ }}
                                    render={({ field }) => (
                                        <TextField
                                            error={!!errors['presetName']}
                                            {...field}
                                            helperText={!!errors['presetName'] ? t('preset_name_required') : ''}
                                            fullWidth
                                            onChange={(e) => {
                                                if (!/^[a-zA-Z0-9._-]+$/.test(e.target.value)) {
                                                    setError('presetName', { message: t('preset_name_required') }, { shouldFocus: true });
                                                } else {
                                                    clearErrors('presetName');
                                                    setValue('presetName', e.target.value);
                                                }
                                                
                                            }}
                                            inputProps={{ maxLength: 50 }}
                                            placeholder={t('preset_name_placeholder')}
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="mb-[24px]">
                            <div>
                                <p className="text-primary font-medium mb-4">{t('description')}</p>
                            </div>
                            <div className="w-full">
                                <Controller
                                    name="presetDescription"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            inputProps={{ maxLength: 200 }}
                                            fullWidth
                                            placeholder={t('preset_name_desc_placeholder')}
                                            variant="outlined"
                                            size="small"
                                            multiline
                                            minRows={5}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Accordion
                            key="panel-0"
                            expanded={formData ? true : false}
                            sx={{
                                boxShadow: 'none',
                                border: 'none',
                                '&:before': {
                                    display: 'none',
                                },
                            }}
                        >
                            <AccordionSummary className="border-none px-0" expandIcon={<ChevronRightIcon />} aria-controls="panel1-content" id="panel1-header">
                                <div className="flex items-center w-full">
                                    <span className="text-lg text-nowrap font-medium">{t('basic_configuration')}</span>
                                    <hr className="ml-4 block w-full" />
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    {formData && (
                                        <BasicConfiguration
                                            formData={formData}
                                            control={control}
                                            errors={errors}
                                            noTitle={true}
                                            onFieldsChange={onFieldsChange}
                                            validateInput={validateInput}
                                            isAndroidExpired={isAndroidExpired}
                                            isReleaseEnabled={isReleaseEnabled}
                                            fileType={fileType}
                                            sealingData={sealingData}
                                            serviceables={serviceables}
                                            getEl={getEl}
                                        />
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        {fileType !== 'zip' && (
                            <Accordion
                                key="panel-1"
                                disabled={disableAccordion}
                                sx={{
                                    boxShadow: 'none',
                                    border: 'none',
                                    '&:before': {
                                        display: 'none',
                                    },
                                }}
                            >
                                <AccordionSummary className="border-none px-0" expandIcon={<ChevronRightIcon />} aria-controls="panel1-content" id="panel1-header">
                                    <div className="flex items-center w-full">
                                        <span className="text-lg text-nowrap font-medium">{t('general_features')}</span>
                                        <span
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onHelpCenterClick('900000555906', false);
                                            }}
                                            className="uppercase text-nowrap underline text-accent mx-2"
                                        >
                                            {t('read_more')}
                                        </span>
                                        <hr className="block w-full" />
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {formData && (
                                        <div>
                                            <div>
                                                <EnvironmentConfiguration getEl={getEl} />
                                            </div>
                                            <div>
                                                <ToolsAndPackages getEl={getEl} />
                                            </div>
                                        </div>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        )}
                        {fileType !== 'zip' && (
                            <Accordion
                                key="panel-2"
                                disabled={disableAccordion}
                                sx={{
                                    boxShadow: 'none',
                                    border: 'none',
                                    '&:before': {
                                        display: 'none',
                                    },
                                }}
                            >
                                <AccordionSummary className="border-none px-0" expandIcon={<ChevronRightIcon />} aria-controls="panel2-content" id="panel2-header">
                                    <div className="flex items-center w-full">
                                        <span className="text-lg text-nowrap font-medium">{t('advanced_features')}</span>
                                        <span
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onHelpCenterClick('11601602478361', true);
                                            }}
                                            className="uppercase text-nowrap underline text-accent mx-2"
                                        >
                                            {t('read_more')}
                                        </span>
                                        <hr className="block w-full" />
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {formData && (
                                        <div className="w-full">
                                            <AdvancedDetection getEl={getEl} />
                                        </div>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </div>
                </form>
            </div>
        </Drawer>
    );
};

export default PresetDrawer;
