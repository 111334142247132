import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { sendAccountInvite } from "../../../../../services/manage/accounts";
import { RootState } from "../../../../../store";

import { useTranslation } from "react-i18next";
import { Button, Chip, Drawer, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DataTable from "../../../../DataDisplay/Table";
import moment from "moment";
import { showToast } from "../../../../utils/notifications";
interface InviteModalInterface {
  visible: boolean;
  inviteHistory: any;
  onCancel: () => void;
  updateHistoryDetails: () => void;
}

const InviteModal = (props: InviteModalInterface) => {
  const { t } = useTranslation();
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [isValidEmail, setEmailValidity] = useState<boolean>(false);
  const authContext = useSelector(
    (state: RootState) => state.mainReducer.authContext
  );

  useEffect(() => {
    setEmailAddress("");
    setEmailValidity(false);
  }, [props.visible]);

  const onEmailChange = (e: any) => {
    setEmailAddress(e.target.value);
    setEmailValidity(e.target.checkValidity());
  };

  const onInviteClick = async () => {
    if (!isValidEmail || !emailAddress) {
      showToast("error", t("please_enter_valid_email_address"));
      return;
    }
    const params = {
      email: emailAddress,
      token: authContext.token,
    };
    const response = await sendAccountInvite(params);
    if (response?.result?.code === "0000") {
      setEmailAddress("");
      setEmailValidity(false);
      showToast("success", t("account_invitation_sent_successfully"));
      props.updateHistoryDetails();
    } else if (response?.result?.code)
      showToast(
        "error",
        t(`RCM-${response?.result?.code}`, t("failed_to_send_account_invite"))
      );
    else showToast("error", t("failed_to_send_account_invite"));
  };
  const columns = [
    { field: "id", headerName: "ID", flex: 1, sortable: true },
    {
      field: "invite_timestamp",
      headerName: "Invitation Date",
      flex: 1,
      sortable: true,
      renderCell: (params: any) => {
        const formattedDate = moment(params.value, "YYYYMMDDHHmmss").format("YYYY/MM/DD HH:mm");
        return formattedDate;
      },
    },
    {
      field: "state",
      headerName: "State",
      width: 200,
      sortable: false,
      renderCell: (params: any) => {
        return params.row.is_signed_user === "Y" ? (
          <Chip
            icon={<CheckIcon className='text-white' />}
            label={t("signed_in_user")}
            className='bg-accent'
          />
        ) : (
          <Chip label={t("resend_invite")} className='bg-[#F5812D]' />
        );
      },
    },
  ];

  const rows =
    Array.isArray(props.inviteHistory) ?
    props.inviteHistory.map((item: any, index: number) => ({
      key: "invite_history_" + index,
      id: item.target_email,
      invite_timestamp: item.invite_timestamp,
      is_signed_user: item.is_signed_user,
    })) : null;

  return (
    <Drawer
      anchor='right'
      open={props.visible}
      onClose={props.onCancel}
    >
      <div className='p-10 max-w-[800px]'>
        <div className='mb-4'>
         <div className='mb-[50px] flex justify-between items-start gap-10'>
            <div>
              <h2 className='text-2xl font-medium m-0'>
                {t("invite_team_member")}
              </h2>
            </div>
            <div className='flex justify-end gap-2'>
              <Button className="h-10" color="secondary" variant="outlined" onClick={props.onCancel}>{t("cancel")}</Button>
              <Button
                onClick={onInviteClick}
                variant='contained'
                color='primary'
                className="h-10"
              >
                {t("invite")}
              </Button>
            </div>
          </div>

          <TextField
            fullWidth
            type='email'
            label={t("team_member_email_address")}
            placeholder={t("enter_email_address")}
            value={emailAddress}
            onChange={onEmailChange}
            required
            variant='outlined'
            className='mb-2 max-w-[336px]'
            size='small'
          />
        </div>
        <p className='mt-10 mb-5'>{t("invite_colleague_note")}</p>
        <div style={{ height: 400, width: "100%" }}>
          <DataTable
            rows={rows}
            columns={columns}
            pageSize={9}
            pageSizeOptions={[9, 5, 10, 20, 40]}
            disableColumnSelector={false}
            filter={false}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default InviteModal;
