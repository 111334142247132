import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Switch from "@mui/material/Switch";
import Chip from "../../../../Foundation/Chip";

interface IOSCardInterface {
  app_name: string;
  package_name: string;
  service_onoff: string;
  service_type?: string;
  service_mod_user_id?: string;
  service_mod_date?: string;
  hideTag?: boolean;
  onToggleSwitch: (type: string, params: any, cardDetails: any) => void;
}

const IOSServiceCard = (props: IOSCardInterface) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(props.service_onoff === "Y");
  const authContext = useSelector(
    (state: RootState) => state.mainReducer.authContext
  );
  const isOwner = authContext.role === 'Owner';
    
  useEffect(() => {
    setChecked(props.service_onoff === "Y");
  }, [props.service_onoff]);

  const onServiceChange = (e: any) => {
    const params = {
      api_ver: "4",
      platform: "ios",
      token: authContext.token,
      package_name: props.package_name,
      status: e.target.checked ? "Y" : "N",
    };

    props.onToggleSwitch("service", params, { ...props });
  };

  const CardTitle = () => {
    const tagName = props.service_type?.includes("HYBRID")
      ? "Hybrid"
      : "Native";
    return (
      <div className="flex flex-none items-center justify-between">
        <span className="text-base font-semibold truncate text">
          {props.app_name}
        </span>
        {props.service_type && props.hideTag !== true && (
          <Chip
            color={tagName.toLowerCase()}
            className="ml-2"
            label={t(tagName.toLowerCase().replaceAll(" ", "_"))}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <Card key={props.package_name} className="w-full max-w-full lg:max-w-[316px]">
        <CardHeader title={<CardTitle />} />
        <CardContent>
          <p className="flex justify-between text-sm">
            <span
              className="font-semibold mr-2 whitespace-nowrap"
              style={{ wordBreak: "keep-all" }}
            >
              {t("bundle_id")}{" "}
            </span>
            <Tooltip title={props.package_name}><span className="truncate">{props.package_name}</span></Tooltip>
          </p>
          <p className="flex justify-between mt-6">
            <span className="font-semibold mr-2 whitespace-nowrap">
              {t("updated_by")}
            </span>
            <Tooltip title={props.service_mod_user_id}>
              <span className="truncate">{props.service_mod_user_id}</span>
            </Tooltip>
          </p>
          <p className="flex justify-between mt-6">
            <span className="font-semibold mr-2 whitespace-nowrap">
              {t("updated_on")}
            </span>
            <span>{props.service_mod_date}</span>
          </p>
          <Tooltip title={ t('only_owner_access')}>
          <p className="flex justify-between mt-3">
            <span className="font-semibold mr-2 whitespace-nowrap flex items-center">
              {t("service")}{" "}
            </span>
           
              <Switch
                checked={checked}
                onChange={onServiceChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={!isOwner}
              />
            
          </p>
          </Tooltip>
        </CardContent>
      </Card>
    </>
  );
};

export default IOSServiceCard;
