import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const ActionMenu = ({ row, onDelete, onEdit }: { row: any, onDelete: (id:string, presetName:string) => void, onEdit: (data:any) => void  }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const handleEditClick = () => {
      handleClose();
      onEdit(row)
  };

  const handleDeleteClick = () => {
      handleClose();
      onDelete(row.id, row.presetName)
  };

  return (
      <div key={row.id}>
          <IconButton onClick={handleClick}>
              <MoreHorizIcon fontSize="small" />
          </IconButton>
          <Menu
              id="actionMenu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                  'aria-labelledby': 'actionMenu',
              }}
          >
              <MenuItem onClick={handleEditClick}>Edit</MenuItem>
              <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
          </Menu>
      </div>
  );
};

export default ActionMenu;