import { Box, Button } from "@mui/material";
import LoadMask from "../utils/loadmask";
import { useTranslation } from "react-i18next";

interface ContentLayoutProps {
  title?: string;
  showMask?: {
    msg: string;
  } | null;
  children: React.ReactNode;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  onClick?: (() => void) | null;
  disabled?: boolean;
  fullWidth?: boolean;
  btnText?: string | null;
  paddingNone?: boolean;
}

const ContentLayout = ({
  showMask = null,
  children,
  title = "",
  onSubmit,
  disabled = false,
  fullWidth = false,
  onClick,
  btnText,
  paddingNone = false,
}: ContentLayoutProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{ margin: '40px', minHeight: "calc(100vh - 159px)", bgcolor: "white" }}
      className={`${paddingNone ? "p-0 overflow-x-auto" : "p-10 min-w-[480px]"} border-[#E0E0E0] border rounded-[5px]`}
    >
      {showMask && <LoadMask msg={showMask.msg} />}
      <Box
        component={onSubmit ? "form" : "div"}
        className={`${fullWidth ? "w-full" : "max-w-[1250px]"}`}
        onSubmit={onSubmit ? onSubmit : undefined}
        sx={{ flexDirection: "column" }}
      >
        {title && (
          <div className='flex flex-none justify-between mb-[42px]'>
            <div className='font-medium text-2xl'>{t(title)}</div>
            {(onClick || onSubmit) && (
              <Button
                className='font-medium text-xs'
                onClick={onClick ? onClick : undefined}
                type={onSubmit ? "submit" : "button"}
                variant='contained'
                disabled={disabled}
              >
                {btnText}
              </Button>
            )}
          </div>
        )}

        {children}
      </Box>
    </Box>
  );
};

export default ContentLayout;
