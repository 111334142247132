import { Get, Post } from "../..";
import { AddSdkInfoInterface, DownloadSdkInterface, SdkInfoInterface, UpdateProjectNameInterface, downloadAismUserGuideInterface, downloadLatestSdkInterface, downloadLatestSdkLauncherInterface } from "../../../type/api/sealing";
import { AppURL, getRegionalURL, RegionalURL } from "../../../utility/AppURL";

export const addiOSProject = async (params: AddSdkInfoInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, service_type, project_name, bundle_id_prefix } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('bundleIdPrefix', bundle_id_prefix);
    urlParams.append('bundle_id_prefix', bundle_id_prefix);
    urlParams.append('projectName', project_name);
    urlParams.append('project_name', project_name);
    urlParams.append('service_type', service_type);
    urlParams.append('token', token);

    const response = await Post(
        getRegionalURL(RegionalURL.addproject, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const getNativeSDKInfo = async (params: SdkInfoInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, service_type } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('token', token);
    urlParams.append('service_type', service_type);

    const response = await Post(
        getRegionalURL(RegionalURL.nativesdkinfo, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const getHybridSDKInfo = async (params: SdkInfoInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, service_type } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('token', token);
    urlParams.append('service_type', service_type);

    const response = await Post(
        getRegionalURL(RegionalURL.hybridsdkinfo, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const getIOSProjectList = async (params: SdkInfoInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, service_type } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('token', token);
    urlParams.append('service_type', service_type);

    const response = await Post(
        getRegionalURL(RegionalURL.projectlist, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const updateProjectName = async (params: UpdateProjectNameInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { projectName, projectNameBefore, token, service_type } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('projectName', projectName);
    urlParams.append('projectNameBefore', projectNameBefore);
    urlParams.append('service_type', service_type);
    urlParams.append('token', token);

    const response = await Post(
        getRegionalURL(RegionalURL.updateprojectname, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const downloadNativeiOSSdk = async (params: DownloadSdkInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, bundleIdPrefix, buildTarget, sdkVersion } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('buildTarget', buildTarget);
    urlParams.append('bundleIdPrefix', bundleIdPrefix);
    urlParams.append('sdkVersion', sdkVersion);
    urlParams.append('token', token);
    urlParams.append('language', localStorage.getItem('user-lang') || 'en_US');

    const response = await Post(
        getRegionalURL(RegionalURL.buildiosnative, region), urlParams, { 'Accept': 'application/download' }, true
    ).catch(() => {
    });
    return response;
};

export const buildHybridiOSSdk = async (params: DownloadSdkInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, bundleIdPrefix, buildTarget, buildTargetVersion = '', sdkVersion } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('buildTarget', buildTarget);
    if (buildTarget === 'ReactNative')
        urlParams.append('buildTargetVersion', buildTargetVersion);
    urlParams.append('bundleIdPrefix', bundleIdPrefix);
    urlParams.append('sdkVersion', sdkVersion);
    urlParams.append('token', token);
    urlParams.append('language', localStorage.getItem('user-lang') || 'en_US');

    const response = await Post(
        getRegionalURL(RegionalURL.buildioshybrid, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const checkHybridiOSSdkStatus = async (params: { token: string, build_id: string }, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, build_id } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('build_id', build_id);
    urlParams.append('token', token);

    const response = await Post(
        getRegionalURL(RegionalURL.checkbuildioshybrid, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const downloadHybridiOSSdk = async (url: string) => {
    //window.open(url, '_blank');
    window.location.href = url;

};


export const createCredential = async (params: AddSdkInfoInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, project_name, bundle_id_prefix } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('bundleIdPrefix', bundle_id_prefix);
    urlParams.append('bundle_id_prefix', bundle_id_prefix);
    urlParams.append('projectName', project_name);
    urlParams.append('project_name', project_name);
    urlParams.append('token', token);

    const response = await Post(
        getRegionalURL(RegionalURL.getCredential, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
};

export const getAccountHash = async (token: string, region:'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let urlParams = new URLSearchParams();
    urlParams.append('token', token);
    const response = await Post(
        getRegionalURL(AppURL.getAccountHash, region), urlParams
    ).then(res => res.json()
    ).catch(() => {

    });
    return response;
}
export const getLatestVersionInfo = async(token: string, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let urlParams = new URLSearchParams();
    urlParams.append('token', token);
    const response = await Post(
        getRegionalURL(AppURL.getLatestVersionInfo, region), urlParams
    ).then(res => res.json()
    ).catch((err) => {
        console.log(err);
    });
    return response;
}

export const getReleasedVersionInfo = async(token: string, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let urlParams = new URLSearchParams();
    urlParams.append('token', token);
   
    const response = await Post(
        getRegionalURL(AppURL.getReleasedVersionInfo, region), urlParams
    ).then(res => res.json()
    ).catch((err) => {
        console.log(err);
    });
    return response;
}

export const downloadAismSdkLauncher = async (params: downloadLatestSdkLauncherInterface) => {
    const { type } = params;
    let urlParams = new URLSearchParams();
    urlParams.append('type', type);

    const response = await Post(
        AppURL.getDownloadSdkUrlLauncher, urlParams
    ).then(res => res.json()
    ).catch((err) => {
        console.log(err);
    });
    return response;
}


export const downloadAismSdk = async (params: downloadLatestSdkInterface) => {
    const { version, arch, region } = params;
    let urlParams = new URLSearchParams();
    urlParams.append('version', version);
    urlParams.append('arch', arch);
    const response = await Post(
        getRegionalURL(AppURL.getDownloadSdkUrl, region), urlParams
    ).then(res => res.json()
    ).catch((err) => {
        console.log(err);
    });
    return response;
}

export const downloadAismUserGuide = async (params: downloadAismUserGuideInterface) => {
    const { version, lang} = params;

    let urlParams = new URLSearchParams();
    urlParams.append('version', version);
    urlParams.append('lang', lang);

    const response = await Get(
        AppURL.getUserGuideUrl + "?" +  urlParams.toString()
    ).then(res => res.json()
    ).catch((err) => {
        console.log(err);
    });
    return response;
}