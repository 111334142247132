import React, { useEffect, useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "../../../Foundation/Button";
import { useTranslation } from "react-i18next";

interface SdkCardProps {
  item: {
    project_name: string;
    bundle_id_prefix: string;
  };
  building: boolean;
  onCredentialClick: () => void;
  onDownloadSDKClick: () => void;
  onSaveClick: (newTitle: string, project_name: string) => void;
}

const SdkCard = ({
  item,
  building,
  onDownloadSDKClick,
  onCredentialClick,
  onSaveClick,
}: SdkCardProps) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState('');
  const [editTitle, setEditTitle] = useState('');

useEffect(() => {
if(title !== item.project_name){
  setTitle(item.project_name);
}
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [item.project_name]);

  const handleEdit = () => {
    setEdit(true);
    setEditTitle(item.project_name);
  }

  const handleNameChange = (value: string) => {
    setEditTitle(value);
  };
  const handleSave = () => {
    onSaveClick(editTitle, title);
    setTitle(editTitle);
    setEdit(false);
  };

  const handleCancel = () => {
    setEditTitle(title);
    setEdit(false);
  };
  return (
    <div className="w-full lg:w-[316px]">
      <Card variant="outlined" className="w-full lg:w-[316px]">
        <React.Fragment>
          <CardContent>
            <div className="mb-3">
              <p className="text-[#A0A0A1] text-xs">{t("project")}</p>
              <div className="flex flex-none justify-between items-center">
                {edit ? (
                  <TextField
                    size="small"
                    variant="outlined"
                    value={editTitle}
                    onChange={(e) => handleNameChange(e.target.value)}
                    fullWidth
                  />
                ) : (
                  <Tooltip title={item.project_name}>
                    <p className="text-base truncate">{item.project_name}</p>
                  </Tooltip>
                )}

                {edit ? (
                  title === editTitle ? (
                    <IconButton
                      onClick={handleCancel}
                    >
                      <CancelIcon sx={{ padding: 0 }} fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleSave}>
                      <CheckIcon sx={{ padding: 0 }} fontSize="small" />
                    </IconButton>
                  )
                ) : (
                  <IconButton onClick={handleEdit}>
                    <EditIcon sx={{ padding: 0 }} fontSize="small" />
                  </IconButton>
                )}
              </div>
            </div>
            <div className="mb-6">
              <p className="text-[#A0A0A1] text-xs">{t("bundle_id_prefix")}</p>
              <Tooltip title={item.bundle_id_prefix}>
                <p className="text-base truncate">{item.bundle_id_prefix}</p>
              </Tooltip>
            </div>
          </CardContent>
          <CardActions>
            <Button
              key="1"
              variant="text"
              className="text-sm capitalize p-0 w-1/2"
              onClick={onCredentialClick}
            >
              {t("check_credential")}
            </Button>
            <span className="bg-[#D6D6D6] w-[1px] h-[24px]"></span>
            <LoadingButton
              key="2"
              className="text-sm capitalize p-0 w-1/2"
              loading={building}
              onClick={onDownloadSDKClick}
            >
              {building ? t("build_in_progress") : t("download_sdk")}
            </LoadingButton>
          </CardActions>
        </React.Fragment>
      </Card>
    </div>
  );
};

export default SdkCard;
