import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import CreateMfa from '../../../login/mfa/create';
import { showDialog, showToast } from "../../../utils/notifications";
import { editMfaInfo } from "../../../../services/login";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControlLabel, Radio } from "@mui/material";

const TwoFactor = () => {
  const { t } = useTranslation();
  const [mfaStatus, setMfaStatus] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const userPreferences = useSelector(
    (state: RootState) => state.mainReducer.userPreferences
  );
  const authContext = useSelector((state: RootState) => state.mainReducer.authContext)


  useEffect(() => {
    if (userPreferences) {
      let isMfaActive = userPreferences.is_active === "0" ? false : true;
      setMfaStatus(isMfaActive);
    }
  }, [userPreferences]);

  useEffect(() => {
    if(localStorage.getItem('appsealing_enable2FA')){
        setIsVisible(true);
    }
  }, [])

  const handleMfaActivation = (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e.target.value === "true"){ 
      setIsVisible(true)
    }else{
      showDialog({
        title: t("disable_mfa_title"),
        content: t("disable_mfa_desc"),
        okText: t("ok"),
        cancelText: t("cancel"),
        onOk: () => inactivateMfa(true),
        onCancel: () => setMfaStatus(true)
      });
    }
  }

  const inactivateMfa = async (isToast = false) => {
    try{
      const response = await editMfaInfo('inactive', authContext.token.toLocaleUpperCase());
      const { result } = response;
      if (result.code === "0000" || result.msg === "SUCCESS") {
        if(isToast){showToast('success', t('disable_mfa'))}
        setMfaStatus(false);
        setIsVisible(false);
        localStorage.removeItem('appsealing_enable2FA');
      }else{
        showToast('error', t(`RCM-${result.code}`));
        setIsVisible(false);
      }
    }catch(error){
      showToast('error', t('failed_to_validate_please_try_again_later'))
    }
  }
  useEffect(() => {
    const handleBeforeUnload = (event: { returnValue: string; }) => {
      if(isVisible){
        inactivateMfa();
        const confirmationMessage = t("mfa_unsaved");
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, t]);

  return (
    <div>
      {isVisible && <CreateMfa isVisible={isVisible} setIsVisible={setIsVisible} setMfaStatus={setMfaStatus} inactivateMfa={inactivateMfa} token={authContext.token}/>}
      <div className="pb-10 border-b border-gray-300 w-full mb-10 ml-1">
        <div className="font-bold text-base mb-4">{t("turn_on_mfa")}</div>
        <FormControl component="fieldset">
          <RadioGroup row name="twoFactorAuth" value={mfaStatus} onChange={(e) => handleMfaActivation(e)}>
            <FormControlLabel value={true} control={<Radio />} label={t("enable")} />
            <FormControlLabel value={false} control={<Radio />} label={t('disable')} />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default TwoFactor;
