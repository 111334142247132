import AppleIcon from '@mui/icons-material/Apple';
import AndroidOutlinedIcon from '@mui/icons-material/AndroidOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import {Button, Tooltip} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getPlanDetails } from "../../../../services/manage/billingdetails";
import { RootState } from "../../../../store";
import { setPlanDetails } from "../../../../store/main";
import {
  setBillingDetails,
  setCardDetails,
  setPlanDetails as setBillingPlanDetails,
} from "../../../../store/manage/billingdetails";
import LoadMask from "../../../utils/loadmask";
import AgreementModal from "./agreement";
import "./index.less";
import InquiryModal from "./inquiry";
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import { useTranslation } from "react-i18next";
import ContentLayout from "../../../Layout/Content";
import { planData } from "./planData";
import { showDialog, showToast } from '../../../utils/notifications';

const Plan = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isBillingValid, setIsBillingValid] = useState<boolean | null>(null);
  const [currentPlanData, setCurrentPlanData] = useState<any>(
    planData["android"]
  );
  const [platform, setPlatform] = useState<"android" | "ios">("android");
  const [planType, setPlanType] = useState<any>({ android: "T", ios: "T" });
  const [isInquiryModalVisible, setIsInquiryModalVisible] =
    useState<boolean>(false);
  const [isSubscribeModalVisible, setIsSubscribeModalVisible] =
    useState<boolean>(false);
  const [showMask, setShowMask] = useState<any>({
    msg: t("fetching_plan_details"),
  });

  const planDetails = useSelector(
    (state: RootState) => state.mainReducer.planDetails
  );
  const authContext = useSelector(
    (state: RootState) => state.mainReducer.authContext
  );

  useEffect(() => {
    let selectedKey = null;
    let path = location.pathname.split("/")?.[3] || "";
    Object.keys(planData).forEach((item) => {
      if (path === item) selectedKey = path;
    });
    if (selectedKey) {
      setPlatform(selectedKey);
      setCurrentPlanData(planData[selectedKey]);
    }
  }, [location]);

  useEffect(() => {
    if (authContext?.token && isBillingValid === null) {
      const fetchAccountInfo = async () => {
        let isValid = false;
        const { isAuth, ...params } = authContext;
        const response = await getPlanDetails(params);
        setShowMask(null);
        if (
          response?.result.code === "0000" &&
          response?.result.message === "SUCCESS"
        ) {
          isValid =
            response?.card &&
            response?.billing?.company &&
            response?.billing?.first_name &&
            response?.billing?.last_name &&
            response?.billing?.email &&
            response?.billing?.tel &&
            response?.billing?.country &&
            response?.billing?.state &&
            response?.billing?.address1 &&
            response?.billing?.zip_code;
        }
        setIsBillingValid(isValid);
      };
      fetchAccountInfo();
    }
  }, [authContext, planDetails, isBillingValid]);

  useEffect(() => {
    if (planDetails) {
      setIsBillingValid(null);
      setPlanType({
        android: planDetails.plan?.android?.type || "T",
        ios: planDetails.plan?.ios?.type || "T",
      });
    }
  }, [planDetails]);

  const GetEl = (data: any) => {
    let el = null;

    if (React.isValidElement(data.el)) el = data.el;
    else if (data.text)
      el = (
        <span className='text-xs lg:text-base'>
          {t(
            data.text
              .toLowerCase()
              .replace(/[.,'&-]/g, "")
              .replaceAll(" ", "_"),
            data.text as string
          )}
        </span>
      );
    else {
      if (data.enabled)
        el = <VerifiedUserOutlinedIcon fontSize="small" className="ml-2 text-sm lg:text-[20px] text-[#02F681]" />;
      else
        el = <HighlightOffOutlinedIcon className="ml-2 text-sm lg:text-[20px] text-infored"/>;
    }

    if (data.tooltip)
      el = (
        <Tooltip
          title={t(
            data.tooltip
              .toLowerCase()
              .replace(/[.,'&-]/g, "")
              .replaceAll(" ", "_"),
            data.tooltip as string
          )}
        >
          {el}
        </Tooltip>
      );

    return el;
  };

  const getButtonProps = (selected: boolean) => {
    if (selected) return { size: "small" as const, color: "primary" as const, variant: "contained" as const  };
    else return { size: "small" as const, color: "secondary" as const, variant: "contained" as const  };
  };

  const onSubcribeProfessionalClick = (e: any) => {
    const currentPlan = planType[platform];
    if (currentPlan !== "T") return;

    if (isBillingValid) setIsSubscribeModalVisible(true);
    else {
      showDialog({
        title: t("billing_information_missing"),
        content: t(
          "please_update_billing_details_and_payment_details_to_upgrade_service_plan"
        ),
        onOk: () => navigate("/billingdetails/info"),
        okText: t("ok"),
      });
    }
  };

  const onSubcribeEnterspiseClick = (e: any) => {
    const currentPlan = planType[platform];
    if (currentPlan === "E") return;
    setIsInquiryModalVisible(true);
  };

  const fetchAccountInfo = async () => {
    const { isAuth, ...params } = authContext;
    setShowMask({ msg: t("updating_account_information") });
    const response = await getPlanDetails(params);
    setShowMask(null);
    if (
      response?.result.code === "0000" &&
      response?.result.message === "SUCCESS"
    ) {
      dispatch(
        setPlanDetails({
          ...response.plan,
        })
      );
      dispatch(
        setBillingPlanDetails({
          ...response.plan,
        })
      );
      dispatch(
        setBillingDetails({
          ...response.billing,
        })
      );
      const cardDetails = response.card ? JSON.parse(response.card) : null;
      dispatch(setCardDetails(cardDetails));
    } else if (response?.result?.code)
      showToast(
        "error",
        t(
          `RCM-${response?.result?.code}`,
          t("failed_to_load_account_information")
        )
      );
    else {
      showToast("error", t("failed_to_load_account_information"));
    }
  };

  const onPlatformChange = (value = "android") => {
    setPlatform(value as "android" | "ios");
    setCurrentPlanData(planData[value]);
  };

  const handleCancel = () => setIsSubscribeModalVisible(false);
  const handleInquiryCancel = () => setIsInquiryModalVisible(false);

  return (
    <ContentLayout>
      <div
        className="grid w-full gap-4 content-start"
        tabIndex={-1}
      >
        <InquiryModal
          visible={isInquiryModalVisible}
          onCancel={handleInquiryCancel}
        />
        <AgreementModal
          visible={isSubscribeModalVisible}
          onCancel={handleCancel}
          platform={platform}
          fetchAccountInfo={fetchAccountInfo}
        />
        {showMask && <LoadMask msg={showMask.msg} />}
        <div>
        <div className="plan-header block lg:flex justify-between flex-none">
              <div className="w-full max-w-full lg:w-1/2 max-w-[320px]">
                <h3 className="mb-7 text-2xl font-medium">{t("plan_details")}</h3>
                <div>
                  <span className="text-left italic font-extralight">
                    {platform === "android"
                      ? t(
                          "sealed_android_application_can_be_released_on_play_store"
                        )
                      : platform === "ios"
                      ? t("ios_appsealing_is_not_available_with_the_trial_plan")
                      : t("sealed_hybrid_application_is_available_to_release")}
                  </span>
                </div>
              </div>
              <div className="flex justify-between w-full text-center p-5 pl-0">
                <div className="w-1/3">
                  <h3 className="mb-3 text-2xl font-medium">{t("trial")}</h3>
                  <div
                    className="text-center"
                    style={{ paddingBottom: "1rem", paddingTop: "0.8rem" }}
                  >
                    <Button
                      {...getButtonProps(planType[platform] === "T")}
                      className='text-xs lg:text-sm'
                      disabled={planType[platform] !== "T"}
                    >
                      {planType[platform] === "T"
                        ? t("current_plan")
                        : t("not_available")}
                    </Button>
                  </div>
                </div>
                <div className="w-1/3">
                  <h3 className="mb-3 text-2xl font-medium">{t("professional")}</h3>
                  <div
                    className="text-center"
                    style={{ paddingBottom: "1rem", paddingTop: "0.8rem" }}
                  >
                    <Button
                      {...getButtonProps(planType[platform] === "P")}
                      className='text-xs lg:text-sm'
                      disabled={planType[platform] === "E"}
                      onClick={onSubcribeProfessionalClick}
                    >
                      {planType[platform] === "P"
                        ? t("current_plan")
                        : planType[platform] === "T"
                        ? t("subscribe_now")
                        : t("not_available")}
                    </Button>
                  </div>
                </div>
                <div className="w-1/3">
                  <h3  className="mb-3 text-2xl font-medium">{t("enterprise")}</h3>
                  <div
                    className="text-center"
                    style={{ paddingBottom: "1rem", paddingTop: "0.8rem" }}
                  >
                    <Button
                      {...getButtonProps(planType[platform] === "E")}
                      className='text-xs lg:text-sm'
                      onClick={onSubcribeEnterspiseClick}
                    >
                      {planType[platform] === "E"
                        ? t("current_plan")
                        : t("contact_us")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          <div className="plan-table w-full"> 
            <div className="flex items-baseline plan-table-tabs">
              <div
                onClick={() => onPlatformChange("android")}
                className={`cursor-pointer w-1/2 text-base rounded-t-lg plan-platform-tab ${
                  platform === "android" ? "active" : ""
                }`}
              >
                <span className="block lg:hidden">
                  <AndroidOutlinedIcon />
                </span>
                <span className="hidden lg:block">{t("android_plan")}</span>
              </div>
              <div
                onClick={() => onPlatformChange("ios")}
                className={`cursor-pointer w-1/2 text-base rounded-t-lg plan-platform-tab ${
                  platform !== "android" ? "active" : ""
                }`}
              >
                <span className="block lg:hidden">
                  <AppleIcon />
                </span>
                <span className="hidden lg:block">{t("ios_plan")}</span>
              </div>
            </div>
            {currentPlanData.map((e: any) => {
              return (
                <div
                  className="table-row-group-item"
                  key={e.title}
                >
                  <div className="cell-1 min-w-[170px] text-xs lg:text-base text-left border-r-2 border-gray-100">
                    {t(e.title.toLowerCase().replaceAll(" ", "_"), e.title)}
                    {e.tooltip && (
                      <Tooltip
                        title={t(
                          e.tooltip
                            .toLowerCase()
                            .replaceAll(",", "")
                            .replaceAll(" ", "_"),
                          e.tooltip as string
                        )}
                      >
                        <HelpOutlineOutlinedIcon fontSize="small" className="ml-2 text-xs lg:text-sm" />
                      </Tooltip>
                    )}
                  </div>
                  <div className="border-r-2 border-gray-100 text-base">{GetEl(e.items.trial)}</div>
                  <div className="border-r-2 border-gray-100 text-base">{GetEl(e.items.professional)}</div>
                  <div className="border-r-2 border-white text-base">{GetEl(e.items.enterprise)}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};

export default Plan;
