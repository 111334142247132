import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { errorDialog, showToast } from '../../../utils/notifications';
import './index.less';
import { getAccountHash, getLatestVersionInfo, downloadAismUserGuide, downloadAismSdk, getReleasedVersionInfo } from '../../../../services/sealing/ios';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import Arm64Popup from './arm64Popup';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadMask from '../../../utils/loadmask';
import UpgradeYourPlan from '../../../utils/upgradePlan';
import { Button } from '@mui/material';

const Aism = () => {
    const { i18n, t } = useTranslation();
    const [copyToolTip, setCopyToolTip] = useState(t('click_to_copy'));
    const [latestVersion, setLatestVersion] = useState<string | null>(null);
    const [releasedVersion, setReleasedVersion] = useState<string | null>(null);
    const [appsealingId, setAppsealingId] = useState<null | string>(null);
    const [selectedKey, setSelectedKey] = useState<string>('');
    const [isPlanValid, setIsPlanValid] = useState<boolean | null>(null);
    const [isHybridPlanValid, setIsHybridPlanValid] = useState<boolean | null>(null);
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const planDetails = useSelector((state: RootState) => state.mainReducer.planDetails);
    const [showMask, setShowMask] = useState<any>({
        msg: t('verifying_plan_details_for_ios'),
    });

    useEffect(() => {
        if (planDetails) {
            setShowMask(null);
            if (planDetails.plan?.ios?.type === 'E' || planDetails.plan?.ios?.type === 'P' || planDetails.plan?.hybrid?.type === 'E' || planDetails.plan?.hybrid?.type === 'P') {
                if (planDetails.plan?.ios?.type === 'E' || planDetails.plan?.ios?.type === 'P') setIsPlanValid(true);
                else setIsPlanValid(false);
                if (planDetails.plan?.hybrid?.type === 'E' || planDetails.plan?.hybrid?.type === 'P') setIsHybridPlanValid(true);
                else setIsHybridPlanValid(false);
            } else {
                setIsPlanValid(false);
                setIsHybridPlanValid(false);
            }
        }
    }, [planDetails]);
    useEffect(() => {
        getVersionInfo();
        getReleasedInfo();
        getAppsealingId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDownloadSdk = async (type: string) => {
        let config =
            type === 'latest'
                ? {
                      version: latestVersion || '',
                      arch: 'arm64',
                      region,
                  }
                : {
                      version: releasedVersion || '',
                      arch: 'x64',
                      region,
                  };

        const response = await downloadAismSdk(config);
        if (response.result.code === '0000') {
            try {
                const newWindow = window.open(response.sdk_url, '_blank');
                if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                    onPopupError(response.sdk_url);
                }
            } catch (err) {
                onPopupError(response.sdk_url);
            }
        } else {
            showToast('error', t(`RCM-${response.result.code}`));
        }
    };

    const onPopupError = (urlDownload: string | undefined) => {
        errorDialog({
            title: t('popup_error_title'),
            content: (
                <div>
                    <span>{t('popup_error_message')}</span>
                    <a className="text-linkblue hover:underline" href={urlDownload} download>
                        {t('popup_error_message2')}
                    </a>
                    .
                </div>
            ),
            okText: t('ok'),
        });
    };

    const getVersionInfo = async () => {
        const response = await getLatestVersionInfo(authContext.token, region);
        if (response.latest_version_info) {
            setLatestVersion(response.latest_version_info.service_version);
        }
    };

    const getReleasedInfo = async () => {
        const response = await getReleasedVersionInfo(authContext.token, region);
        if (response.release_versions) {
            const highestVersion = response.release_versions.reduce((max: { seq: number }, current: { seq: number }) => (current.seq > max.seq ? current : max), response.release_versions[0]);
            setReleasedVersion(highestVersion.service_version);
        }
    };
    const getAppsealingId = async () => {
        const response = await getAccountHash(authContext.token, region);
        if (response.hash_id_256) {
            setAppsealingId(response.hash_id_256);
        }
    };

    const copyValueToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(appsealingId || '');
            setCopyToolTip(t('copied'));
            setTimeout(() => setCopyToolTip(t('click_to_copy')), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    const onChange = (key: string | string[]) => {
        setSelectedKey(key as string);
    };

    const downloadGuide = async () => {
        const config = {
            version: latestVersion || '',
            lang: i18n.language === 'kr_KR' ? 'KR' : 'EN',
        };
        const response = await downloadAismUserGuide(config);
        if (response.result.code === '0000') {
            try {
                const newWindow = window.open(response.sdk_url, '_blank');
                if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                    onPopupError(response.sdk_url);
                }
            } catch (err) {
                onPopupError(response.sdk_url);
            }
        } else {
            showToast('error', t(`RCM-${response.result.code}`));
        }
    };

    return (
        <>
            {showMask && <LoadMask msg={showMask.msg} />}
            {isPlanValid === false && isHybridPlanValid === false && <UpgradeYourPlan />}
            {typeof isPlanValid === 'boolean' && typeof isHybridPlanValid === 'boolean' && (isPlanValid === true || isHybridPlanValid === true) && (
                <Box className="grid gap-6 content-start">
                    <Box className="text-2xl font-medium">{t('aism_title')}</Box>
                    <Box className="max-w-[800px] text-sm">
                        <Box>
                            {t('aism_desc')}
                            <span onClick={downloadGuide} className="text-linkblue underline ml-1 cursor-pointer">
                                {t('aism_desc_link')}
                            </span>
                        </Box>
                        <Box className="mt-6">
                            <Box className="font-bold">{t('aism_scope_title')}</Box>
                            <Box>{t('aism_scope_desc')}</Box>
                        </Box>
                    </Box>

                    <Box className="flex gap-4 mt-4">
                        <Arm64Popup disabled={!latestVersion} onClick={onDownloadSdk} btnText={`Arm64 ${t('version')} ${latestVersion}`} />
                        <Button disabled={!releasedVersion} onClick={() => onDownloadSdk('released')} variant="contained" className="w-full max-w-[200px] font-bold uppercase">
                            {`x64 ${t('version')} ${releasedVersion}`}
                        </Button>
                    </Box>

                    <Box className="mt-12">
                        <Box className="flex items-center mb-2 cursor-pointer">
                            <Box className="font-bold text-sm mr-2">{t('appsealing_id')}</Box>
                            <Tooltip title={t('aism_appsealing_id_tooltip')}>
                                <HelpOutlineIcon fontSize="small" className="text-[#C2C2C2] text-base" />
                            </Tooltip>
                        </Box>
                        <Box className="cursor-pointer mb-8" onClick={copyValueToClipboard}>
                            <Tooltip title={copyToolTip}>
                                <>
                                    <IconButton>
                                        <ContentCopyIcon className="text-base text-[#5F6368] mr-1" />
                                    </IconButton>
                                    <span className="text-tiny">{appsealingId}</span>
                                </>
                            </Tooltip>
                        </Box>
                        <Box className="max-w-[800px]">
                            <Accordion expanded={selectedKey === '1'} onChange={(event, isExpanded) => onChange(isExpanded ? '1' : '')} sx={{ boxShadow: 'none', '&::before': { display: 'none' } }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} className="p-0">
                                    <Box className="flex items-center w-full">
                                        <p className="text-xl font-semibold min-w-[125px]">{t('release_note')}</p>
                                        <p className="mx-2 w-full h-px bg-[#E0E0E0]" />
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className="flex gap-8 items-center">
                                        <Box className="text-sm font-semibold">{latestVersion || ''}</Box>
                                        <Box>{t('aism_release_note')}</Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default Aism;
