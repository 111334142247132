import React, { useEffect, useState } from 'react';
import { Drawer, FormControl, InputLabel, Select, MenuItem, Chip, FormControlLabel, Checkbox } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { iosModalJson, appFrameworks } from './iosData';
import { buildHybridiOSSdk, checkHybridiOSSdkStatus, downloadHybridiOSSdk, downloadNativeiOSSdk } from '../../../../../services/sealing/ios';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form';
import renderInput from './renderInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { ModalConfig } from '../../../../../type/ios';
import { setSealingPreferences } from '../../../../../store/sealing/ios';
import { Hint } from '../../../../utils';
import { showToast } from '../../../../utils/notifications';
const menuProps = {
    PaperProps: {
        style: {
            maxHeight: 250,
            overflow: 'auto',
        },
    },
    anchorOrigin: {
        vertical: 'bottom' as const,
        horizontal: 'left' as const,
    },
    transformOrigin: {
        vertical: 'top' as const,
        horizontal: 'left' as const,
    },
};
interface SdkInfo {
    service_version: string;
    support_react_version_v2?: string;
}
interface IOSModalProps {
    type: keyof typeof iosModalJson;
    visible: boolean;
    onCancel: (e?: any) => void;
    iosData?: any;
    buildData?: any;
    setBuildData?: any;
    isPlanValid?: any;
    isHybridPlanValid?: any;
}

const isHybridFramework = (framework: string) =>
    appFrameworks
        .filter((e) => e.type === 'hybrid')
        .map((e) => e.detail)
        .includes(framework);

const DownloadSdk = ({ type, visible, onCancel, iosData, setBuildData, isPlanValid, isHybridPlanValid }: IOSModalProps) => {
    const data: ModalConfig = iosModalJson[type];
    const [isOpen, setIsOpen] = useState(visible);
    const {
        control,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: iosData,
    });
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState<any>(null);
    const [sealingData, setSealingData] = useState<any>([]);
    const [frameworkMajorVerData, setFrameworkMajorVerData] = useState<any>([]);
    const [frameworkMinorVerData, setFrameworkMinorVerData] = useState<any>([]);
    const [isAdhocEnabled, setAdhocEnabled] = useState<boolean>(false);
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const sdkInfo = useSelector((state: RootState) => state.iOSSealingReducer.sdkInfo);
    const initialData = useSelector((state: RootState) => state.iOSSealingReducer.sealingPreferences);

    useEffect(() => {
        if (visible !== isOpen) setIsOpen(visible);
    }, [isOpen, visible]);

    useEffect(() => {
        if (!formData && initialData && sdkInfo) {
            let data = { ...initialData };
            const serviceVersions = isHybridFramework(data.app_framework) ? sdkInfo.hybridSdkInfo : sdkInfo.nativeSdkInfo;
            let sealData = serviceVersions || [];
            let frameworkData: {
                title: string;
                value: string;
                children: { title: string; value: string; selectable: boolean }[];
                selectable: boolean;
            }[] = [];
            let frameworkMinorData: {
                title: string;
                value: string;
                selectable: boolean;
            }[] = [];
      
            if (data.app_framework === 'ReactNative' && serviceVersions.length) {
                const reactNativeInfo = serviceVersions.find((info) => info.service_version === data.sealing_version);
                if (reactNativeInfo) {
                    const reactVersions = JSON.parse(reactNativeInfo.support_react_version_v2 || '{}');
                    Object.entries(reactVersions).forEach(([majorVersion, minorVersions]) => {
                        const children = ((minorVersions as string[]) || []).map((minorVersion: string) => ({
                            title: minorVersion,
                            value: `${minorVersion}`,
                            selectable: true,
                        }));
                        if (majorVersion === data.framework_major_version) {
                            frameworkMinorData = children;
                        }
                        frameworkData.push({
                            title: majorVersion,
                            value: majorVersion,
                            children,
                            selectable: false,
                        });
                    });
                }
            }

            if (data.enable_adhoc) {
                sealData = adjustForAdhoc(sealData, data.enable_adhoc);
            }

            if (!frameworkData.find((e) => e.value === data.framework_major_version)) {
                data.framework_major_version = frameworkData.length > 0 ? frameworkData[0].value : undefined;
                frameworkMinorData = frameworkData[0]?.children || [];
            }
            if (!frameworkMinorData.find((e) => e.value === data.framework_version)) {
                data.framework_version = frameworkMinorData.length > 0 ? frameworkMinorData[0].value : undefined;
            }
            if (data.sealing_version?.includes('AE') || data.sealing_version?.includes('AdhocEnabled')) {
                data.enable_adhoc = true;
                setAdhocEnabled(true);
                setValue('enable_adhoc', true);
            }

            setSealingData(sealData);
            setFrameworkMajorVerData(frameworkData);
            setFrameworkMinorVerData(frameworkMinorData);

            setAdhocEnabled(!!data.enable_adhoc);
            setFormData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, initialData, sdkInfo]);

    useEffect(() => {
        if (formData) {
            dispatch(setSealingPreferences(formData));
            localStorage.setItem('ios_sealing_preferences', JSON.stringify(formData));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    function adjustForAdhoc(sealData: SdkInfo[], enableAdhoc: boolean): SdkInfo[] {
        return sealData.filter((sdk) =>
            enableAdhoc ? sdk.service_version.includes('AE') || sdk.service_version.includes('AdhocEnabled') : !sdk.service_version.includes('AE') && !sdk.service_version.includes('AdhocEnabled'),
        );
    }

    const buildNativeSdk = async (params: any) => {
        let fileName = params.bundleIdPrefix;
        downloadNativeiOSSdk(params, region)
            .then((response) => {
                if (response) {
                    const contentDisposition = response.headers.get('content-disposition');
                    if (contentDisposition) {
                        fileName = contentDisposition.split('=')[1].replace(/"/gi, '');
                        fileName = fileName.replace(';', '');
                    }
                }
                return response;
            })
            .then((response) => {
                try {
                    (response as Response).arrayBuffer().then((res: any) => {
                        setBuildData?.((oldData: any) => ({
                            ...oldData,
                            [params.bundleIdPrefix]: false,
                        }));
                        let link = document.createElement('a'),
                            blob = new Blob([res]),
                            objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                        link.download = unescape(fileName);
                        link.href = objectUrl;
                        link.dataset.downloadurl = ['text/plain', link.download, link.href].join(':');
                        link.click();
                    });
                } catch (error) {
                    setBuildData?.((oldData: any) => ({
                        ...oldData,
                        [params.bundleIdPrefix]: false,
                    }));
                    showToast('error', t('error_in_downloading_file'));
                }
            })
            .catch(() => {
                setBuildData?.((oldData: any) => ({
                    ...oldData,
                    [params.bundleIdPrefix]: false,
                }));
                showToast('error', t('error_in_downloading_file'));
            });
        setBuildData?.((oldData: any) => ({
            ...oldData,
            [params.bundleIdPrefix]: true,
        }));
        onCancel(null);
    };

    const buildHybridSdk = async (values: any, params: any) => {
        if (values.app_framework === 'ReactNative') params['buildTargetVersion'] = `${values.framework_major_version}.${values.framework_version}`;
        const response = await buildHybridiOSSdk(params, region);
        if (response?.result?.code === '0000') {
            const downloadParams = {
                build_id: response.result.sdkBuildStatus.build_id,
                token: authContext.token,
            };
            (() => {
                const intervalId = setInterval(
                    async (bundleIdPrefix, clearBuildInfo) => {
                        const downloadResponse = await checkHybridiOSSdkStatus(downloadParams, region);
                        if (downloadResponse?.result?.sdkBuildStatus?.result === 10) {
                            clearInterval(intervalId);
                            clearBuildInfo?.((oldData: any) => ({
                                ...oldData,
                                [bundleIdPrefix]: false,
                            }));
                            if (downloadResponse?.result?.sdkDownloadUrl) {
                                showToast('success', t('sdk_build_successfully'));
                                setTimeout(
                                    (url: string) => {
                                        downloadHybridiOSSdk(url);
                                    },
                                    5000,
                                    downloadResponse?.result?.sdkDownloadUrl,
                                );
                            } else showToast('error', t('failed_to_download_sdk'));
                        } else if (downloadResponse?.result?.sdkBuildStatus?.result === -1) {
                            clearInterval(intervalId);
                            clearBuildInfo?.((oldData: any) => ({
                                ...oldData,
                                [bundleIdPrefix]: true,
                            }));
                        }
                    },
                    5000,
                    params.bundleIdPrefix,
                    setBuildData,
                );
            })();
            setBuildData?.((oldData: any) => ({
                ...oldData,
                [params.bundleIdPrefix]: true,
            }));
            onCancel(null);
        } else showToast('error', response?.result?.message ? response?.result?.message : t('failed_to_initiate_build_for_sdk'));
    };

    const onFormSubmit = async (values: any) => {
        const isHybrid = isHybridFramework(values.app_framework);
        const params: any = {
            buildTarget: values.app_framework,
            bundleIdPrefix: iosData.bundle_id_prefix,
            sdkVersion: values.sealing_version,
            token: authContext.token,
        };
        if (isHybrid) buildHybridSdk(values, params);
        else buildNativeSdk(params);
    };

    const onFieldsChange = (fieldName: string, fieldValue: string) => {
        if (fieldValue.length < 1) return;

        let data: any = { ...formData, [fieldName]: fieldValue };
        switch (fieldName) {
            case 'app_framework': {
                const appFrameworkSealData = getSealDataBasedOnFramework(fieldValue);
                data = handleAppFrameworkChange(appFrameworkSealData || [], data);
                if (data.app_framework === 'ReactNative') {
                    handleReactNativeSpecificLogic(data);
                }
                break;
            }
            case 'enable_adhoc':
                const appFrameworkSealData = getSealDataBasedOnFramework(data.app_framework);
                data = handleAppFrameworkChange(appFrameworkSealData || [], data);
                data.enable_adhoc = fieldValue === 'true';
                if (data.app_framework === 'ReactNative') {
                    handleReactNativeSpecificLogic(data);
                }
                break;
            case 'sealing_version':
                data.sealing_version = fieldValue;
                if (data.app_framework === 'ReactNative') {
                    handleReactNativeSpecificLogic(data);
                }
                break;
            case 'framework_major_version':
                if (data.app_framework === 'ReactNative') {
                    data.framework_version = null;
                    handleReactNativeSpecificLogic(data);
                }
                break;
            case 'framework_version':
                if (data.app_framework === 'ReactNative') {
                    data.framework_version = fieldValue;
                    handleReactNativeSpecificLogic(data);
                }
                break;
            default:
                break;
        }
        if (data.sealing_version?.includes('AE') || data.sealing_version?.includes('AdhocEnabled')) {
            data.enable_adhoc = true;
            setAdhocEnabled(true);
        }
        adjustSealingVersionBasedOnAdhoc(data);
        reset(data);
        setFormData(data);
    };

    function handleAppFrameworkChange(sealData: any[], data: any) {
        const filteredSealData = data.enable_adhoc
            ? sealData.filter((sdk) => sdk.service_version.includes('AE') || sdk.service_version.includes('AdhocEnabled'))
            : sealData.filter((sdk) => !sdk.service_version.includes('AE') && !sdk.service_version.includes('AdhocEnabled'));

        if (!data.sealing_version || !filteredSealData.some((sdk) => sdk.service_version === data.sealing_version)) {
            data.sealing_version = filteredSealData.length > 0 ? filteredSealData[0].service_version : '';
        }
        setSealingData(filteredSealData);
        return data;
    }

    function getSealDataBasedOnFramework(appFramework: string) {
        return ['ReactNative', 'Ionic', 'Cordova'].includes(appFramework) ? sdkInfo?.hybridSdkInfo : sdkInfo?.nativeSdkInfo;
    }

    function adjustSealingVersionBasedOnAdhoc(data: any) {
        const sealData = getSealDataBasedOnFramework(data.app_framework);
        if (data.enable_adhoc) {
            const aeVersion = sealData?.find((sdk: any) => sdk.service_version.includes('AE') || sdk.service_version.includes('AdhocEnabled'))?.service_version;
            if (aeVersion && !data.sealing_version.includes('AE') && !data.sealing_version.includes('AdhocEnabled')) data.sealing_version = aeVersion;
        }
    }

    function handleReactNativeSpecificLogic(data: any) {
        const reactNativeInfo = sdkInfo?.hybridSdkInfo.find((info) => info.service_version === data.sealing_version);
        if (!reactNativeInfo) return;

        const supportVersionsRaw = JSON.parse(reactNativeInfo.support_react_version_v2 || '{}');
        const sortedMajorVersions = Object.keys(supportVersionsRaw).sort((a, b) => b.localeCompare(a, undefined, { numeric: true }));

        if (!data.framework_major_version || !sortedMajorVersions.includes(data.framework_major_version)) {
            data.framework_major_version = sortedMajorVersions[0];
        }

        const sortedMinorVersions = supportVersionsRaw[data.framework_major_version]?.sort((a: any, b: string) => b.localeCompare(a, undefined, { numeric: true }));
        if (!data.framework_version || !sortedMinorVersions.includes(data.framework_version)) {
            data.framework_version = sortedMinorVersions?.[0];
        }
        setValue('framework_major_version', data.framework_major_version);
        setValue('framework_version', data.framework_version);

        const newFrameworkData = sortedMajorVersions.map((majorVersion) => ({
            title: majorVersion,
            value: majorVersion,
            children:
                supportVersionsRaw[majorVersion]
                    ?.sort((a: string, b: string) => b.localeCompare(a, undefined, { numeric: true }))
                    .map((minorVersion: string) => ({
                        title: minorVersion,
                        value: minorVersion,
                        selectable: true,
                    })) || [],
            selectable: false,
        }));

        setFrameworkMajorVerData(newFrameworkData);
        setFrameworkMinorVerData(newFrameworkData.find((f) => f.value === data.framework_major_version)?.children || []);
    }

    const isDownloadDisabled = () => {
        let isDisabled = false;
        if (!formData?.app_framework) isDisabled = true;
        else if (isHybridFramework(formData.app_framework) && !isHybridPlanValid) isDisabled = true;
        else if (!isHybridFramework(formData.app_framework) && !isPlanValid) isDisabled = true;
        return isDisabled;
    };

    const onAdhocChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const data = getValues();
        data.enable_adhoc = checked;
        let sealData: any = [];
        setAdhocEnabled(checked);
        let appFramework = getValues('app_framework');
        if (['ReactNative', 'Ionic', 'Cordova'].includes(appFramework)) {
            sealData = sdkInfo?.hybridSdkInfo;
        } else {
            sealData = sdkInfo?.nativeSdkInfo;
        }

        const filteredSealingData = sealData.filter((sdk: { service_version: string | string[] }) =>
            checked ? sdk.service_version.includes('AE') || sdk.service_version.includes('AdhocEnabled') : !sdk.service_version.includes('AE') && !sdk.service_version.includes('AdhocEnabled'),
        );
        const firstSealingVersion = filteredSealingData.length > 0 ? filteredSealingData[0].service_version : '';
        setSealingData(filteredSealingData);
        setValue('sealing_version', firstSealingVersion);
        setFormData({ ...data, enable_adhoc: checked, sealing_version: firstSealingVersion });
    };
    const onReset = () => {
        const resetData = {
            ...iosData,
            app_framework: '',
            sealing_version: '',
            framework_major_version: '',
            framework_version: '',
        };
        setFormData(resetData);
        reset(resetData);
    };
    const ModalHeader = (
        <div className="mb-[50px] flex justify-between items-start gap-10">
            <div>
                <h2 className="text-2xl font-medium m-0">{t(data.title)}</h2>
                <p className="text-[#A0A0A1] text-xs">{t(data.subtitle)}</p>
            </div>
            <div>
                <div className="flex justify-end gap-4">
                    {data.footer?.map((item) => (
                        <React.Fragment key={'action'}>
                            <Button key={'icon'} variant="outlined" color="secondary" onClick={onReset}>
                                <RefreshIcon />
                            </Button>
                            <Button key={'cancel'} variant="outlined" color="secondary" onClick={onCancel} className="font-medium text-[13px]">
                                {t('cancel')}
                            </Button>
                            <Button key={'submit'} type="submit" variant="contained" color="primary" disabled={isDownloadDisabled()} className="font-medium text-[13px]">
                                {t(item.label ?? '')}{' '}
                            </Button>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );

    return (
        <Drawer anchor="right" open={isOpen} onClose={onCancel}>
            <div className="p-10 max-w-[800px]">
                {formData && (
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div>{ModalHeader}</div>
                        <div>
                            {data.items.map((item, index) => (
                                <div key={index}>
                                    <div className="flex flex-none items-center gap-2.5 mb-[24px]" key={index}>
                                        <div className="min-w-[150px]">
                                            <p className="text-primary font-medium">{t(item.label ?? '')} </p>
                                        </div>
                                        <div className="p-2 w-full max-w-[264px] flex gap-2.5 items-center">{renderInput({ item: item, control, index: index })}</div>
                                    </div>
                                </div>
                            ))}

                            <div className="flex flex-none items-center gap-2.5 mb-[24px]">
                                <div className="min-w-[150px]">
                                    <p className="text-primary font-medium">{t('app_framework')}</p>
                                </div>
                                <div className="p-2 w-full max-w-[264px] flex gap-2.5 items-center">
                                    <Controller
                                        name="app_framework"
                                        control={control}
                                        defaultValue={formData?.app_framework && formData.app_framework.length > 0 ? formData.app_framework : ''}
                                        rules={{ required: t('please_select_your_app_framework') }}
                                        render={({ field }) => (
                                            <FormControl fullWidth size="small" error={errors['app_framework'] ? true : false}>
                                                <InputLabel className="bg-white px-1">{t('app_framework')}</InputLabel>
                                                <Select
                                                    {...field}
                                                    onChange={(e) => onFieldsChange(e.target.name, e.target.value)}
                                                    disabled={!isPlanValid}
                                                    MenuProps={menuProps}
                                                    placeholder={t('select_your_app_framework')}
                                                >
                                                    {appFrameworks.map((item) => (
                                                        <MenuItem key={item.detail} value={item.detail}>
                                                            <span className="mr-2">{item.name}</span>
                                                            {item.type === 'hybrid' && !isHybridPlanValid && <Chip style={{ marginLeft: 8 }} color="error" label={t('expired')} />}
                                                            {item.type === 'hybrid' && <Chip style={{ marginLeft: 8 }} color="warning" label={t('hybrid')} />}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-none items-center gap-2.5 mb-[24px]">
                                <div className="min-w-[150px]">
                                    <p className="text-primary font-medium">{t('sealing_version')}</p>
                                </div>
                                <div className="p-2 w-full max-w-[264px] flex gap-2.5 items-center">
                                    <Controller
                                        name="sealing_version"
                                        control={control}
                                        defaultValue={formData?.sealing_version || ''}
                                        rules={{ required: t('please_select_app_sealing_version') }}
                                        render={({ field }) => (
                                            <FormControl fullWidth size="small" variant="outlined" error={errors['sealing_version'] ? true : false}>
                                                <InputLabel className="bg-white px-1">{t('sealing_version')}</InputLabel>
                                                <Select
                                                    {...field}
                                                    key={'2'}
                                                    MenuProps={menuProps}
                                                    onChange={(e) => onFieldsChange(e.target.name, e.target.value)}
                                                    disabled={
                                                        !formData.app_framework ||
                                                        (!isHybridPlanValid && isHybridFramework(formData.app_framework)) ||
                                                        (!isPlanValid && !isHybridFramework(formData.app_framework))
                                                    }
                                                    placeholder={t('select_appsealing_version')}
                                                >
                                                    {formData?.app_framework &&
                                                        sealingData
                                                            .filter((sdk: any) =>
                                                                isAdhocEnabled
                                                                    ? sdk.service_version.includes('AE') || sdk.service_version.includes('AdhocEnabled')
                                                                    : !sdk.service_version.includes('AE') && !sdk.service_version.includes('AdhocEnabled'),
                                                            )
                                                            .map((item: any) => (
                                                                <MenuItem key={item.service_version} value={item.service_version}>
                                                                    {item.service_version}
                                                                </MenuItem>
                                                            ))}
                                                </Select>
                                                {formData?.app_framework &&
                                                formData.app_framework.length !== 0 &&
                                                formData?.sealing_version !==
                                                    sealingData.filter((sdk: any) => (isAdhocEnabled ? sdk.service_version.includes('AdhocEnabled') : !sdk.service_version.includes('AdhocEnabled')))[0]
                                                        ?.service_version ? (
                                                    <span className="text-red-500 text-xs  italic">{t('older_appsealing_version')}</span>
                                                ) : undefined}
                                            </FormControl>
                                        )}
                                    />
                                </div>
                            </div>

                            {formData.app_framework === 'ReactNative' && isHybridPlanValid && (
                                <div className="flex flex-none items-center gap-2.5 mb-[24px]">
                                    <div className="min-w-[150px]">
                                        <p className="text-primary font-medium">{t('framework_version')}</p>
                                    </div>
                                    <div className="p-2 w-full max-w-[264px] flex gap-2.5 items-center">
                                        <FormControl fullWidth size="small" error={errors['framework_major_version'] ? true : false}>
                                            <Controller
                                                name="framework_major_version"
                                                control={control}
                                                rules={{
                                                    required: t('please_select_framework_version'),
                                                }}
                                                defaultValue={formData?.framework_major_version || ''}
                                                render={({ field }) => (
                                                    <Select {...field} size="small" onChange={(e) => onFieldsChange(e.target.name, e.target.value)} placeholder={t('select_major_version')} disabled={!formData.app_framework} MenuProps={menuProps}>
                                                        {frameworkMajorVerData
                                                            .sort((a: { value: string }, b: { value: string }) => parseFloat(b.value) - parseFloat(a.value))
                                                            .map((item: { title: string; value: string | number | readonly string[] | undefined }) => (
                                                                <MenuItem key={item.title.toString()} value={item.value}>
                                                                    {item.title}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>

                                        <FormControl fullWidth error={errors['framework_version'] ? true : false}>
                                            <Controller
                                                name="framework_version"
                                                control={control}
                                                rules={{
                                                    required: t('please_select_framework_version'),
                                                }}
                                                defaultValue={formData?.framework_version || ''}
                                                render={({ field, formState: { errors } }) => (
                                                    <Select
                                                        {...field}
                                                        size="small"
                                                        placeholder={t('select_major_version')}
                                                        disabled={!formData.app_framework}
                                                        MenuProps={menuProps}
                                                        onChange={(e) => onFieldsChange(e.target.name, e.target.value)}
                                                        error={errors['framework_version'] ? true : false}
                                                    >
                                                        {frameworkMinorVerData
                                                            .sort((a: { value: string }, b: { value: string }) => parseFloat(b.value) - parseFloat(a.value))
                                                            .map((item: { title: string; value: string | number | readonly string[] | undefined }) => (
                                                                <MenuItem key={item.title.toString()} value={item.value}>
                                                                    {item.title}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            )}

                            <div className="ml-[170px] min-w-1/2 max-w-[415px]">
                                <Controller
                                    name="enable_adhoc"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={<Checkbox {...field} onChange={onAdhocChange} checked={isAdhocEnabled} />}
                                            label={<span className="text-[#F5812D]">{t('enable_adhoc')}</span>}
                                        />
                                    )}
                                />
                                {isAdhocEnabled && <Hint text={t('enable_adhoc_tip')} />}
                            </div>
                        </div>
                        {data.notes && (
                            <div className="text-[#797A7C] text-xs">
                                <p className="font-semibold ">{t('note')}:</p>
                                <p>{t(data.notes)}</p>
                            </div>
                        )}
                    </form>
                )}
            </div>
        </Drawer>
    );
};

export default DownloadSdk;
