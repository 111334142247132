import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Tooltip, Box, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab } from '@mui/material';
import moment from 'moment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './index.less';
import { AppURL } from '../../../../utility/AppURL';
import { openHelpCenter } from '../../../../services/main';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { checkSealingStatus, downloadSealedFile, getAOSSealingHistory, getServiceables, requestSealing, sealingAvailable } from '../../../../services/sealing/android';
import { setSealingPreferences, setServiceables } from '../../../../store/sealing/android';
import { showDialog, showToast } from '../../../utils/notifications';
import { updateAdvanceService } from '../../../../services/securityservices';
import DownloadModal from '../historyinfo/download';
import LoadMask from '../../../utils/loadmask';
import ManageDex from '../../../manage/settings/manageservice/managedex';
import SealingModal from './modals/sealingModal';
import { useTranslation } from 'react-i18next';
import BasicConfiguration from './BasicConfiguration';
import UploadAosApp from './UploadAosApp';
import EnvironmentConfiguration from './EnvironmentConfiguration';
import ToolsAndPackages from './ToolsAndPackages';
import AdvancedDetection from './AdvancedDetection';
import SecurityFeatures from './SecurityFeatures';
import { appFrameworks, isHybridFramework } from './data';
import { useSecurityMeansure } from './data/common';
import ViewToggleBtn from './ViewToggleBtn';
import { getCommonEl, getSealingPrestOptions, onFieldsChanges } from './SealingFunction';
import { convertKeysToSnakeCase } from '../preset/PresetFunction';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabsInfo = [
    {
        key: '1',
        title: 'upload_file',
    },
    {
        key: '2',
        title: 'basic_configuration',
    },
    {
        key: '3',
        title: 'environment_detection',
    },
    {
        key: '4',
        title: 'tools_and_packages',
    },
    {
        key: '5',
        title: 'advanced_detection',
    },
];

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div className="w-full" role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
            {value === index && (
                <div className="px-10">
                    <div>{children}</div>
                </div>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const AndroidSealing = () => {
    const submitRef = useRef<any>(null);
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const [viewType, setViewType] = useState<string>('step');
    const [toggleFirstTime, setToggleFirstTime] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [sealingTabs, setSealingTabs] = useState(TabsInfo);
    const [loadMask, showLoadMask] = useState<any>(false);
    const [fileType, setFileType] = useState<string>('apk');
    const [sealingData, setSealingData] = useState<any>([]);
    const [activePanels, setActivePanels] = useState<Array<any>>([]);
    const [currentAppData, setCurrentAppData] = useState<any>({});
    const [showDownloadModal, setShowDownloadModal] = useState<any>(null);
    const [buttonText, setButtonText] = useState<string>('START APPSEALING');
    const [nativePlanType, setNativePlanType] = useState<string | null>(null);
    const [hybridPlanType, setHybridPlanType] = useState<string | null>(null);
    const [isDexModalVisible, setIsDexModalVisible] = useState<boolean>(false);
    const [selectedServiceDetails, setSelectedServiceDetails] = useState<any>(null);
    const [showSealingModal, setShowSealingModal] = useState(false);
    const [showGuide, setShowGuide] = useState(false);
    const [sealingStatus, setSealingStatus] = useState(0);
    const [downloadData, setDownloadData] = useState<any>();
    const [fileName, setFileName] = useState('');
    const [hasError, setHasError] = useState<boolean | string>(false);
    const [isSecurityExpanded, setIsSecurityExpanded] = useState<boolean>(false);
    const [presetId, setPresetId] = useState<boolean | number | string>(false);
    const [didPresetChange, setDidPresetChange] = useState<any>(false);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);
    const planDetails = useSelector((state: RootState) => state.mainReducer.planDetails);
    const serviceables = useSelector((state: RootState) => state.androidSealingReducer.serviceables);
    const initialData = useSelector((state: RootState) => state.androidSealingReducer.sealingPreferences);
    const region = userDetails?.default_service_region;
    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: initialData,
    });
    const [formData, setFormData] = useState<any>(null);
    const securityMeasure = useSecurityMeansure(formData, sealingData, fileType, selectedServiceDetails);

    useEffect(() => {
        if (planDetails) {
            setNativePlanType(planDetails.plan?.android?.type || 'T');
            setHybridPlanType(planDetails.plan?.hybrid?.type || 'T');
        }
    }, [planDetails]);

    useEffect(() => {
        const viewType = localStorage.getItem('sealingViewType');
        if (viewType) setViewType(viewType);
        else {
            setToggleFirstTime(true);
            setTimeout(() => {
                setToggleFirstTime(false);
            }, 5000);
        }
    }, []);

    useEffect(() => {
        if (serviceables === null) {
            const fetchServiceables = async () => {
                const params = {
                    token: authContext.token,
                };
                const data = await getServiceables(params, region);
                dispatch(setServiceables(data));
            };
            fetchServiceables();
        }
    }, [region, serviceables, authContext.token, dispatch]);

    useEffect(() => {
        if (formData) {
            const { apkfile, ...newPref } = formData;
            dispatch(setSealingPreferences({ ...newPref }));
            localStorage.setItem('sealing_preferences', JSON.stringify(newPref));
        }
    }, [formData, dispatch]);

    useEffect(() => {
        if (formData === null && initialData && serviceables) {
            let data: any = { ...initialData };
            const isHybrid = isHybridFramework(initialData.app_framework);
            const serviceVersions = (serviceables?.serviceVersions || []).filter((e) => e.type === (isHybrid ? 'hybrid' : 'native'));
            if (serviceVersions?.length) {
                const minVersion = appFrameworks.find((e) => e.detail === data.app_framework)?.minVersion;
                let details = null;
                let sealingVersions = (serviceables?.serviceVersions || []).filter((e) => e.type === (isHybrid ? 'hybrid' : 'native'));
                if (minVersion) sealingVersions = sealingVersions.filter((e) => e.service_version >= minVersion);
                if (initialData.service_version) details = serviceVersions.find((e: any) => e.service_version === data.service_version);

                setSealingData(sealingVersions);
                setSelectedServiceDetails(details);
            } else data.service_version = null;

            setFormData(data);
        }
    }, [formData, initialData, serviceables]);

    const onDownloadClick = async () => {
        let params: any = {
            req_source: 'ADC_AOS_HYBRID',
            sealed_date_from_view: moment().format('YYYY/MM/DD'),
            sealed_date_from: moment().format('YYYYMMDD'),
            service_type: 'ALL',
            token: authContext.token,
        };
        let record = null;
        if (downloadData.extension === 'zip') {
            params = {
                token: authContext.token,
                packId: downloadData.sealingID,
            };
            downloadSealedFile(params, region);
        } else {
            params['pack_id'] = downloadData.sealingID;
            const response = await getAOSSealingHistory({ ...params }, region);
            if (response?.result?.code === '0000' && Array.isArray(response?.sealingHistoryList) && response?.sealingHistoryList.length > 1)
                record = response?.sealingHistoryList.find((e: any) => e.pack_id === downloadData.sealingID);
        }
        if (record === null) {
            showToast('error', t('error_while_sealing_application'));
            return false;
        } else {
            setShowDownloadModal(record);
        }
        handleSealingModalCancel();
    };

    const checkAOSSealingStatus = async (data: any) => {
        const params = {
            pack_id: data.sealingID,
            extension: data.extension,
            sealed_date_from: moment().format('YYYYMMDD'),
            sealed_date_from_view: moment().format('YYYY/MM/DD'),
            service_type: 'ALL',
            token: authContext.token,
        };

        let status: any = '0';
        const response = await checkSealingStatus(params, region);
        let responseMsg = (response?.result?.message || response?.message) !== 'SUCCESS' ? response.result?.message || response.message : t('error_while_sealing_application');

        if (data.extension === 'zip' && response?.SEALING_INFO?.status) {
            status = response?.SEALING_INFO?.status;
        } else if (data.extension === 'zip' && !response?.result?.status) {
            setHasError(responseMsg);
            showLoadMask(false);
            setButtonText('START APPSEALING');
            return false;
        } else if (data.extension !== 'zip' && response?.result?.status) {
            status = response?.result?.status;
        }

        if (status === '0' && response.result.code === '9999') {
            setHasError(response.result.message);
            return false;
        }
        if (status === '0' || status === 0 || status === '1' || status === 1) {
            setTimeout(() => {
                checkAOSSealingStatus(data);
            }, 5000);
        } else if (status === '2' || status === 2) {
            showLoadMask(false);
            setButtonText('START APPSEALING');
            setFormData((e: any) => {
                return { ...e, apkfile: [] };
            });
            setValue('apkfile', []);
            setDownloadData(data);
            setFileName(formData.apkfile[0].name);
            setSealingStatus(2);
        } else if (status === '3' || status === 3) {
            showLoadMask(false);
            setButtonText('START APPSEALING');
            if (response?.result?.code || response?.code) {
                const code = response?.result?.code || response?.code;
                if (t(`SCM-${code}`.toUpperCase(), '') !== '') {
                    const defaultErrMsg = t('error_while_sealing_application');
                    setHasError(t(`SCM-${code}`, defaultErrMsg));
                } else setHasError(responseMsg);
            } else setHasError(responseMsg);
        }
    };
    const handleSealing = () => {
        const getAllValues = getValues();
        onFormSubmit(getAllValues);
    };
    const onFormSubmit = async (values: any) => {
        const data = getValues();
        let differences: boolean = false;
        if (presetId) {
            Object.keys(data).map((key) => {
                if (key === 'apkfile') return;
                if (key === 'allow_emulator' || key === 'allow_work_profiles_owner_app_name') {
                    if (data[key].join(',') !== didPresetChange[key].join(',')) {
                        differences = true;
                    }
                    return;
                }
                if (data[key] !== didPresetChange[key]) {
                    differences = true;
                }
            });
        }

        let { app_framework, allow_emulator, allow_work_profiles_owner_app_name, block_work_profile, dex_encrypt, macro, sniff, vmenv, apkfile, lang, block_dex_dump, dex_string_encrypt, ...others } =
            formData;
        if (vmenv === 'optional' && allow_emulator.length === 0) {
            showToast('error', t('please_select_allow_emulator'));
            setSelectedTab(2);
            return false;
        }

        if (block_work_profile === 'optional' && allow_work_profiles_owner_app_name.length === 0) {
            showToast('error', t('please_select_block_work_profile'));
            setSelectedTab(2);
            return false;
        }

        const isHybrid = isHybridFramework(app_framework),
            file = values['apkfile']?.[0],
            details = (serviceables?.serviceVersions || []).filter((e) => e.type === (isHybrid ? 'hybrid' : 'native')).find((e: any) => e.service_version === others.service_version),
            extension = file?.name?.split('.')?.[values['apkfile'][0].name.split('.').length - 1];

        let select_dex_encrypt = 'no',
            allow_external_tool: any = [],
            service_type = isHybrid ? 'HYBRID_AOS' : 'NATIVE_AOS';

        if (dex_encrypt === 'selective') {
            dex_encrypt = 'yes';
            select_dex_encrypt = 'yes';
        }
        if (block_dex_dump === null) block_dex_dump = 'no';
        if (dex_string_encrypt === null) dex_string_encrypt = 'no';
        if (macro === 'no') allow_external_tool.push('macro');
        if (sniff === 'no') allow_external_tool.push('sniff');

        allow_external_tool = allow_external_tool.join(',');
        app_framework = app_framework === 'NATIVE' ? '' : app_framework;
        allow_emulator = Array.isArray(allow_emulator) ? allow_emulator.join(',') : undefined;
        allow_work_profiles_owner_app_name = Array.isArray(allow_work_profiles_owner_app_name) ? allow_work_profiles_owner_app_name.join(',') : undefined;

        vmenv = vmenv === 'optional' ? 'forbid' : vmenv;
        block_work_profile = block_work_profile === 'optional' ? 'yes' : block_work_profile;

        let args: any = {
            app_framework,
            allow_emulator,
            block_work_profile,
            vmenv,
            service_type,
            dex_encrypt,
            select_dex_encrypt,
            allow_external_tool,
            hybrid_platform: app_framework,
            allow_work_profiles_owner_app_name,
            app_packaging_format: extension,
            lang: 'en',
            dex_string_encrypt,
            block_dex_dump,
            ...others,
        };

        if (isHybrid) {
            delete args.app_framework;
            args['hybrid_platform'] = app_framework;
            args['h5_service_version'] = details.h5_service_version;
            args['aos_service_version'] = details.aos_service_version;
            args['hybrid_sdk_version'] = details.hybrid_sdk_version;
        } else {
            delete args.h5_service_version;
            delete args.aos_service_version;
            delete args.hybrid_sdk_version;
        }

        const params: any = {
            token: authContext.token,
            userID: userDetails.user_id,
        };

        // h5_service_version is the service_version for JS_ONLY
        if (extension.toLowerCase() === 'zip') {
            args = {
                service_type: 'JS_ONLY',
                hybrid_platform: app_framework,
                service_version: details.h5_service_version,
                hybrid_service_version: others.service_version,
                lang: 'en',
            };
            params['html5file'] = file;
        } else params['apkfile'] = file;

        params['args'] = args;
        delete args['id'];
        if (!differences && presetId) args['sealingPresetId'] = presetId || '';

        showLoadMask({ msg: t('validating_user_information') });
        setButtonText('Validating Sealing');

        const requestSealingResponse = await sealingAvailable({ token: authContext.token }, region);
        if (requestSealingResponse?.result?.code !== '0000') {
            showLoadMask(false);
            setShowSealingModal(false);
            setButtonText('START APPSEALING');
            if (requestSealingResponse?.result?.code === '1203' || requestSealingResponse?.result?.code === '1204') {
                showDialog({
                    title: t('sealing_error'),
                    content: t(`SCM-${requestSealingResponse?.result?.code}`.toUpperCase(), requestSealingResponse?.result?.message).toString(),
                    cancelButtonProps: { hidden: true },
                    okText: t('ok'),
                });
            } else if (requestSealingResponse?.result?.code && t(`SCM-${requestSealingResponse?.result?.code}`.toUpperCase(), '') !== '') {
                showDialog({
                    title: t('sealing_error'),
                    content: t(`SCM-${requestSealingResponse?.result?.code}`.toUpperCase(), requestSealingResponse?.result?.message).toString(),
                    cancelButtonProps: { hidden: true },
                    okText: t('ok'),
                });
            } else if (requestSealingResponse?.result?.message) showToast('error', requestSealingResponse?.result?.message);
            else showToast('error', t('failed_to_seal_application'));
        } else {
            showLoadMask(false);
            setButtonText('Uploading Application');
            setSealingStatus(0);
            setShowSealingModal(true);

            const response = await requestSealing(params, region);
            if (extension.toLowerCase() === 'zip' && response?.result?.code === '0000' && response.SEALING_INFO?.pack_id) {
                const data = {
                    sealingID: response.SEALING_INFO?.pack_id,
                    extension: extension.toLowerCase(),
                };
                setButtonText('Sealing Application');
                setSealingStatus(1);
                checkAOSSealingStatus(data);
            } else if (extension.toLowerCase() !== 'zip' && response?.result === 'OK' && response.sealingID) {
                const data = {
                    sealingID: response.sealingID,
                    extension: extension.toLowerCase(),
                };
                setSealingStatus(1);
                checkAOSSealingStatus(data);
            } else {
                showLoadMask(false);
                setShowSealingModal(false);
                let code = response.code || response?.result.code;

                if (code || response?.message) {
                    let msg = t(`SCM-${code}`.toUpperCase(), response?.message || t('failed_to_seal_application')) as string;
                    if (code.startsWith('1015')) {
                        const onConfirmDialogue = () => {
                            setCurrentAppData(response);
                            setIsDexModalVisible(true);
                        };
                        showDialog({
                            title: t('SCM-1015_TITLE'),
                            content: (
                                <div className="flex flex-col gap-2">
                                    <p>{t(`SCM-1015`)}</p>
                                    <p className="mt-2">{t('package') + ' : ' + response.package_name}</p>
                                    <p>{t('application') + ' : ' + response.app_name}</p>
                                </div>
                            ),
                            onOk: onConfirmDialogue,
                            okText: t('confirm'),
                            cancelText: t('cancel'),
                        });
                    } else if (code === '1016' || code === '1017' || code === '1021' || code === '1022') {
                        const onConfirmDialogue = async () => {
                            const params = {
                                package_name: response.package_name,
                                request: code === '1016' || code === '1017' ? 'Y' : 'N',
                                service_id: code === '1016' || code === '1021' ? 'DATA_SEALING' : 'OTA_SERVICE',
                                token: authContext.token,
                            };
                            const serviceResponse = await updateAdvanceService(params, region);
                            if (serviceResponse.result.code === '0000') onFormSubmit(values);
                            else showToast('error', serviceResponse?.result?.code + serviceResponse?.result?.message);
                        };
                        showDialog({
                            title: t(`SCM-${code}_TITLE`, `Adavnced Feature ${code === '1016' || code === '1017' ? 'activation' : 'deactivation'} required`),
                            content: (
                                <div className="flex flex-col gap-2">
                                    <p>
                                        {t(
                                            `SCM-${code}`,
                                            `Would you like to ${code === '1016' || code === '1017' ? 'activate' : 'deactivate'}  the feature and proceed with the application of AppSealing? 
                                    ${code === '1016' || code === '1017' ? 'It will charge an additional fee when you enable it.' : ''}`,
                                        )}
                                    </p>
                                    <p className="mt-2">{t('package') + ' : ' + response.package_name}</p>
                                    <p>{t('application') + ' : ' + response.app_name}</p>
                                    <p>
                                        {(code === '1016' || code === '1021' ? t('data_sealing') : t('over_the_air_update')) + ': ' + (code === '1016' || code === '1017' ? 'Off => On' : 'On => Off')}
                                    </p>
                                </div>
                            ),
                            onOk: onConfirmDialogue,
                            okText: t('yes'),
                            cancelText: t('no'),
                        });
                    } else if (code === '1020') {
                        const onConfirmDialogue = async () => {
                            const params = {
                                package_name: response.package_name,
                                request: 'Y',
                                token: authContext.token,
                            };
                            const dataSealingServiceResponse = await updateAdvanceService({ ...params, service_id: 'DATA_SEALING' }, region);
                            const otaserviceResponse = await updateAdvanceService({ ...params, service_id: 'OTA_SERVICE' }, region);
                            if (dataSealingServiceResponse.result.code === '0000' && otaserviceResponse.result.code === '0000') onFormSubmit(values);
                            else {
                                if (dataSealingServiceResponse.data.result.code !== '0000') showToast('error', dataSealingServiceResponse?.result?.code + dataSealingServiceResponse?.result?.message);
                                else showToast('error', otaserviceResponse?.result?.code + otaserviceResponse?.result?.message);
                            }
                        };
                        showDialog({
                            title: t(`SCM-${code}_TITLE`, `Adavnced Features activation required`),
                            content: (
                                <div className="flex flex-col gap-2">
                                    <p>
                                        {t(
                                            `SCM-${code}`,
                                            `Would you like to activate the feature and proceed with the application of AppSealing? 
                                   It will charge an additional fee when you enable it.`,
                                        )}
                                    </p>
                                    <p className="mt-2">{t('package') + ' : ' + response.package_name}</p>
                                    <p>{t('application') + ' : ' + response.app_name}</p>
                                    <p>{t('data_sealing') + ': Off => On'}</p>
                                    <p>{t('over_the_air_update') + ': Off => On'}</p>
                                </div>
                            ),
                            onOk: onConfirmDialogue,
                            okText: t('yes'),
                            cancelText: t('no'),
                        });
                    } else if (code === '110') {
                        showDialog({
                            title: t('SCM-110_TITLE'),
                            content: <div dangerouslySetInnerHTML={{ __html: msg }}></div>,
                            cancelButtonProps: { hidden: true },
                            okText: t('ok'),
                        });
                    } else if (t(`SCM-${code}`.toUpperCase(), '') !== '') {
                        showDialog({
                            title: t('sealing_error'),
                            content: msg,
                            cancelButtonProps: { hidden: true },
                            okText: t('ok'),
                        });
                    } else showToast('error', msg as string);
                } else setHasError(t('failed_to_seal_application'));

                setButtonText('START APPSEALING');
            }
        }
    };

    const onFieldsChange = (fields: any) => {
        onFieldsChanges({
            fields,
            isOTADisabled,
            formData,
            setValue,
            getValues,
            appFrameworks,
            serviceables,
            setSealingData,
            nativePlanType,
            hybridPlanType,
            setShowSealingModal,
            setSelectedServiceDetails,
            setFileType,
            setSealingTabs,
            TabsInfo,
            setSelectedTab,
            setFormData,
            clearErrors,
        });
    };

    const handleReset = () => {
        setSelectedTab(0);
        setFormData(null);
    };

    const isReleaseEnabled = (framework: any) => {
        let isRelease = false;
        const isHybrid = isHybridFramework(framework);
        if (framework) {
            if (isHybrid) {
                if (hybridPlanType === 'E' || hybridPlanType === 'P') isRelease = true;
            } else {
                if (nativePlanType === 'E' || nativePlanType === 'P') isRelease = true;
            }
        }
        return isRelease;
    };

    const isOTADisabled = (framework: any) => {
        let disable = true;
        const isHybrid = isHybridFramework(framework);
        if (framework) {
            if (isHybrid && hybridPlanType === 'E') disable = false;
            if (!isHybrid && nativePlanType === 'E') disable = false;
        }
        return disable;
    };

    const isAndroidExpired = (type: string) => {
        let isExpired = false;
        type = type === 'hybrid' ? 'hybrid' : 'android';
        if (planDetails?.plan?.[type]?.type === 'T' && planDetails?.plan?.[type]?.isExpired) isExpired = true;
        return isExpired;
    };

    const onHelpCenterClick = (article: string, section?: boolean, helpDeskOnly = false) => {
        let redirectURL = '';
        if (helpDeskOnly) {
            redirectURL = AppURL.helpCenter + (i18n.language === 'en_US' ? 'en-us' : 'ko');
        } else {
            redirectURL = AppURL.helpCenter + (i18n.language === 'en_US' ? 'en-us' : 'ko') + (section ? '/sections/' : '/articles/') + article;
        }
        openHelpCenter(authContext.token, redirectURL);
    };

    const getEl = (data: any, type: string) => {
        return getCommonEl(data, formData, control, onFieldsChange, serviceables, planDetails, onHelpCenterClick);
    };

    const validateInput = () => {
        let data = {
            basicSetting: false,
            features: false,
        };

        if (formData.apkfile?.length || userDetails.user_id === 'demo@appsealing.com') {
            data.basicSetting = true;
            if (!!!(!formData.app_framework || (fileType !== 'zip' && !formData.app_type) || !formData.service_version)) {
                data.features = true;
                let temp = [...activePanels];
                let tempSealingTabs = sealingTabs;
                if (!temp.includes('panel-1') || !temp.includes('panel-2')) {
                    if (!temp.includes('panel-1')) {
                        temp.push('panel-1');
                        tempSealingTabs = tempSealingTabs.map((tab) => {
                            if (tab.key === '3' || tab.key === '4') {
                                return { ...tab, disabled: false };
                            }
                            return tab;
                        });
                    }
                    if (!temp.includes('panel-2')) {
                        temp.push('panel-2');
                        tempSealingTabs = tempSealingTabs.map((tab) => {
                            if (tab.key === '5') {
                                return { ...tab, disabled: false };
                            }
                            return tab;
                        });
                    }

                    setActivePanels(temp);
                    setSealingTabs(tempSealingTabs);
                }
            } else {
                if (activePanels.length) setActivePanels([]);
            }
        }
        return data;
    };

    const handleSealingModalCancel = () => {
        setShowSealingModal(false);
        setHasError(false);
        handleReset();
    };

    const activeTab = 'shadow-top shadow-bottom shadow-left border border-r-none rounded-tl-[10px] rounded-bl-[10px] bg-primary overflow-hidden';

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const disableTab = (tab: string) => {
        switch (tab) {
            case '1':
                return !formData.apkfile;
            case '2':
                return !validateInput().basicSetting;
            case '3':
                return !validateInput().features;
            case '4':
                return !validateInput().features;
            case '5':
                return !validateInput().features;
        }
    };

    const handleToggle = (type: string) => {
        setViewType(type);
        localStorage.setItem('sealingViewType', type);
    };

    const handleTabClick = () => {
        if (!formData.apkfile || formData.apkfile.length === 0) {
            setShowGuide(true);
            setTimeout(() => {
                setShowGuide(false);
            }, 3000);
        } else if (!formData.app_framework) {
            setError('app_framework', {
                type: 'manual',
                message: 'This field is required',
            });
        } else if (!formData.service_version) {
            setError('service_version', {
                type: 'manual',
                message: 'This field is required',
            });
        }
    };

    const applyPreset = (preset: any) => {
        const convertPreset = convertKeysToSnakeCase(preset);
        const options: any = getSealingPrestOptions(convertPreset);
        Object.keys(options).map(async (key) => {
            await onFieldsChange([{ name: [key], value: options[key] }]);
        });
        setPresetId(preset.id);
        setDidPresetChange(options);
    };
    return (
        <div className="relative">
            {formData && (
                <React.Fragment>
                    {loadMask && <LoadMask msg={loadMask.msg} />}
                    <ManageDex
                        visible={isDexModalVisible}
                        appData={currentAppData}
                        onCancel={(e: any) => {
                            setIsDexModalVisible(false);
                            setCurrentAppData({});
                        }}
                    />
                    {showSealingModal && (
                        <SealingModal
                            isModalVisible={showSealingModal}
                            sealingStatus={sealingStatus}
                            onDownloadClick={onDownloadClick}
                            fileName={fileName}
                            hasError={hasError}
                            onHelpCenterClick={onHelpCenterClick}
                            onCancel={handleSealingModalCancel}
                        />
                    )}
                    <DownloadModal visible={showDownloadModal ? true : false} data={showDownloadModal} onCancel={() => setShowDownloadModal(false)} />
                    <form ref={submitRef} onSubmit={handleSubmit(onFormSubmit)} className={`relative ${viewType === 'basic' ? 'min-w-[400px]' : 'min-w-[860px]'}`}>
                        <div className="flex justify-end bg-primary absolute z-10 right-6 top-6">
                            <Tooltip open={toggleFirstTime} title={toggleFirstTime ? t('click_here_for_old_layout') : ''} arrow placement="bottom">
                                <div>
                                    <ViewToggleBtn viewType={viewType} setViewType={handleToggle} />
                                </div>
                            </Tooltip>
                        </div>

                        {viewType === 'basic' ? (
                            <div className="p-10 h-full">
                                <div className="flex-grow flex flex-col h-full">
                                    <div className="flex-grow flex flex-col overflow-y-auto overflow-x-auto lg:overflow-x-hidden">
                                        <div className="lg:pt-0 pt-10 pb-5 flex lg:flex-nowrap flex-wrap gap-16">
                                            <div className="flex flex-col w-full max-w-full lg:max-w-[440px]">
                                                <UploadAosApp
                                                    handleSubmit={handleSealing}
                                                    control={control}
                                                    formData={formData}
                                                    viewType={viewType}
                                                    onFieldsChange={onFieldsChange}
                                                    buttonText={buttonText}
                                                    validateInput={validateInput}
                                                    securityMeasure={securityMeasure}
                                                    nativePlanType={nativePlanType}
                                                    hybridPlanType={hybridPlanType}
                                                    sendPresets={applyPreset}
                                                />
                                            </div>
                                            <div
                                                className="flex flex-col gap-4"
                                                style={{
                                                    minWidth: 512,
                                                    flexGrow: 1,
                                                    opacity: validateInput().basicSetting ? 1 : 0.3,
                                                    cursor: validateInput().basicSetting ? 'auto' : 'not-allowed',
                                                }}
                                            >
                                                <BasicConfiguration
                                                    formData={formData}
                                                    control={control}
                                                    errors={errors}
                                                    onFieldsChange={onFieldsChange}
                                                    validateInput={validateInput}
                                                    isAndroidExpired={isAndroidExpired}
                                                    isReleaseEnabled={isReleaseEnabled}
                                                    fileType={fileType}
                                                    sealingData={sealingData}
                                                    serviceables={serviceables}
                                                    getEl={getEl}
                                                />
                                            </div>
                                        </div>

                                        {fileType !== 'zip' && (
                                            <Accordion
                                                key="panel-1"
                                                disabled={validateInput().features ? false : true}
                                                sx={{
                                                    boxShadow: 'none',
                                                    border: 'none',
                                                    '&:before': {
                                                        display: 'none',
                                                    },
                                                }}
                                                expanded={activePanels.includes('panel-1')}
                                            >
                                                <AccordionSummary className="border-none px-0" expandIcon={<ChevronRightIcon />} aria-controls="panel1-content" id="panel1-header">
                                                    <div className="flex items-center w-full">
                                                        <span className="text-lg text-nowrap font-medium">{t('general_features')}</span>
                                                        <span
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onHelpCenterClick('900000555906', false);
                                                            }}
                                                            className="uppercase text-nowrap underline text-accent mx-2"
                                                        >
                                                            {t('read_more')}
                                                        </span>
                                                        <hr className="block w-full" />
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="flex flex-wrap lg:flex-nowrap gap-12">
                                                        <div className="flex flex-col gap-3 w-full lg:w-1/2">
                                                            <EnvironmentConfiguration getEl={getEl} />
                                                        </div>
                                                        <div className="flex-grow w-full lg:w-1/2">
                                                            <ToolsAndPackages getEl={getEl} />
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        )}
                                        {fileType !== 'zip' && (
                                            <Accordion
                                                key="panel-2"
                                                disabled={validateInput().features ? false : true}
                                                sx={{
                                                    boxShadow: 'none',
                                                    border: 'none',
                                                    '&:before': {
                                                        display: 'none',
                                                    },
                                                }}
                                                expanded={activePanels.includes('panel-2')}
                                            >
                                                <AccordionSummary className="border-none px-0" expandIcon={<ChevronRightIcon />} aria-controls="panel2-content" id="panel2-header">
                                                    <div className="flex items-center w-full">
                                                        <span className="text-lg text-nowrap font-medium">{t('advanced_features')}</span>
                                                        <span
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onHelpCenterClick('11601602478361', true);
                                                            }}
                                                            className="uppercase text-nowrap underline text-accent mx-2"
                                                        >
                                                            {t('read_more')}
                                                        </span>
                                                        <hr className="block w-full" />
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="flex flex-col gap-3 w-full lg:w-1/2">
                                                        <AdvancedDetection getEl={getEl} />
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        )}
                                        <Accordion
                                            key="panel-3"
                                            onChange={() => setIsSecurityExpanded(!isSecurityExpanded)}
                                            disabled={validateInput().features ? false : true}
                                            sx={{
                                                boxShadow: 'none',
                                                border: 'none',
                                                '&:before': {
                                                    display: 'none',
                                                },
                                            }}
                                            expanded={isSecurityExpanded}
                                        >
                                            <AccordionSummary className="border-none px-0" expandIcon={<ChevronRightIcon />} aria-controls="panel3-content" id="panel3-header">
                                                <div className="flex items-center w-full">
                                                    <span className="text-sm text-nowrap font-medium mr-2">{t('inherent_features')}</span>
                                                    <hr className="block w-full" />
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <SecurityFeatures viewType={viewType} />
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="w-full flex h-full relative" style={{ maxHeight: 'calc(100vh - 159px)' }}>
                                <div
                                    className={`${showGuide ? 'block' : 'hidden'} absolute left-0 top-0 bg-black  w-full h-full z-30 opacity-30 transition-opacity`}
                                    onClick={() => setShowGuide(false)}
                                ></div>
                                <Box className="flex-1 w-3/4 bg-primary flex h-full" sx={{ maxHeight: 'calc(100vh - 159px)', minHeight: 'calc(100vh - 159px)' }}>
                                    <Tabs
                                        className={`max-w-[170px] min-w-[150px] w-1/4 bg-[#F8F8F8]`}
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={selectedTab}
                                        onClick={handleTabClick}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        sx={{
                                            '& .MuiTabs-indicator': {
                                                display: 'none',
                                            },
                                        }}
                                    >
                                        {sealingTabs.map((tab, i) => (
                                            <Tab
                                                className={`py-5 px-7 items-start rounded-top-left text-left ${selectedTab === i ? activeTab : 'shadow-right'}`}
                                                key={tab.title}
                                                disabled={disableTab(tab.key)}
                                                label={
                                                    <div className={`${selectedTab === i ? 'text-accent' : 'text-[#C8C8C8]'} w-full`}>
                                                        <span
                                                            className={`bg-primary flex justify-center items-center w-10 h-10 rounded-full border-[2px] text-base font-semibold ${selectedTab === i ? 'border-accent' : 'border-[#C8C8C8]'}`}
                                                        >
                                                            0{i + 1}
                                                        </span>
                                                        <span className="my-2 block text-tiny">STEP {i + 1}</span>
                                                        <span className={` font-semibold text-xs capitalize ${selectedTab === i ? 'text-accent' : 'text-[#2C2C2C]'}`}>{t(tab.title)}</span>
                                                        <hr className="bg-[#C8C8C8] w-full h-px block mt-5" />
                                                    </div>
                                                }
                                                {...a11yProps(i)}
                                            />
                                        ))}
                                    </Tabs>
                                    <div className="max-h-full overflow-y-auto w-full my-10">
                                        <TabPanel value={selectedTab} index={0}>
                                            <div className="z-50 relative">
                                                <UploadAosApp
                                                    handleSubmit={handleSealing}
                                                    control={control}
                                                    formData={formData}
                                                    onFieldsChange={onFieldsChange}
                                                    buttonText={buttonText}
                                                    viewType={viewType}
                                                    className="flex gap-10 flex-wrap xl:flex-nowrap mb-5"
                                                    validateInput={validateInput}
                                                    securityMeasure={securityMeasure}
                                                    nativePlanType={nativePlanType}
                                                    hybridPlanType={hybridPlanType}
                                                    showGuide={showGuide}
                                                />
                                            </div>
                                            <SecurityFeatures viewType={viewType} />
                                        </TabPanel>
                                        <TabPanel value={selectedTab} index={1}>
                                            <BasicConfiguration
                                                formData={formData}
                                                control={control}
                                                errors={errors}
                                                onFieldsChange={onFieldsChange}
                                                validateInput={validateInput}
                                                isAndroidExpired={isAndroidExpired}
                                                isReleaseEnabled={isReleaseEnabled}
                                                fileType={fileType}
                                                sealingData={sealingData}
                                                serviceables={serviceables}
                                                getEl={getEl}
                                            />
                                        </TabPanel>
                                        <TabPanel value={selectedTab} index={2}>
                                            <p className="font-semibold text-base mb-8 underline">{t('environment_detection')}</p>
                                            <div className="pl-7">
                                                <EnvironmentConfiguration getEl={getEl} />
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={selectedTab} index={3}>
                                            <p className="font-semibold text-base mb-8 underline">{t('tools_and_packages')}</p>
                                            <div className="pl-7">
                                                <ToolsAndPackages getEl={getEl} />
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={selectedTab} index={4}>
                                            <p className="font-semibold text-base mb-8 underline">{t('advanced_detection')}</p>
                                            <div className="pl-7">
                                                <AdvancedDetection getEl={getEl} />
                                            </div>
                                        </TabPanel>
                                    </div>
                                </Box>
                                <div className="min-w-[300px] max-w-[400px] w-1/4 px-6 mt-16 mb-5 pt-8 pb-5 border-l border-[#E0E0E0] overflow-y-auto" style={{ maxHeight: 'calc(100vh - 159px)' }}>
                                    {selectedTab !== 0 && (
                                        <UploadAosApp
                                            handleSubmit={handleSealing}
                                            control={control}
                                            formData={formData}
                                            onFieldsChange={onFieldsChange}
                                            buttonText={buttonText}
                                            viewType={viewType}
                                            validateInput={validateInput}
                                            securityMeasure={securityMeasure}
                                            nativePlanType={nativePlanType}
                                            hybridPlanType={hybridPlanType}
                                            sendPresets={applyPreset}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </form>
                </React.Fragment>
            )}
        </div>
    );
};

export default AndroidSealing;
