import './index.less';
import { signUpData } from './AuthData';
import { AppURL } from '../../utility/AppURL';
import { showDialog, showToast } from '../utils/notifications';
import { signup, validateEmail } from '../../services/login';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './languageselector';
import AuthLayout from '.';
import AuthForm from './AuthForm';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import LoadMask from '../utils/loadmask';


declare global {
  interface Window {
    trackingFunctions: {
      onLoad: (config: { appId: string }) => void;
    };
  }
}


const SignUp = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const [loadMask, showLoadMask] = useState<any>(false);
    const [formKey, setFormKey] = useState(0);
    const [isSignUpSuccess, setSignUpSuccess] = useState(false);
    const [extraParams, setExtraParams] = useState<any>({});
    const [inviteParams, setInviteParams] = useState<any>(null);
    const [registrationToken, setRegistrationToken] = useState<any>(null);
    const [formData, setFormData] = useState<any>(null);
    const [enableBack, setEnableBack] = useState(true);
    const [emailValidationError, setEmailValidationError] = useState<boolean>(false);

    let query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

    useEffect(() => {
        if (location && location.pathname === '/awssignup') setRegistrationToken(query.toString());
        else if (location && location.pathname === '/invites/signup') {
            setInviteParams({
                invite_by: query.get('invite_by') || '',
                token: query.get('invite_token') || '',
                user_id: query.get('user_id') || '',
            });

            signUpData[1].value = query.get('user_id') || '';
            signUpData[1].disabled = true;
            setEnableBack(false);
            setFormData(signUpData);
        }

        if (query.get('utm_source'))
            setExtraParams((e: any) => ({
                ...e,
                utm_source: query.get('utm_source'),
            }));
        if (query.get('utm_medium'))
            setExtraParams((e: any) => ({
                ...e,
                utm_medium: query.get('utm_medium'),
            }));
        if (query.get('utm_campaign'))
            setExtraParams((e: any) => ({
                ...e,
                utm_campaign: query.get('utm_campaign'),
            }));
        setFormData(signUpData);
    }, [location, query]);

    useEffect(() => {
        const win: any = window;

        if (win?.insent?.widget) {
            win.insent.widget('show');
        } else {
            const script = document.createElement('script');

            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://appsealing.widget.insent.ai/insent';
            script.defer = !0;

            win.insentCompanyDomain = 'appsealing.com';
            win.insentProjectName = 'appsealing';
            win.insentUseCompleteURL = true;
            win.insentProjectKey = 'QE3tkx4XHTCH7hNENc8c';

            const t: any = win.insent || {};
            t.queue = [];
            t.SCRIPT_VERSION = '0.1.3';
            t.methods = ['widget', 'listener', 'setVisitor'];
            t.factory = (e: any) => {
                return function () {
                    const n = Array.prototype.slice.call(arguments);
                    n.unshift(e);
                    t.queue.push(n);
                    return t;
                };
            };
            t.methods.forEach((e: any) => {
                t[e] = t.factory(e);
            });
            win.insent = t;

            document.readyState === 'complete'
                ? document.getElementById('signup')?.appendChild(script)
                : window.addEventListener('load', function (n) {
                      document.getElementById('signup')?.appendChild(script);
                  });
        }
        return () => {
            if (win?.insent?.widget) win.insent.widget('hide');
        };
    }, []);

    const onSignUpClick = async (values: any) => {
        const isWorkEmail = await validateEmail({ user_id: values.user_id });
        setEmailValidationError(!isWorkEmail);
        setFormKey(formKey + 1);
        if (!isWorkEmail) {
            return;
        }

        let { agreement, ...params } = values;
        params = {
            locale: i18n.language === 'kr_KR' ? 'KR' : 'EN',
            is_free_mail: 'N',
            ...params,
            ...extraParams,
        };

        if (location.pathname === '/awssignup') {
            if (registrationToken) params = { registration_token: registrationToken, ...params };
            else {
                showToast('error', t('RCM-1151'));
                return;
            }
        } else if (location.pathname === '/invites/signup') {
            if (inviteParams.user_id && inviteParams.invite_by && inviteParams.token)
                params = {
                    ...params,
                    user_id: inviteParams.user_id,
                    invite_by: inviteParams.invite_by,
                    token: inviteParams.token,
                };
            else {
                showToast('error', t('inivitation_token_value_is_invalid'));
                return;
            }
        }

        showLoadMask({ msg: t('validating_user_information') });
        const response = await signup(params);
        showLoadMask(false);
        if (response?.result?.code === '0000') {
            showToast('success', t('account_created_successfully'));
            setSignUpSuccess(true);
        } else if (response?.result?.code === '1140') {
            showDialog({
                title: t('sign_up'),
                content: t(`RCM-${response?.result?.code}`),
                onOk: () => navigate('/login'),
                okText: t('yes'),
                cancelText: t('no'),
            });
        } else if (response?.result?.code === '1151' || response?.result?.code === '1152') {
            showDialog({
                title: t('sign_up'),
                content: t(`RCM-${response?.result?.code}`, 'Failed to create account. Please try again later'),
                okButtonProps: { hidden: true },
                cancelText: t('close'),
            });
        } else if (response?.result?.code === '2700') {
            showToast('error', t(`SIGNUP-${response?.result?.code}`, response?.result?.message) as string);
        } else if (t(`RCM-${response?.result?.code}`, '') !== '') showToast('error', t(`RCM-${response?.result?.code}`, response?.result?.message || 'Failed to create account') as string);
        else showToast('error', t('failed_to_create_account'));
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `
        window.faitracker=window.faitracker||function(){this.q=[];var t=new CustomEvent("FAITRACKER_QUEUED_EVENT");return this.init=function(t,e,a){this.TOKEN=t,this.INIT_PARAMS=e,this.INIT_CALLBACK=a,window.dispatchEvent(new CustomEvent("FAITRACKER_INIT_EVENT"))},this.call=function(){var e={k:"",a:[]};if(arguments&&arguments.length>=1){for(var a=1;a<arguments.length;a++)e.a.push(arguments[a]);e.k=arguments[0]}this.q.push(e),window.dispatchEvent(t)},this.message=function(){window.addEventListener("message",function(t){"faitracker"===t.data.origin&&this.call("message",t.data.type,t.data.message)})},this.message(),this.init("sqo9pc0mbxrisw4tkv1dg2pax02wkhkq",{host:"https://api.dyh8ken8pc.com"}),this}(),function(){var t=document.createElement("script");t.type="text/javascript",t.src="https://asset.dyh8ken8pc.com/dyh8ken8pc.js",t.async=!0,(d=document.getElementsByTagName("script")[0]).parentNode.insertBefore(t,d)}();
        `;
        document.head.appendChild(script);

        const clearbitScript = document.createElement('script');
        clearbitScript.type = 'text/javascript';
        clearbitScript.src = 'https://tag.clearbitscripts.com/v1/pk_727dd43bc9d58d45b64833b71857aa3d/tags.js';
        clearbitScript.referrerPolicy = 'strict-origin-when-cross-origin';
        document.head.appendChild(clearbitScript);

        return () => {
            document.head.removeChild(clearbitScript);
        };
    }, []);

    const footerElements = (
        <div className="flex justify-end mt-2.5 text-sm">
            <span className="mr-2">{t('already_a_member')}</span>
            <Link to="/login" className="text-linkblue underline hover:text-accent">
                {t('login_here')}
            </Link>
        </div>
    );
    return (
        <>
            <div className="script-container"></div>
            {loadMask && <LoadMask msg={loadMask.msg} style={{ zIndex: '10007', background: '#88295C', color: 'white' }} />}
            <AuthLayout BackButton={enableBack}>
                {isSignUpSuccess ? (
                    <div className={`pt-[120px] px-5 sm:px-10 md:px-[80px] pb-8`}>
                        <div className={`text-xl font-medium text-center`}>{t('signed_up_successfully')}</div>
                        <div className=" text-center mb-20">{t('sign_up_thank_you')}</div>
                        <div className="px-5">
                            <p>{t('a_verification_email_has_been_sent_to_the_provided_email_address')}</p>
                            <p>{t('please_check_the_link_in_the_email_to_activate_your_account')}</p>
                        </div>
                        <div className="mt-4 text-sm italic">
                            <p className="px-5">
                                <span>{t('if_you_havent_reveived_email_please_connect_to_our_')}</span>
                                <a href={AppURL.helpCenter} className="bold underline text-linkblue">
                                    {t('help_desk_team')}
                                </a>
                            </p>
                        </div>
                        <Button className="w-full h-[42px] mt-24" variant="contained" onClick={() => navigate('/login')}>
                            {t('back_to_login')}
                        </Button>
                        <div className={`flex justify-end w-full mt-2`}>
                            <LanguageSelector />
                        </div>
                    </div>
                ) : (
                    formData && (
                        <AuthForm
                            title={'sign_up'}
                            data={formData}
                            btnText="sign_up"
                            onSubmit={onSignUpClick}
                            footerElements={footerElements}
                            signup={true}
                            emailValidationError={emailValidationError}
                            formKey={formKey}
                        />
                    )
                )}
            </AuthLayout>
        </>
    );
};

export default SignUp;
