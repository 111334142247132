import React, { useEffect} from "react";

import './index.less';
import Aism from "./aism";
import AppConfig from "./appconfig";
import IOSSealing from "./sealing";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getHybridSDKInfo, getNativeSDKInfo } from "../../../services/sealing/ios";
import { setIOSSdkInfo } from "../../../store/sealing/ios";
import ContentLayout from "../../Layout/Content";
import { useLocation, useNavigate } from "react-router-dom";



const AppServiceiOS = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const sdkInfo = useSelector((state: RootState) => state.iOSSealingReducer.sdkInfo);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
   
    useEffect(() => {
        if (sdkInfo === null) {
            const fetchSdkInfo = async () => {
                let data: any = {};
                let nativeParams = {
                    token: authContext.token,
                    service_type: 'NATIVE_IOS'
                };
                const nativeSDKInfoResponse = await getNativeSDKInfo(nativeParams, region);
                if (nativeSDKInfoResponse?.result.code === '0000' && Array.isArray(nativeSDKInfoResponse.IosSdkVersion))
                    data.nativeSdkInfo = nativeSDKInfoResponse.IosSdkVersion;

                let hybridParams = {
                    token: authContext.token,
                    service_type: 'NATIVE_IOS'
                };
                const hybridSDKInfoResponse = await getHybridSDKInfo(hybridParams, region);
                if (hybridSDKInfoResponse?.result.code === '0000' && Array.isArray(hybridSDKInfoResponse.IosSdkVersion))
                    data.hybridSdkInfo = hybridSDKInfoResponse.IosSdkVersion;

                dispatch(setIOSSdkInfo(data));
            };
            fetchSdkInfo();
        }
    }, [region, sdkInfo, authContext.token, dispatch]);

    const renderComponent = () => {
        switch (location.pathname) {
          case "/sealingios/aism":
            return <Aism />;
          case "/sealingios/configurations":
            return <AppConfig />;
          case "/sealingios/projects":
            return <IOSSealing />;
          default:
            navigate("/sealingios/aism"); 
            return <Aism />;
        }
      };

    
    return (
      <ContentLayout fullWidth={true}>
        {renderComponent()}
      </ContentLayout>
    );
};

export default AppServiceiOS;