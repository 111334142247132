import { Get, Post, Put } from '../..';
import {
    AccountsInformationInterface,
    ChangePasswordInterface,
    MemberInfoInterface,
    PreferencesInterface,
    ProfileInformationInterface,
    MemberActivationInterface,
    SetPasswordSecurityInterface,
} from '../../../type/api/manage';
import { AppURL } from '../../../utility/AppURL';

export const saveProfileInfo = async (params: ProfileInformationInterface) => {
    let urlParams = new URLSearchParams();

    const { first_name = '', last_name = '', mobile = '', registered_date, token } = params;
    urlParams.append('division', 'M');
    urlParams.append('first_name', first_name);
    urlParams.append('last_name', last_name);
    urlParams.append('mobile', mobile);
    urlParams.append('registered_date', registered_date);
    urlParams.append('token', token);

    let response = await Post(AppURL.profile, urlParams).then((res) => res.json());

    return response;
};

export const getAccountsInformation = async (params: AccountsInformationInterface) => {
    let { token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);

    let response = await Post(AppURL.information, urlParams).then((res) => res.json());

    return response;
};

export const saveAccountInfo = async (params: AccountsInformationInterface) => {
    let urlParams = new URLSearchParams();

    for (let [key, value] of Object.entries(params)) {
        urlParams.append(key, value || '');
    }

    let response = await Post(AppURL.information, urlParams).then((res) => res.json());

    return response;
};

export const getMemberList = async (params: { token: string }) => {
    let { token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);

    let response = await Post(AppURL.getaccountmemberlist, urlParams).then((res) => res.json());

    return response;
};

export const getInviteHistory = async (params: { token: string }) => {
    let { token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);

    let response = await Post(AppURL.getmemberinvitehistory, urlParams).then((res) => res.json());

    return response;
};

export const sendAccountInvite = async (params: { email: string; token: string }) => {
    let { token, email } = params,
        urlParams = new URLSearchParams();

    urlParams.append('member_email', email);
    urlParams.append('token', token);

    let response = await Post(AppURL.inviteaccountmember, urlParams)
        .then(() => ({ result: { code: '0000', message: 'success' } }))
        .catch(() => ({
            result: { code: '9999', message: 'Failed to activate member' },
        }));

    return response;
};
export const activateMember = async (params: MemberActivationInterface) => {
    let { token, user_id } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);
    urlParams.append('user_id', user_id);

    let response = await Post(AppURL.activateaccountmember, urlParams)
        .then(() => ({ result: { code: '0000', message: 'success' } }))
        .catch(() => ({
            result: { code: '9999', message: 'Failed to activate member' },
        }));

    return response;
};

export const deactivateMember = async (params: MemberActivationInterface) => {
    let { token, user_id } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);
    urlParams.append('user_id', user_id);

    let response = await Post(AppURL.deactivateaccountmember, urlParams)
        .then(() => ({ result: { code: '0000', message: 'success' } }))
        .catch(() => ({
            result: { code: '9999', message: 'Failed to deactivate member' },
        }));

    return response;
};
export const updateAccountMember = async (params: MemberInfoInterface) => {
    let { account_id, currentNote = '', division = 'M', joined, member_email, member_name, notes, token, you_yn } = params,
        urlParams = new URLSearchParams();

    urlParams.append('account_id', account_id);
    urlParams.append('currentNote', currentNote);
    urlParams.append('division', division);
    urlParams.append('edit', 'true');
    urlParams.append('joined', joined);
    urlParams.append('member_email', member_email);
    urlParams.append('member_name', member_name);
    urlParams.append('notes', notes);
    urlParams.append('token', token);
    urlParams.append('you_yn', you_yn);

    let response = await Post(AppURL.editaccountmembernote, urlParams).then((res) => res.json());

    return response;
};

export const setPreferences = async (params: PreferencesInterface) => {
    let { lang_set, timezone, timezone_code, token, view_sign_yn } = params,
        urlParams = new URLSearchParams();

    urlParams.append('lang_set', lang_set);
    if (timezone) urlParams.append('timezone', timezone);
    if (timezone_code) urlParams.append('timezone_code', timezone_code);
    urlParams.append('token', token);
    if (view_sign_yn) urlParams.append('view_sign_yn', view_sign_yn);

    let response = await Post(AppURL.preference, urlParams).then((res) => res.json());

    return response;
};

export const changePwd = async (params: ChangePasswordInterface) => {
    let { user_pw, new_user_pw, new_user_pw_confirm, token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('new_user_pw', new_user_pw);
    urlParams.append('new_user_pw_confirm', new_user_pw_confirm);
    urlParams.append('token', token);
    urlParams.append('user_pw', user_pw);

    let response = await Post(AppURL.changePwd, urlParams).then((res) => res.json());

    return response;
};

export const getPasswordSettings = async ({ companyId }: { companyId: number }) => {
    let response = await Get(AppURL.companySecurity + companyId)
        .then((res) => res.json())
        .catch((e) => 'error');

    return response;
};

export const setPasswordSettings = async (params: SetPasswordSecurityInterface) => {
    const header = {
        'Content-Type': 'application/json',
    }

    await togglePasswordSettings(params);

    let response = await Put(AppURL.companySecurity, JSON.stringify(params), header);

    return response;
};


const togglePasswordSettings = async (params: SetPasswordSecurityInterface) => {
    const header = {
        'Content-Type': 'application/json',
    };


    let duplicateCheckUrl = `${AppURL.companySecurity}${params.companyId}/password-policies/duplicate-check/`;
    duplicateCheckUrl += params.passwordCount === -1 ? 'disable' : 'enable';

    await Put(duplicateCheckUrl, null, header);


    let expirationCheckUrl = `${AppURL.companySecurity}${params.companyId}/password-policies/expiration-check/`;
    expirationCheckUrl += params.passwordExpiration === -1 ? 'disable' : 'enable';

    await Put(expirationCheckUrl, null, header);


    return true;
}