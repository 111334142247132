import React, { useEffect } from 'react';
import './index.less';
import AndroidHistoryInfo from './historyinfo';
import AndroidAppConfig from './appconfig';
import AndroidSealing from './sealing';
import ManageKeyStore from './managekeystore';
import { getKeyStoreInfo, getPresets } from '../../../services/sealing/android';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { setKeyStoreInfo, setPresets } from '../../../store/sealing/android';
import { useNavigate } from 'react-router';
import ContentLayout from '../../Layout/Content';
import { useLocation } from 'react-router-dom';
import SealingPreset from './preset';
import { getAccountsInformation } from '../../../services/manage/accounts';
import { getCookies } from '../../../utility/Common';

const Android = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);
    const region = userDetails?.default_service_region;
    const companyId = Number(getCookies('companyId'));
    useEffect(() => {
        if (authContext?.token) {
            const fetchAccountInfo = async (token: string) => {
                const params = { token };
                if (companyId) {
                    const response = await getAccountsInformation(params);
                    if (response?.result.code === '0000' && response?.result.message === 'SUCCESS') {
                        const fetchPreset = await getPresets(response.account.account_id, response.auth.token);
                        dispatch(setPresets(fetchPreset.data));
                    }
                }
            };
            fetchAccountInfo(authContext.token);
        }
    }, [authContext, dispatch]);
    useEffect(() => {
        const fetchKeyStoreInfo = async () => {
            const params = {
                token: authContext.token,
                account_status: authContext.account_status,
                account_type: authContext.account_type,
                auth_key: authContext.auth_key,
                role: authContext.role,
                user_status: authContext.user_status,
            };
            const response = await getKeyStoreInfo(params, region);
            if (response?.result?.code === '0000') dispatch(setKeyStoreInfo(response?.result?.keystore));
        };
        fetchKeyStoreInfo();
    }, [region, authContext, dispatch]);

    useEffect(() => {
        if (location.pathname.includes('start-securing-your-app')) {
            navigate('/sealingandroid/sealing');
        }
    }, [location, navigate]);

    const renderComponent = () => {
        switch (location.pathname) {
            case '/sealingandroid/sealing':
                return <AndroidSealing />;
            case '/sealingandroid/history':
                return <AndroidHistoryInfo />;
            case '/sealingandroid/configurations':
                return <AndroidAppConfig />;
            case '/sealingandroid/keystore':
                return <ManageKeyStore />;
            case '/sealingandroid/preset':
                return <SealingPreset />;
            default:
                navigate('/sealingandroid/sealing');
                return <AndroidSealing />;
        }
    };

    const checkPath = () => {
        if (location.pathname === '/sealingandroid/sealing' || location.pathname.includes('start-securing-your-app')) {
            return true;
        }

        return false;
    };

    return (
        <ContentLayout fullWidth={true} paddingNone={checkPath()}>
            {renderComponent()}
        </ContentLayout>
    );
};

export default Android;
