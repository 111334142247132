
import { Button } from "../../../../Foundation/Button";
import Modal from "../../../../Feedback/Modal";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { requestAppTransfer } from "../../../../../services/securityservices";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { FormControl, InputLabel, TextField, Select, MenuItem, FormHelperText, Typography } from "@mui/material";
import { showToast } from "../../../../utils/notifications";

interface AccountTransferInterface {
    visible: boolean;
    appData?: any;
    onCancel: (reloadData?: any) => void;
};

const AccountTransfer = (props: AccountTransferInterface) => {
    const { t } = useTranslation();
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
      } = useForm({
        defaultValues: {
            target_mail: "",
            transfer_reason_select:"",
            transfer_reason: "",
        },
      });
    useEffect(() => {
        if (props.visible) reset();
    }, [props.visible, reset]);

    const onRequestTransferClick = async () => {
        const { target_mail, transfer_reason_select, transfer_reason = "" } = watch();
        if (props.appData) {
            const params = {
                token: authContext.token,
                package_name: props.appData.package_name,
                target_mail, transfer_reason, transfer_reason_select
            };
            const response = await requestAppTransfer(params, region);
            if (response?.result?.code === "0000") {
                showToast('success', t('application_trasferred_successfully'));
                props.onCancel(true);
            }
            else if (response?.result?.code)
                showToast('error', t(`RCM-${response?.result?.code}`,
                    t('app_service_failed_to_update_for_package')));
            else
                showToast('error', t('error_while_transferring_account'));
        }
    };

    const CustomTransferHeader = (
        <div className='text-black-900 mb-[60px]'>
          <Typography variant="h1" className="mb-3">
            {t("request_transfer_for_app_ownership")} - {props.appData?.package_name}
          </Typography>
          <span className='block text-sm font-normal max-w-[700px]'>
            {t('you_will_not_able_to_access_this_app')}
          </span>
        </div>
    );
    
    return (
        <Modal header={CustomTransferHeader} maxWidth={'sm'}
            open={props.visible} handelCancel={props.onCancel}
            footer={[
                <Button color="primary" onClick={handleSubmit(onRequestTransferClick)} >{t('request_transfer')}</Button>,
                <Button color="secondary" className='ant-btn-tertiary' onClick={() => reset()}>{t('reset')}</Button>
            ]}
        >
            <form onSubmit={handleSubmit(onRequestTransferClick)} className="pt-2">
                <FormControl fullWidth error={!!errors.target_mail} size="small" className="mb-10">
                    <Controller
                        name="target_mail"
                        control={control}
                        rules={{ required: t('please_enter_valid_email_address') }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                className='h-[40px]'
                                size="small"
                                error={!!error}
                                placeholder={t("enter_email_address")}
                                label={t("email_address")}
                                helperText={error ? error.message : null}
                                fullWidth
                                required
                            />
                        )}
                    />
                </FormControl>
                <FormControl fullWidth error={!!errors.transfer_reason} size='small' className="mb-10">
                    <Controller
                        name="transfer_reason_select"
                        control={control}
                        rules={{ required: t('please_select_transfer_reason') }}
                        render={({ field, fieldState: { error } }) => (
                             <>
                                <InputLabel id="transfer_reason_label" className={`${!!error ? "text-[#FF0000]" : ""}`}>{`${t("target_type")} *`}</InputLabel>
                                <Select
                                    {...field}
                                    labelId="transfer_reason_label"
                                    id="transfer_reason_select"
                                    placeholder={t('please_select_transfer_reason')}
                                    label={`${t("transfer_reason")} *`}
                                    error={!!error}
                                    fullWidth
                                    required
                                >
                                    <MenuItem value='1'>{t('i_want_to_grant_team_member_to_use_appsealing_with_this_app')}</MenuItem>
                                    <MenuItem value='2'>{t('i_want_to_use_company_mail_rather_than_public_mail_for_the_test')}</MenuItem>
                                    <MenuItem value='3'>{t('app_publisher_company_domain_is_changed')}</MenuItem>
                                    <MenuItem value='4'>{t('other')}</MenuItem>
                                </Select>
                                {error && <FormHelperText className="text-[#FF0000]">{error.message}</FormHelperText>}
                             </>
                        )}
                    />
                </FormControl>
                <FormControl fullWidth className="mb-[60px]">
                    <Controller
                        name="transfer_reason"
                        control={control}
                        render={({ field }) => (
                        <TextField
                            {...field}
                            label={t('detailed_reason')}
                            placeholder={t('enter_detailed_reason_for_transfer')}
                            multiline
                            minRows={3}
                            variant="outlined"
                            fullWidth
                        />
                        )}
                    />
                </FormControl>
        </form>
        </Modal>
    );
};

export default AccountTransfer;