import moment from "moment";
import {
  GridColDef,
  GridColumnGroupingModel,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import DataTable from "../../DataDisplay/Table";

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: "Hacking Details",
    headerName: "Hacking Details",
    headerClassName: 'text-[12px] text-subtitle font-medium border-none max-h-[20px] align-bottom self-end',
    children: [
      { field: "cntTotalHits" },
      { field: "desc_en" },
      { field: "msg" }
    ],
  },
];

interface HackingAttemptsTableProps {
  rows: any[];
  history?: boolean;
}

const HackingAttemptsDetailTable = ({
  rows,
  history = false,

}: HackingAttemptsTableProps) => {
  const { t } = useTranslation();
	
  let columns: GridColDef[] = [
    {
      field: "register_datetime",
      headerName: t("reported_on"),
      flex: 1,
      renderCell: ({ value }) => value ? moment(value, "YYYYMMDDHHmmss").format("YYYY/MM/DD HH:mm:ss") : "",
    },
    {
      field: "app_version_code",
      headerName: t("app_version_code"),
      flex: 1,
    },
    {
      field: "app_version_name",
      headerName: t("app_version_name"),
      flex: 1,
    },
    {
      field: "core_version",
      headerName: t("appSealing_version"),
      flex: 1,
    },
    {
      field: "cntTotalHits",
      headerName: t("attempts"),
      flex: 1,
      renderCell: ({ value }) => value ? value?.toLocaleString() : value,
      sortComparator: (v1, v2) => v1 - v2,
    },
    {
      field: "desc_en",
      headerName: t("hacking_type"),
      flex: 1,
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "msg",
      headerName: t("details"),
      flex: 1,
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
  ];


const historyColumns: GridColDef[] = [
  {
    field: "calc_date",
    headerName: t("reported_on"),
    flex: 1,
    renderCell: ({ value }) => value ? moment(value, "YYYYMMDD").format("YYYY/MM/DD") : "",
    sortComparator: (v1: string, v2: any, param1: { value: string }, param2: { value: string }) =>
      moment(param1.value, "YYYYMMDD").unix() - moment(param2.value, "YYYYMMDD").unix(),
  },
  {
    field: "desc_en",
    headerName: t("hacking_type"),
    flex: 1,
    sortComparator: (v1: string, v2: any) => v1.localeCompare(v2),
  },
  {
    field: "detail",
    headerName: t('hacking_details'),
    flex: 1,
    sortComparator: (v1: string, v2: any) => v1.localeCompare(v2),
  },
  {
    field: "hacking_cnt",
    headerName: t("protected_hacking_attempts"),
    flex: 1,
    valueFormatter: ({ value }: { value: string }) => value?.toLocaleString() || value,
    sortComparator: (v1: any, v2: any) => v1 - v2,
  },
];

  return (
    <>
		<div className='w-full min-h-[400px] bg-primary hacking-attempt-table'>
      <DataTable
        columns={history ? historyColumns : columns}
        rows={rows}
        pageSize={9}
        pageSizeOptions={[9, 5, 10, 20, 40]}
				disableColumnSelector={false}
        columnGroupingModel={history ? undefined : columnGroupingModel}
      />
    </div>
		</>
  );
};

export default HackingAttemptsDetailTable;
