import React, { useEffect, useState } from 'react';

import { Button, IconButton, InputAdornment, TextField } from '@mui/material';

import LoadMask from '../../../utils/loadmask';
import IOSServiceCard from '../../../form/field/cards/ios';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { filterBy } from '../../../../utility/filtering';
import { showDialog, showToast } from '../../../utils/notifications';
import { updateService } from '../../../../services/securityservices';
import { getAppList } from '../../../../services/manage/settings';
import { useTranslation } from 'react-i18next';

const AppConfig = () => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<any>([]);
    const [filterText, setFilterText] = useState('');
    const [showMask, setShowMask] = useState<any>(null);
    const [pageSize, setPageSize] = useState<number>(20);
    const [appList, setAppList] = useState<any[] | null>(null);
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);

    useEffect(() => {
        if (appList === null) {
            const fetchApplications = async () => {
                let data = [];
                const params = {
                    token: authContext.token,
                };
                setShowMask({ msg: t('fetching_application') });
                const response = await getAppList(params, region);
                setShowMask(null);
                if (response?.result?.code === '0000' && Array.isArray(response?.packageOnOffStatus)) {
                    data = response?.packageOnOffStatus.filter((item: any) => item.platform === 'ios');
                }
                setAppList(data);
            };
            fetchApplications();
        }
    }, [region, appList, authContext, t]);
    const handleClearClick = () => {
        setFilterText('');
        setFilters([]);
    };

    const onSearchClick = (e: any) => {
        const value = e.target.value;
        let filter: any = [];
        if (value) {
            filter = {
                logic: 'or',
                filters: [
                    { field: 'app_name', value: value, operator: 'contains' },
                    { field: 'package_name', value: value, operator: 'contains' },
                ],
            };
        }
        setFilterText(value);
        setFilters(filter);
    };

    const updateServices = async (type: string, params: any) => {
        let data: any = null;
        if (type === 'service') {
            const response = await updateService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('app_service_is_updated_for_the_package')} ${params.package_name}`);
                data = { service_onoff: params.status };
            } else if (response?.result?.code) showToast('error', t(`SCM-${response?.result?.code}`, t('app_service_failed_to_update_for_package')));
            else showToast('error', `${t('app_service_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        }

        if (data && Array.isArray(appList)) {
            setAppList((oldData: any) => {
                const index = oldData.findIndex((e: any) => e.platform === 'ios' && e.package_name === params.package_name);
                let item = oldData[index];
                oldData[index] = { ...item, ...data };
                return [...oldData];
            });
        }
    };

    const onSwitchToggle = async (type: string, params: any) => {
        showDialog({
            title: t('confirm_update', 'Confirm Update?'),
            content: params.status === 'Y' ? t('app_can_be_sealed_and_will_incur_a_monthly_fee') : t('app_cannot_be_sealed_and_data_will_not_collected_while_the_sealing_option_is_off'),
            onOk: () => updateServices(type, params),
            okText: t('yes', 'Yes'),
            cancelText: t('no', 'No'),
        });
    };

    const onShowMoreClick = () => setPageSize((oldPageSize: number) => oldPageSize + 20);

    return (
        <React.Fragment>
            {showMask && <LoadMask msg={showMask.msg} />}
            <div className="flex flex-col lg:flex-row gap-6 mb-8">
                <div className="w-full lg:w-[316px] text-2xl font-medium">{t('app_configurations')}</div>
                <div className="lg:mt-0 w-full max-w-[656px]">
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={filterText}
                        onChange={onSearchClick}
                        placeholder={t('search_app_name_or_bundle_id')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    {filterText && (
                                        <IconButton aria-label="clear text" onClick={handleClearClick} size="small" edge="end">
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>

            <div className="overflow-auto">
                <div className="flex flex-wrap gap-6">
                    {Array.isArray(appList) &&
                        appList.length > 0 &&
                        (filterBy(appList, filters) || []).slice(0, pageSize).map((item: any) => {
                            return <IOSServiceCard key={item.app_name + item.package_name} {...item} hideTag={true} onToggleSwitch={onSwitchToggle} />;
                        })}
                </div>
                {appList?.length && (filterBy(appList, filters) || []).length > pageSize ? (
                    <div className="w-full text-center mt-6">
                        <Button variant="text" onClick={onShowMoreClick}>
                            {t('show_more')}
                        </Button>
                    </div>
                ) : (
                    appList?.length === 0 ? <div className="text-xl text-center py-52 mx-auto whitespace-pre">{t('please_apply_appsealing_first')}</div>
                        :(filterBy(appList, filters) || []).length === 0 && <div className="text-xl text-center py-52 mx-auto whitespace-pre">{t('no_results_found')}</div>
                )}
            </div>
        </React.Fragment>
    );
};

export default AppConfig;
