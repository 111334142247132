import React, { ReactNode, useEffect, useState } from 'react';
import { Box, CircularProgress, FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import DragnDrop from '../../../utils/dragndrop';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../../utils/notifications';
import { AppURL } from '../../../../utility/AppURL';
import { openHelpCenter } from '../../../../services/main';
import { isHybridFramework } from './data';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const acceptFiles = {
    'application/zip': ['.zip'],
    'application/vnd.android.package-archive': ['.apk'],
    'application/x-apk-bundle': ['.aab'],
};

const normFile = (e: any, isHybridFramework: boolean, nativePlan: string | null, hybridPlan: string | null, i81n: any, t: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    let fileSize = 512;
    if (isHybridFramework) {
        if (hybridPlan === 'P') fileSize = 3072;
        else if (hybridPlan === 'E') fileSize = 3072;
    } else {
        if (nativePlan === 'P') fileSize = 3072;
        else if (nativePlan === 'E') fileSize = 3072;
    }

    if (e.file.size / Math.pow(10, 6) > fileSize) {
        showToast(
            'error',
            `${t('the_file_is_too_large')} 
    ${i81n.language === 'kr_KR' ? t('file_size_is_more_than_allocated_configs2') + fileSize + 'MB ' : ''}
    ${t('file_size_is_more_than_allocated_configs')}
    ${i81n.language === 'kr_KR' ? '' : fileSize + 'MB.'}`,
        );
        return [];
    }
    const file = e && e.fileList.length ? [e.fileList[e.fileList.length - 1]] : [];
    return file;
};

interface UploadAosAppProps {
    control: Control;
    formData: any;
    onFieldsChange: any;
    buttonText: string;
    validateInput: () => any;
    securityMeasure: any;
    nativePlanType: string | null;
    hybridPlanType: string | null;
    viewType: string;
    className?: string;
    showGuide?: boolean;
    sendPresets?: any;
    handleSubmit: () => void;
}
const UploadAosApp = ({
    control,
    formData,
    handleSubmit,
    onFieldsChange,
    buttonText,
    validateInput,
    securityMeasure,
    nativePlanType,
    hybridPlanType,
    viewType,
    className,
    showGuide,
    sendPresets,
}: UploadAosAppProps) => {
    const { i18n, t } = useTranslation();
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [presets, setPresets] = useState<any>(null);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const presetData = useSelector((state: RootState) => state.androidSealingReducer.presets);

    useEffect(() => {
        if (presets !== presetData) setPresets(presetData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [presetData]);

    useEffect(() => {
        if (showGuide !== undefined && showGuide !== showTooltip) {
            setShowTooltip(showGuide);
        }
    }, [showGuide, showTooltip]);

    const progressColor = () => {
        if (securityMeasure.securityMeasure < 50) {
            return '#E25757';
        } else if (securityMeasure.securityMeasure >= 50 && securityMeasure.securityMeasure < 76) {
            return '#FFA500';
        } else if (securityMeasure.securityMeasure >= 76) {
            return '#127BF5';
        }
    };
    const formatPercent = (percent?: number, successPercent?: number): ReactNode => {
        if (typeof percent === 'number') {
            return viewType === 'step' ? (
                percent + '%'
            ) : (
                <div>
                    <div>{t('security_level')}</div>
                    <div className="text-2xl">{percent}%</div>
                </div>
            );
        }
        return null;
    };

    const handlePresetChange = (event: SelectChangeEvent) => {
        const selectedPreset = presets.find((v: any) => v.id === event.target.value);
        sendPresets(selectedPreset);
    };

    return (
        <div className={className}>
            <div className={className ? 'w-full xl:w-1/2' : ''}>
                <Tooltip title={t('please_upload_application_file')} placement="top" arrow open={showTooltip}>
                    <div>
                        <Controller
                            name="apkfile"
                            control={control}
                            defaultValue={formData.apkfile ? formData.apkfile : []}
                            rules={{
                                required: 'Please upload an application file',
                                validate: (files) => files.length > 0 && normFile(files, isHybridFramework(formData.app_framework), nativePlanType, hybridPlanType, i18n, t) !== null,
                            }}
                            render={({ field }) => (
                                <FormControl {...field} className="w-full">
                                    <DragnDrop
                                        color="primary"
                                        appFile={formData.apkfile ? formData.apkfile : []}
                                        onChange={(files) => onFieldsChange([{ name: ['apkfile'], value: files }])}
                                        maxFiles={1}
                                        accept={acceptFiles}
                                        title={t('click_or_drag_application_file')}
                                        subtitle={t('supports_apk_or_appbundle_type_for_application_sealing')}
                                    />
                                </FormControl>
                            )}
                        />
                    </div>
                </Tooltip>
                {presets && formData.apkfile && (
                    <FormControl fullWidth size="small" className="mt-3">
                        <InputLabel id="preset-select-label">{t('preset')}</InputLabel>
                        <Select
                            disabled={!validateInput().basicSetting || (buttonText !== 'START APPSEALING' ? true : false)}
                            size="small"
                            labelId="preset-select-label"
                            id="preset-select"
                            name="sealingPresetId"
                            defaultValue={''}
                            label={t('preset')}
                            onChange={handlePresetChange}
                        >
                            {presets.map((v: any) => (
                                <MenuItem value={v.id} key={v.id}>
                                    {v.presetName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <LoadingButton
                    variant="contained"
                    className="text-md mt-5 text-white"
                    size="large"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                    loading={buttonText !== 'START APPSEALING' ? true : false}
                    disabled={!(validateInput().basicSetting && validateInput().features) || (buttonText !== 'START APPSEALING' ? true : false)}
                >
                    {t(buttonText.toLowerCase().replace(/ /g, '_'), buttonText)}
                </LoadingButton>
            </div>
            <div className={` ${viewType === 'step' ? (className ? 'w-full xl:w-1/2' : '') : 'flex flex-row gap-5 px-2 flex-wrap xl:flex-nowrap'} mt-4`}>
                {validateInput().basicSetting && validateInput().features ? (
                    <React.Fragment>
                        <div>
                            <div>
                                {viewType === 'step' ? (
                                    <div className="mb-3">
                                        <div className="text-sm font-medium">{t('security_level')}</div>
                                        <div className="flex justify-between items-center gap-x-2">
                                            <LinearProgress
                                                variant="determinate"
                                                value={securityMeasure.securityMeasure}
                                                sx={{
                                                    width: '100%',
                                                    height: 10,
                                                    borderRadius: 5,
                                                    '&.MuiLinearProgress-colorPrimary': {
                                                        backgroundColor: '#E9E9E9',
                                                    },
                                                    '& .MuiLinearProgress-bar': {
                                                        borderRadius: 5,
                                                        backgroundColor: progressColor() as any,
                                                    },
                                                }}
                                            />
                                            <div className={`text-[${progressColor() as any}] text-xs font-medium`}>{formatPercent(securityMeasure.securityMeasure)}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <Box position="relative" display="inline-flex">
                                        <CircularProgress variant="determinate" size={180} value={securityMeasure.securityMeasure} sx={{ color: progressColor() as any }} />
                                        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">{formatPercent(securityMeasure.securityMeasure)}</div>
                                    </Box>
                                )}
                            </div>
                        </div>
                        <div className="text-xs">{securityMeasure.securityTip}</div>
                    </React.Fragment>
                ) : (
                    <SecurityGuide authContext={authContext} />
                )}
            </div>
        </div>
    );
};

const SecurityGuide = (props: { authContext: any; securityMeasure?: any }) => {
    const { i18n, t } = useTranslation();

    const onHelpCenterClick = (article: string, section?: boolean) => {
        const redirectURL = AppURL.helpCenter + (i18n.language === 'en_US' ? 'en-us' : 'ko') + (section ? '/sections/' : '/articles/') + article;
        openHelpCenter(props.authContext.token, redirectURL);
    };

    return (
        <div className={'overflow-auto flex-grow'} tabIndex={-1}>
            <div>
                <h5 className="font-medium">{t('before_appsealing')}</h5>
                <p className="text-sm">
                    <span>- {t('firebase_crashlytics_should_maintain_their_original_package_name_without_obfuscate_to_be_supported_by_appsealing')}</span>
                    <span className="ml-1 text-linkblue underline cursor-pointer" onClick={() => onHelpCenterClick('360039751872')}>
                        {t('read_more')}
                    </span>
                </p>
            </div>
        </div>
    );
};
export default UploadAosApp;
