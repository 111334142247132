import { Post } from "../..";
import { AuthoriseUserInterface, WebhookInterface, WithdrawalRequestInterface } from "../../../type/api/settings";
import { AppURL, getRegionalURL, RegionalURL } from "../../../utility/AppURL";

export const requestWebhook = async (params: { payload: string }) => {
    let { payload } = params,
        urlParams = new URLSearchParams();

    urlParams.append('payload', payload);

    let response = await Post(
        AppURL.sendfeedback, urlParams
    ).then(res => res.json());

    return response;
};

export const getWebhookStatus = async (params: { token: string }) => {
    let { token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);

    let response = await Post(
        AppURL.webhookstatus, urlParams
    ).then(res => res.json());

    return response;
};

export const updateWebhook = async (params: WebhookInterface) => {
    let { token, webhook_type, webhook_url } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);
    urlParams.append('webhook_type', webhook_type);
    urlParams.append('webhook_url', webhook_url);

    let response = await Post(
        AppURL.updatewebhook, urlParams
    ).then(res => res.json());

    return response;
};

export const updateWebhookSubscription = async (params: { token: string, triggers: string[] }) => {
    let { token, triggers } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);
    let subscribeURL = AppURL.subscribetrigger,
        unsubscribeURL = AppURL.unsubscribetrigger;

    urlParams.append('trigger_code', 'ADC_NEW_ANNOUNCE');
    let responseNewAnnouncement = await Post(
        triggers.includes('ADC_NEW_ANNOUNCE') ? subscribeURL : unsubscribeURL, urlParams
    ).then(res => res.json());

    urlParams.set('trigger_code', 'INVOICE_SENT');
    let responseInvoiceSent = await Post(
        triggers.includes('INVOICE_SENT') ? subscribeURL : unsubscribeURL, urlParams
    ).then(res => res.json());

    urlParams.set('trigger_code', 'AOS_NATIVE_UPDATED');
    let responseNativeUpdated = await Post(
        triggers.includes('AOS_NATIVE_UPDATED') ? subscribeURL : unsubscribeURL, urlParams
    ).then(res => res.json());

    urlParams.set('trigger_code', 'AOS_HYBRID_UPDATED');
    let responseHybridUpdated = await Post(
        triggers.includes('AOS_HYBRID_UPDATED') ? subscribeURL : unsubscribeURL, urlParams
    ).then(res => res.json());

    let response;
    if (responseNewAnnouncement?.result?.code === '0000' && responseInvoiceSent?.result?.code === '0000' &&
        responseNativeUpdated?.result?.code === '0000' && responseHybridUpdated?.result?.code === '0000') {
        response = { code: "0000", message: "SUCCESS" };
    }
    else {
        response = { code: "0004", message: "FAILURE" };
    }
    return response;
};

export const getAppList = async (params: { token: string }, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    let { token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);

    let response = await Post(
        getRegionalURL(RegionalURL.applist, region), urlParams
    ).then(res => res.json());

    return response;
};

export const authoriseUser = async (params: AuthoriseUserInterface) => {
    let { user_id, user_pw, token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('token', token);
    urlParams.append('user_id', user_id);
    urlParams.append('user_pw', user_pw);

    let response = await Post(
        AppURL.authoriseuser, urlParams
    ).then(res => res.json());

    return response;
};

export const requestWithdrawal = async (params: WithdrawalRequestInterface) => {
    let { account_id, content, user_id, user_pw, token } = params,
        urlParams = new URLSearchParams();

    urlParams.append('account_id', account_id);
    urlParams.append('content', content);
    urlParams.append('token', token);
    urlParams.append('user_id', user_id);
    urlParams.append('user_pw', user_pw);

    let response = await Post(
        AppURL.requestwithdrawal, urlParams
    ).then(res => res.json());

    return response;
};