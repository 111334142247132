import { useTranslation } from 'react-i18next';
import ContentLayout from '../../../Layout/Content';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { AppURL } from '../../../../utility/AppURL';
import { useEffect, useState } from 'react';
import { getPasswordSettings, setPasswordSettings } from '../../../../services/manage/accounts';
import { showToast } from '../../../utils/notifications';
import { getCookies } from '../../../../utility/Common';

const SecuritySettings = () => {
    const { t } = useTranslation();
    const [passwordCount, setPasswordCount] = useState<number>(5);
    const [passwordExpiration, setPasswordExpiration] = useState<number>(90);
    const PasswordReuseCount = [1, 2, 3, 4, 5];
    const PasswordExpirationDays = [30, 60, 90, 180];
    const companyId = Number(getCookies('companyId'));

    useEffect(() => {
        if (companyId) getPasswordSettingsInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPasswordSettingsInfo = async () => {
        const response = await getPasswordSettings({ companyId });

        if (response?.passwordCount) {
            setPasswordCount(response.passwordCount);
        }
        if (response?.passwordExpiration) {
            setPasswordExpiration(response.passwordExpiration);
        }
    };

    const handlePasswordSettingChange = async (e: any) => {
        if (!companyId) return;
        const { name, value } = e.target;
        const params: { companyId: number; passwordCount: number; passwordExpiration: number } = {
            companyId,
            passwordCount: passwordCount,
            passwordExpiration: passwordExpiration,
        };
        
        if (name === 'passwordCount') {
            setPasswordCount(value);
            params['passwordCount'] = Number(value);
        } else {
            setPasswordExpiration(value);
            params['passwordExpiration'] = Number(value);
        }
        const response = await setPasswordSettings(params);
        if (response?.status === 200) {
            showToast('success', t('password_settings_updated'));
        } else {
            showToast('error', t('error_occurred_try_again'));
        }
    };

    return (
        <ContentLayout title={t('security')}>
            <div className="max-w-[840px] w-full">
                <div className="mb-[60px]">
                    <div className="flex items-center w-full gap-2">
                        <Typography variant="h4" className="whitespace-nowrap">
                            {t('password_reuse_policy_title')}
                        </Typography>
                        <hr className="block w-full" />
                    </div>

                    <div className="mt-6">
                        <FormControl size="small" variant="outlined" className="w-1/2 max-w-[240px]">
                            <InputLabel>{t('password_reuse_limit')}</InputLabel>
                            <Select
                                value={passwordCount}
                                onChange={handlePasswordSettingChange}
                                label={t('password_reuse_limit')}
                                inputProps={{
                                    name: 'passwordCount',
                                    style: { textAlign: 'center', textAlignLast: 'center' },
                                }}
                            >
                                <MenuItem key={'disable'} value={-1}>
                                    {t('disable_password_reuse')}
                                </MenuItem>
                                {PasswordReuseCount.map((i) => (
                                    <MenuItem key={i} value={i}>
                                        {i}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="mt-2 italic">
                        <FormHelperText>
                            {t('password_reuse_policy')}{' '}
                            <a href={AppURL.helpCenter} className="bold underline text-linkblue">
                                {t('help_desk_team')}
                            </a>
                        </FormHelperText>
                    </div>
                </div>
                <div>
                    <div className="flex items-center w-full gap-2">
                        <Typography variant="h4" className="whitespace-nowrap">
                            {t('password_expiration_title')}
                        </Typography>
                        <hr className="block w-full" />
                    </div>

                    <div className="mt-6">
                        <FormControl size="small" variant="outlined" className="w-1/2 max-w-[240px]">
                            <InputLabel>{t('password_validity_period')}</InputLabel>
                            <Select
                                value={passwordExpiration}
                                onChange={handlePasswordSettingChange}
                                label={t('password_validity_period')}
                                inputProps={{
                                    name: 'passwordExpiration',
                                    style: { textAlign: 'center', textAlignLast: 'center' },
                                }}
                            >
                                <MenuItem key={'disable'} value={-1}>
                                    {t('disable_password_expiration')}
                                </MenuItem>
                                {PasswordExpirationDays.map((i) => (
                                    <MenuItem key={i} value={i}>
                                        {i}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="mt-2 italic">
                        <FormHelperText>
                            {t('password_expiration')}{' '}
                            <a href={AppURL.helpCenter} className="bold underline text-linkblue">
                                {t('help_desk_team')}
                            </a>
                        </FormHelperText>
                    </div>
                </div>
            </div>
        </ContentLayout>
    );
};

export default SecuritySettings;
