import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { showDialog, showToast } from '../../../utils/notifications';
import LoadMask from '../../../utils/loadmask';
import DragnDrop from '../../../utils/dragndrop';
import { deleteKeyStore, uploadKeyStore } from '../../../../services/sealing/android';
import { setKeyStoreInfo } from '../../../../store/sealing/android';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Accept } from 'react-dropzone';
import CachedIcon from '@mui/icons-material/Cached';

const acceptFiles: Accept = {
  'application/x-java-jks': ['.jks'],
  'application/octet-stream': ['.keystore'],
};
const ManageKeyStore = (props: { showTitle?: boolean; endBtn?: boolean }) => {
    const { t } = useTranslation();
    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { isDirty, errors },
    } = useForm();
    const dispatch = useDispatch();
    const [showMask, setShowMask] = useState<any>(null);
    const [key, setKey] = useState(0);
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const keyStoreInfo = useSelector((state: RootState) => state.androidSealingReducer.keyStoreInfo);
  
    const onUpdateClick = async (values: any) => {
        const updateKeyStoreInfo = async () => {
            const params = {
                token: authContext.token,
                keystoreFile: values.keystoreFile,
                ksPass: values.ksPass,
                keyPass: values.keyPass,
                alias: values.alias,
            };
            setShowMask({ msg: t('updating_key_store_information') });
            const response = await uploadKeyStore(params, region);
            setShowMask(null);
            if (response?.result?.code === '0000') {
                reset();
                dispatch(setKeyStoreInfo(response?.result?.keystore));
                showToast('success', t('keystore_information_updated_successfully'));
            } else if (response?.result?.code) {
                dispatch(setKeyStoreInfo(null));
                showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_update_keystore_information')));
            } else {
                dispatch(setKeyStoreInfo(null));
                showToast('error', t('failed_to_update_keystore_information'));
            }
        };

        showDialog({
            title: t('confirm_update'),
            content: t('do_you_really_want_to_update_keystore_information'),
            onOk: updateKeyStoreInfo,
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    const onDeleteKeyStoreInfo = async () => {
        const deleteKeyStoreInfo = async () => {
            const params = {
                token: authContext.token,
                alias: keyStoreInfo.alias,
                keystoreFileName: keyStoreInfo.keystoreFileName,
            };
            setShowMask({ msg: t('updating_key_store_information') });
            const response = await deleteKeyStore(params, region);
            setShowMask(null);
            if (response?.result?.code === '0000') {
                reset();
                dispatch(setKeyStoreInfo(null));
                showToast('success', t('keystore_information_deleted_successfully'));
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('failed_to_delete_keystore_information')));
            else showToast('error', t('failed_to_delete_keystore_information'));
        };

        showDialog({
            title: t('confirm_update'),
            content: t('do_you_really_want_to_delete_keystore_information'),
            onOk: deleteKeyStoreInfo,
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    const onResetClick = () => {
        reset();
        setKey(1);
    };
    const handleKeyStoreFile = (files: any) => {
        setValue('keystoreFile', files[0]);
    }
    return (
        <div className="flex h-full w-full max-w-[800px] gap-10">
            <div className="flex flex-col w-full">
                {showMask && <LoadMask msg={showMask.msg} />}
                <div className="flex justify-between w-full">
                    {props.showTitle !== false && <div className="mb-8 text-2xl font-medium w-1/2">{t('manage_keystore')}</div>}
                    {keyStoreInfo ? (
                        !props.endBtn && (
                            <Button variant="outlined" color="error" onClick={onDeleteKeyStoreInfo}>
                                {t('delete')}
                            </Button>
                        )
                    ) : (
                        <div className="w-1/2 flex justify-end gap-2">
                            <Button variant="outlined" className="w-[36px] h-[36px] p-0" color={'secondary'} disabled={!isDirty} onClick={onResetClick}>
                                {<CachedIcon />}
                            </Button>
                            <Button disabled={!isDirty} variant="contained" color="primary" type="button" onClick={handleSubmit(onUpdateClick)}>
                                {t('update')}
                            </Button>
                        </div>
                    )}
                </div>
                <div className="max-w-[320px]">
                    {keyStoreInfo ? (
                        <div className="flex flex-col gap-10">
                            <TextField size="small" label={t('account_id')} value={keyStoreInfo.account_id} fullWidth variant="outlined" inputProps={{ readOnly: true }} />
                            <TextField size="small" label={t('alias')} value={keyStoreInfo.alias} fullWidth variant="outlined" inputProps={{ readOnly: true }} />
                            <TextField size="small" label={t('keystore_file_name')} value={keyStoreInfo.keystoreFileName} fullWidth variant="outlined" inputProps={{ readOnly: true }} />
                            <hr />
                            {props.endBtn && (
                                <Button variant="outlined" color="error" onClick={onDeleteKeyStoreInfo}>
                                    {t('delete')}
                                </Button>
                            )}
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit(onUpdateClick)} className="flex flex-col gap-10">
                            <Controller
                                name="alias"
                                control={control}
                                defaultValue=''
                                rules={{ required: t('please_enter_alias_for_signing_key') }}
                                render={({ field }) => <TextField size="small" label={t('alias')} className="max-w-[320px]" fullWidth variant="outlined" error={!!errors.alias} helperText={errors.alias?.message as string}  {...field} />}
                            />
                            <Controller
                                name="ksPass"
                                control={control}
                                defaultValue=''
                                rules={{ required: t('please_enter_keystore_password') }}
                                render={({ field }) => <TextField size="small" label={t('keystore_password')}  error={!!errors.ksPass} helperText={errors.ksPass?.message as string} fullWidth variant="outlined" {...field} />}
                            />
                            <Controller
                                name="keyPass"
                                control={control}
                                defaultValue=''
                                rules={{ required: t('please_enter_key_password') }}
                                render={({ field }) => <TextField size="small" label={t('key_password')} error={!!errors.keyPass} helperText={errors.keyPass?.message as string}  fullWidth variant="outlined" {...field} />}
                            />
                            <hr />
                            <Controller
                                name="keystoreFile"
                                control={control}
                                defaultValue=''
                                rules={{ required: t('please_upload_keystore_file') }}
                                render={({ field }) => <div>
                                  <DragnDrop key={key} maxFiles={1} accept={acceptFiles} onChange={handleKeyStoreFile} title={t('click_or_drag_key_store_file')} subtitle={t('supported_ext_keystore_file')} />
                                  {errors.keystoreFile && <div className="text-warning text-xs italic">{String(errors.keystoreFile?.message)}</div>}
                                  </div>
                                }
                            />
                            
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ManageKeyStore;
