import React, { useEffect, useState } from 'react';
import DataTable from '../../../DataDisplay/Table';
import { useTranslation } from 'react-i18next';
import { GridRenderCellParams } from '@mui/x-data-grid';
import PresetDrawer from './PresetDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import ActionMenu from './PresetAction';
import { showDialog, showToast } from '../../../utils/notifications';
import { convertKeysToSnakeCase, createPresetOptions } from './PresetFunction';
import { editPreset } from '../../../../services/sealing/android';
import { setPresets } from '../../../../store/sealing/android';
import moment from 'moment';

interface PresetTableProps {
    searchValue: string;
    onSave: (data: any) => void;
    onDelete: (id: string, presetName: string) => void;
}

const PresetTable: React.FC<PresetTableProps> = ({ searchValue, onSave, onDelete }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedPreset, setSelectedPreset] = useState<any | null>(null);
    const [preset, setPreset] = useState<any>(null);
    const presetData = useSelector((state: RootState) => state.androidSealingReducer.presets);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const serviceables = useSelector((state: RootState) => state.androidSealingReducer.serviceables);
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);

    useEffect(() => {
        if (preset !== presetData) setPreset(presetData);
    }, [preset, presetData]);

    const handleEditClick = (data: any) => {
        const skipKeys = ['presetName', 'presetDescription'];
        const filteredPreset = convertKeysToSnakeCase(data, skipKeys);
        setSelectedPreset(filteredPreset);
        setTimeout(() => {
            setDrawerOpen(true);
        }, 1000);
    };

    const handleDeleteClick = (id: string, presetName: string) => {
        showDialog({
            title: t('delete_preset'),
            content: t(`delete_preset_details`, { preset_name: presetName }),
            onOk: () => onDelete(id, presetName),
            okText: t('yes'),
            cancelText: t('no'),
        });
    };

    const handleDrawerClose = () => {
      setDrawerOpen(false);
        setTimeout(() => {
            setSelectedPreset(null);
        }, 1000);
    };

    const handleEdit = async (values: any) => {
        const data = values;
        const args = createPresetOptions(data, serviceables, userDetails, selectedPreset?.id);

        const response = await editPreset(args, authContext.token);
        if (response.result.code === '0000') {
            const updatedData = preset.map((item: any) => (item.id === response.data.id ? response.data : item));
            dispatch(setPresets(updatedData));
            showToast('success', t('edited_preset_successfully'));
            handleDrawerClose();
        } else if (response?.result?.code === '14000') {
            showToast('error', t('SEALING_PRESET_NAME_IS_DUPLICATED'));
        } else {
            showToast('error', t('try_again_later'));
        }
    };

    const columns = [
        {
            headerName: t('preset_name'),
            field: 'presetName',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
        },
        {
            headerName: t('created_at'),
            field: 'createdAt',
            sortable: true,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                return moment(params.row.createdAt).format('YYYY-MM-DD HH:mm:ss');
            },
        },
        {
            headerName: t('description'),
            field: 'presetDescription',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            headerName: t('action'),
            field: 'action',
            flex: 0.3,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => {
                return <ActionMenu row={params.row} onEdit={handleEditClick} onDelete={handleDeleteClick} />;
            },
        },
    ];

    const filterData = (data: any[]) => {
        if (!searchValue) return data;
        const lowerSearchValue = searchValue.toLowerCase();
        return data.filter((item) => item.presetName && item.presetName.toLowerCase().includes(lowerSearchValue));
    };

    return (
        <>
            <DataTable height={'80%'} columns={columns} rows={filterData(preset) || []} pageSize={12} pageSizeOptions={[10, 12, 20, 30]} />
            {selectedPreset && <PresetDrawer open={drawerOpen} onSave={handleEdit} onClose={handleDrawerClose} preset={selectedPreset} />}
        </>
    );
};

export default PresetTable;
