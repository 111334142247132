import moment from "moment";
import {
  GridColDef,
  GridColumnGroupingModel,
} from "@mui/x-data-grid";
import { getLocalisedDate } from "../../utils";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { Icon, IconButton } from "@mui/material";
import DataTable from "../../DataDisplay/Table";

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: "device_details",
    headerName: "Device Details",
		headerClassName: 'text-[10px] text-subtitle font-medium border-none max-h-[20px] align-bottom self-end',
    children: [
      { field: "device_model" },
      { field: "os_version" },
      { field: "app_version" },
      { field: "android_raw_id" },
    ],
  },
  {
    groupId: "Hacking Details",
    headerName: "Hacking Details",
		headerClassName: 'text-[10px] text-subtitle font-medium border-none max-h-[20px] align-bottom self-end',
    children: [
      { field: "country" },
      { field: "cnt" },
      { field: "emul_name" },
      { field: "rooting" },
      { field: "jailbreak" },
      { field: "desc_en" },
    ],
  },
];

interface HackingAttemptsTableProps {
  rows: any[];
  platform: string;
	onShowDetailsClick: (record: any) => void;
  hackingDetection?: boolean;
}

const HackingAttemptsTable = ({
  rows,
  platform,
	onShowDetailsClick,
  hackingDetection = false,
}: HackingAttemptsTableProps) => {
  const { t, i18n } = useTranslation();
	
  let columns: GridColDef[] = [
    {
      flex: 0.1,
      field: "action",
      headerName: t("action"),
			disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton onClick={() => onShowDetailsClick(params.row)}>
          <Icon fontSize='small' component={SearchIcon} />
        </IconButton>
      ),
    },
    {
			flex: 0.2,
      field: "register_datetime",
      headerName: t("reported_on"),
      renderCell: (params) => {
        const val = params.value;
        return val
          ? moment(
              getLocalisedDate(moment(val, "YYYYMMDDHHmmss").toDate())
            ).format("YYYY/MM/DD HH:mm:ss")
          : val;
      },
    },
    {
			flex: 0.15,
      field: "core_version",
      headerName: t("appsealing_version"),
    },
    {
			flex: 0.15,
      field: "sdk_version",
      headerName: t("sdk_version"),
    },
    {
			flex: 0.15,
      field: "android_raw_id",
      headerName: t("android_id"),
    },
    {
			flex: 0.15,
      field: "device_model",
      headerName: t("model_name"),
    },
    {
			flex: 0.15,
      field: "os_version",
      headerName:
        platform === "android" ? t("android_version") : t("iOS_version"),
    },
    {
			flex: 0.15,
      field: "app_version_code",
      headerName: t("app_version"),
    },
    {
			flex: 0.15,
      field: "country",
      headerName: t("country"),
    },
    {
			flex: 0.1,
      field: "cnt",
      headerName: t("attempts"),
      renderCell: (params) => params.value.toLocaleString() || params.value,
    },
    {
			flex: 0.12,
      field: "emul_name",
      headerName: t("emulator"),
    },
    {
			flex: 0.15,
      field: "rooting",
      headerName: t("rooting"),
      renderCell: (params) =>
        params.value === "y" ? t("rooted") : t("not_rooted"),
    },
    {
			flex: 0.15,
      field: "jailbreak",
      headerName: t("jailbreak"),
      renderCell: (params) => (params.value === "0" ? t("no") : t("yes")),
    },
    {
			flex: 0.15,
      field: "desc_en",
      headerName: t("hacking_type"),
      renderCell: (params) =>
        i18n.language === "en_US" ? t(params.value) : params.row.desc_kr,
    },
  ];

  columns = columns.filter((column) => {
    if(hackingDetection){
      if(["core_version","app_version_code", "emul_name"].includes(column.field)){
        return false;
      }
    }
    if (column.field === "sdk_version" || column.field === "jailbreak") {
      return platform === "ios";
    } else if (
      column.field === "core_version" ||
      column.field === "app_version_code" ||
      column.field === "emul_name" ||
      column.field === "rooting" ||
      column.field === "android_raw_id"
    ) {
      return platform === "android";
    }
    return true;
  });

  const processedData = rows.map((v, i) => ({
    ...v,
    country: i18n.language === "en_US" ? v.country_name ?? v.country : v.country_name_kr,
    id: i,
  }));


  return (
    <>
		<div className='w-full min-h-[400px] min-w-[1000px] bg-primary hacking-attempt-table'>
      <DataTable
        columns={columns}
        rows={processedData}
        pageSize={9}
        pageSizeOptions={[9, 5, 10, 20, 40]}
				disableColumnSelector={true}
        columnGroupingModel={columnGroupingModel}
        filter={true}
      />
    </div>
		</>
  );
};

export default HackingAttemptsTable;
