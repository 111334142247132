import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getAppList } from '../../../../services/manage/settings';
import IOSServiceCard from '../../../form/field/cards/ios';
import AndroidServiceCard from '../../../form/field/cards/android';
import { filterBy } from '../../../../utility/filtering';

import { updateAdvanceService, updateService } from '../../../../services/securityservices';
import { showDialog, showToast } from '../../../utils/notifications';
import AccountTransfer from './accounttransfer';
import ManageDex from './managedex';
import CustomBlacklist from './customblacklist';
import { useTranslation } from 'react-i18next';
import ContentLayout from '../../../Layout/Content';
import { Button, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';

const ManageService = () => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState<any>([]);
    const [filterText, setFilterText] = useState('');
    const [showMask, setShowMask] = useState<any>(null);
    const [pageSize, setPageSize] = useState<number>(20);
    const [appList, setAppList] = useState<any[] | null>(null);
    const [platform, setPlatform] = useState<string>('android');
    const [currentAppData, setCurrentAppData] = useState<any>(null);
    const [isDexModalVisible, setIsDexModalVisible] = useState<boolean>(false);
    const [isTransferModalVisible, setIsTransferModalVisible] = useState<boolean>(false);
    const [isCustomBlacklistModalVisible, setIsCustomBlacklistModalVisible] = useState<boolean>(false);

    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);

    useEffect(() => {
        if (appList === null) {
            const fetchApplications = async () => {
                let data = [];
                const params = {
                    token: authContext.token,
                };
                setShowMask({ msg: t('fetching_application') });
                const response = await getAppList(params, region);
                setShowMask(null);
                if (response?.result?.code === '0000' && Array.isArray(response?.packageOnOffStatus)) {
                    data = response?.packageOnOffStatus;
                }
                setAppList(data);
            };
            fetchApplications();
        }
    }, [region, appList, authContext, t]);

    const onPlatformChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPlatform((e.target as HTMLInputElement).value);
        setFilterText('');
        setFilters([]);
        setPageSize(20);
    };

    const onSearchClick = (e: any) => {
        const value = e.target.value;
        let filter: any = [];
        if (value) {
            filter = {
                logic: 'or',
                filters: [
                    { field: 'app_name', value: value, operator: 'contains' },
                    { field: 'package_name', value: value, operator: 'contains' },
                ],
            };
        }
        setFilterText(value);
        setFilters(filter);
    };

    const updateServices = async (type: string, params: any) => {
        let data: any = null;
        if (type === 'service') {
            const response = await updateService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('app_service_is_updated_for_the_package')} ${params.package_name}`);
                data = { service_onoff: params.status };
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('app_service_failed_to_update_for_package')));
            else showToast('error', `${t('app_service_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        } else if (type === 'datasealing') {
            const response = await updateAdvanceService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('data_sealing_service_is_updated_for_the_package')} ${params.package_name}`);
                data = { use_data_sealing: params.request };
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('data_sealing_service_failed_to_update_for_package')));
            else showToast('error', `${t('data_sealing_service_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        } else if (type === 'otaservice') {
            const response = await updateAdvanceService(params, region);
            if (response?.result?.code === '0000') {
                showToast('success', `${t('ota_service_is_updated_for_the_package', 'OTA Service is updated for the package')} ${params.package_name}`);
                data = { use_ota_service: params.request };
            } else if (response?.result?.code) showToast('error', t(`RCM-${response?.result?.code}`, t('ota_failed_to_update_for_package')));
            else showToast('error', `${t('ota_failed_to_update_for_package')} ${params.package_name} ${response?.result?.message}`);
        }

        if (data && Array.isArray(appList)) {
            setAppList((oldData: any) => {
                const index = oldData.findIndex((e: any) => e.platform === platform && e.package_name === params.package_name);
                let item = oldData[index];
                oldData[index] = { ...item, ...data };
                return [...oldData];
            });
        }
    };

    const onSwitchToggle = async (type: string, params: any) => {
        let msg = '';
        switch (type) {
            case 'service':
                msg = params.status === 'Y' ? t('app_can_be_sealed_and_will_incur_a_monthly_fee') : t('app_cannot_be_sealed_and_data_will_not_collected_while_the_sealing_option_is_off');
                break;
            case 'datasealing':
                msg =
                    params.request === 'Y'
                        ? t('datasealing_option_will_be_available_while_applying_security_to_your_app')
                        : t('datasealing_is_not_supported_when_applying_security_to_your_app_any_longer');
                break;
            case 'otaservice':
                msg =
                    params.request === 'Y'
                        ? t('once_activated_you_can_manage_the_custom_blacklist_by_yourself_and_it_is_updated_over')
                        : t('custom_blacklist_option_is_not_supported_when_applying_security_to_your_app_any_longer_and_mad_is_not_counted_any_longer');
                break;
            default:
                break;
        }
        if (msg) {
            showDialog({
                title: t('confirm_update'),
                content: msg,
                onOk: () => updateServices(type, params),
                okText: t('yes'),
                cancelText: t('no'),
            });
        } else updateServices(type, params);
    };

    const onCardButtonClick = (type: string, appData: any) => {
        setCurrentAppData(appData);
        if (type === 'managedex') setIsDexModalVisible(true);
        else if (type === 'accounttransfer') setIsTransferModalVisible(true);
        else if (type === 'customblacklist') setIsCustomBlacklistModalVisible(true);
    };

    const onTransferClose = (type: string, reloadData?: any) => {
        if (type === 'managedex') setIsDexModalVisible(false);
        else if (type === 'accounttransfer') setIsTransferModalVisible(false);
        else if (type === 'customblacklist') setIsCustomBlacklistModalVisible(false);
        setCurrentAppData(null);

        if (reloadData === true) setAppList(null);
    };

    const onShowMoreClick = () => setPageSize((oldPageSize: number) => oldPageSize + 20);

    return (
        <ContentLayout fullWidth={true} showMask={showMask} btnText={t('save')}>
            <AccountTransfer visible={isTransferModalVisible} appData={currentAppData} onCancel={(e: any) => onTransferClose('accounttransfer', e)} />
            <ManageDex visible={isDexModalVisible} appData={currentAppData} onCancel={(e: any) => onTransferClose('managedex', e)} />
            <CustomBlacklist visible={isCustomBlacklistModalVisible} appData={currentAppData} onCancel={(e: any) => onTransferClose('customblacklist', e)} />
            <div className="h-full grid gap-8 pr-2 content-start">
                <div className="flex flex-col 1xl:flex-row gap-6 mb-8">
                <div className="w-full lg:w-[316px] text-2xl font-medium whitespace-nowrap">{t('manage_services')}</div>
                    <div className="flex gap-4 xl:flex-nowrap flex-wrap flex-col sm:flex-row text-center flex-1">
                        <RadioGroup className="flex flex-row items-center gap-6 text-sm" value={platform} onChange={onPlatformChange}>
                            <FormControlLabel value="android" control={<Radio />} label={t('android')} />
                            <FormControlLabel value="ios" control={<Radio />} label={t('ios')} />
                        </RadioGroup>
                        <TextField
                            className="w-full max-w-full lg:max-w-[430px] text-sm"
                            variant="outlined"
                            onChange={onSearchClick}
                            defaultValue={filterText}
                            label={t('search')}
                            size='small'
                            placeholder={platform === 'android' ? t('search_app_name_or_package_name') : t('search_app_name_or_bundle_id')}
                        />
                    </div>
                </div>
                <div className="overflow-auto" tabIndex={-1}>
                    <div className="flex flex-wrap gap-6">
                        {Array.isArray(appList) && appList.length > 0 && (
                            (filterBy(appList, filters) || [])
                                .filter((e: any) => e.platform === platform)
                                .slice(0, pageSize)
                                .map((item: any) => {
                                    let component = null;
                                    if (item.platform === 'ios' && platform === 'ios') component = <IOSServiceCard key={item.app_name + item.package_name} {...item} onToggleSwitch={onSwitchToggle} />;
                                    else if (item.platform === 'android' && platform === 'android')
                                        component = <AndroidServiceCard key={item.app_name + item.package_name} {...item} onButtonClick={onCardButtonClick} onToggleSwitch={onSwitchToggle} />;
                                    return component;
                                })
                        )}
                    </div>
                    {appList?.length && (filterBy(appList, filters) || []).filter((e: any) => e.platform === platform).length > pageSize ? (
                        <div className="w-full text-center mt-6">
                            <Button variant="text" onClick={onShowMoreClick}>
                                {t('show_more')}
                            </Button>
                        </div>
                    ) : (
                        appList?.length === 0 ? <div className="text-xl text-center py-52 mx-auto whitespace-pre">{t('please_apply_appsealing_first')}</div>
                        :(filterBy(appList, filters) || []).length === 0 && <div className="text-xl text-center py-52 mx-auto whitespace-pre">{t('no_results_found')}</div>
                    )}
                </div>
            </div>
        </ContentLayout>
    );
};

export default ManageService;
