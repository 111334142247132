import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Autocomplete, Box, FormControl, FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { saveAccountInfo } from '../../../../services/manage/accounts';
import { useDispatch } from 'react-redux';
import { setAccountDetails } from '../../../../store/manage/accounts';
import { setAuthContext } from '../../../../store/main';
import { getCities, getStates } from '../../../../services/main';
import ConfirmAccountUpdate from './confirmaccountupdate';
import { useTranslation } from 'react-i18next';
import ContentLayout from '../../../Layout/Content';
import { showToast } from '../../../utils/notifications';

const NoOfEmployeesData = [
    { label: 'Self employed', value: '1' },
    { label: '10 employees', value: '2' },
    { label: '11 to 50 employees', value: '3' },
    { label: '51 to 100 employees', value: '4' },
    { label: '101 to 500 employees', value: '5' },
    { label: '> 500 employees', value: '6' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const Account = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState<any>({});
    const [cityData, setCityData] = useState<any>([]);
    const [stateData, setStateData] = useState<any>([]);
    const [isModelVisible, setIsModelVisible] = useState(false);

    const ownerList = useSelector((state: RootState) => state.accountsReducer.ownerList);
    const countryData = useSelector((state: RootState) => state.commonReducer.countries);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const appCategories = useSelector((state: RootState) => state.commonReducer.appCategories);
    const accountDetails = useSelector((state: RootState) => state.accountsReducer.accountDetails);

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            owner: '',
            company_name: '',
            mobile: '',
            employees: '',
            country: '',
            state: '',
            city: '',
            app_category: '',
            web_site: '',
        },
    });

    useEffect(() => {
        setIsEdit(authContext.role === 'Owner');
        if (accountDetails) {
            let creationDate = `${accountDetails.account_create_date}`;
            creationDate = creationDate.slice(0, 4) + '/' + creationDate.slice(4, 6) + '/' + creationDate.slice(6, 8);

            const transformedData = {
                creationDate,
                reseller: `${accountDetails.reseller_id} ${accountDetails.reseller_name}`,
                ...accountDetails,
                country_name: accountDetails.country_name,
                country: accountDetails.country === '0' ? null : accountDetails.country,
                state: accountDetails.state || null,
                city: accountDetails.city || null,
                employees: accountDetails.employees || null,
                app_category: accountDetails.app_category === '' ? null : accountDetails.app_category,
            };

            setFormData(transformedData);
            reset(transformedData);

            if (accountDetails.country) {
                getStates(accountDetails.country, authContext.token).then((states) => {
                    setStateData(states);
                });
            }
            if (accountDetails.state) {
                getCities(accountDetails.state, authContext.token).then((cities) => {
                    setCityData(cities);
                });
            }
        }
    }, [accountDetails, authContext.role, authContext.token, reset]);

    const onConfirmUpdate = async () => {
        setIsModelVisible(false);
        const values = watch();
  
        const {
          creationDate,
          account_id,
          reseller_id,
          reseller_name,
          territory_code,
        } = formData;

        const params = {
          account_id,
          reseller_id,
          reseller_name,
          territory_code,
          token: authContext.token,
          account_create_date: creationDate,
          account_type: authContext.account_type,
          ...values,
          country: values.country ? values.country : "0",
        };

        const response = await saveAccountInfo(params);
        if (response?.result?.code === "0000") {
          setIsEdit(false);
          dispatch(
            setAccountDetails({
              ...response.account,
            })
          );
          dispatch(
            setAuthContext({
              ...response.auth,
              isAuth: true,
            })
          );
          showToast("success", t("account_information_updated_successfully"));
        } else if (response?.result?.code)
          showToast(
            "error",
            t(
              `RCM-${response?.result?.code}`,
              t("failed_to_update_account_information")
            )
          );
        else showToast("error", t("failed_to_update_account_information"));
    };

    const onFormSubmit = () => setIsModelVisible(true);

    const onCountryChange = async (value: any) => {
        let states = [];
        if (value) states = await getStates(value, authContext.token);
        setStateData(states);
        setValue('city', '');
        setValue('state', '');
    };

    const onStateChange = async (value: any) => {
        let cities = [];
        if (value) cities = await getCities(value, authContext.token);
        setCityData(cities);
        setValue('city', '');
    };

    const onFinishFailed = () => showToast('error', t('please_validate_mandatory_fields'));

    const onError = () => {
        onFinishFailed();
    };

    return (
        <ContentLayout title={t('accounts')} fullWidth={true} onClick={authContext.role === 'Owner' ? onFormSubmit : null} btnText={authContext.role === 'Owner' ? t('save') : null}>
            <ConfirmAccountUpdate visible={isModelVisible} onCancel={() => setIsModelVisible(false)} onConfirmed={onConfirmUpdate} />
            <div className="h-full flex flex-col max-w-[1027px] mb-10">
                <div className="ml-1">
                    <div className="bg-f4 px-4 py-3 mb-10  flex-none w-full rounded-sm flex gap-10 flex-wrap lg:flex-nowrap">
                        <p className="w-full max-w-full lg:w-1/3 md:max-w-[320px]">
                            <strong className="block text-darkgray">{t('account_id')}</strong>
                            <span className="block text-lightgray">{formData.account_id}</span>
                        </p>
                        <p className="w-full max-w-full lg:w-1/3 md:max-w-[320px]">
                            <strong className="block text-darkgray">{t('created_on')}</strong>
                            <span className="block text-lightgray">{formData.creationDate}</span>
                        </p>
                        <p className="w-full max-w-full lg:w-1/3 md:max-w-[320px]">
                            <strong className="block text-darkgray">{t('reseller')}</strong>
                            <span className="block text-lightgray">{formData.reseller}</span>
                        </p>
                    </div>
                    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onFormSubmit, onError)}>
                        <div className="max-w-full lg:max-w-[320px] mb-16">
                            <Controller
                                name="owner"
                                control={control}
                                rules={{ required: t('account_owner_is_required') }}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl fullWidth error={!!errors.owner}>
                                        <Autocomplete
                                            {...field}
                                            id="owner-label"
                                            options={ownerList || []}
                                            getOptionLabel={(option) => {
                                                const getOwnerDefaultValue = ownerList?.find((owner) => owner.user_id === formData.owner) || ownerList?.find((owner) => owner.user_id === option);

                                                return option && typeof option === 'object' ? option.user_id : getOwnerDefaultValue ? getOwnerDefaultValue.user_id : '';
                                            }}
                                            isOptionEqualToValue={(option, value) => option.user_id === value.user_id}
                                            disabled={!isEdit}
                                            defaultValue={ownerList && ownerList.length > 0 ? ownerList[0] : null}
                                            onChange={(event, newValue) => field.onChange(newValue)}
                                            renderInput={(params) => <TextField {...params} label={t('account_owner')} error={!!error} />}
                                        />
                                        {error && <FormHelperText>{error.message}</FormHelperText>}
                                    </FormControl>
                                )}
                            />
                        </div>
                        <div className="flex gap-10 flex-wrap mb-16 lg:flex-nowrap">
                            <Controller
                                name="country"
                                control={control}
                                render={({ field }) => (
                                    <FormControl fullWidth className="lg:w-[320px] w-full">
                                        <Autocomplete
                                            {...field}
                                            disabled={!isEdit}
                                            options={countryData}
                                            getOptionLabel={(option) => {
                                                const getCountryDefaultValue =
                                                    countryData.find((country) => country.country_id === formData.country) || countryData.find((country) => country.country_id === option);

                                                return option && typeof option === 'object'
                                                    ? i18n.language === 'kr_KR'
                                                        ? option.country_name_kr
                                                        : option.country_name
                                                    : getCountryDefaultValue
                                                      ? i18n.language === 'kr_KR'
                                                          ? getCountryDefaultValue.country_name_kr
                                                          : getCountryDefaultValue.country_name
                                                      : '';
                                            }}
                                            isOptionEqualToValue={(option, value) => option.country_id === value}
                                            onChange={(_, data) => {
                                                field.onChange(data.country_id);
                                                onCountryChange(data.country_id);
                                                setValue('state', '');
                                                setValue('city', '');
                                            }}
                                            renderInput={(params) => <TextField {...params} label={t('country')} placeholder={t('select_country')} disabled={!isEdit} fullWidth />}
                                        />
                                    </FormControl>
                                )}
                            />

                            <Controller
                                name="state"
                                control={control}
                                render={({ field }) => (
                                    <FormControl fullWidth className="lg:w-[320px] w-full">
                                        <Autocomplete
                                            {...field}
                                            disabled={!isEdit}
                                            options={stateData}
                                            getOptionLabel={(option: any) => {
                                                const getStateDefaultValue = stateData.find((state: { state_id: string }) => state.state_id === option);
                                                return option && typeof option === 'object' ? option.state_name : getStateDefaultValue ? getStateDefaultValue.state_name : '';
                                            }}
                                            onChange={(_, data: any) => {
                                                field.onChange(data.state_id);
                                                onStateChange(data.state_id);
                                                setValue('city', '');
                                            }}
                                            isOptionEqualToValue={(option, value) => option.state_id === value}
                                            renderInput={(params) => <TextField {...params} label={t('provience_state_region')} placeholder={t('select_state')} disabled={!isEdit} fullWidth />}
                                        />
                                    </FormControl>
                                )}
                            />
                            <Controller
                                name="city"
                                control={control}
                                render={({ field }) => (
                                    <FormControl fullWidth className="lg:w-[320px] w-full">
                                        <Autocomplete
                                            {...field}
                                            disabled={!isEdit}
                                            options={cityData}
                                            getOptionLabel={(option: any) => {
                                                const getCityDefaultValue = cityData.find((city: { city_id: string }) => city.city_id === option);
                                                return option && typeof option === 'object' ? option.city_name : getCityDefaultValue ? getCityDefaultValue.city_name : '';
                                            }}
                                            onChange={(_, data: any) => field.onChange(data.city_id)}
                                            isOptionEqualToValue={(option: any, value) => option.city_id === value}
                                            renderInput={(params) => <TextField {...params} label={t('city')} placeholder={t('select_city')} disabled={!isEdit} fullWidth />}
                                        />
                                    </FormControl>
                                )}
                            />
                        </div>
                        <div className="flex gap-10 flex-wrap mb-16 lg:flex-nowrap">
                            <Controller
                                name="company_name"
                                control={control}
                                render={({ field }) => <TextField {...field} fullWidth className="lg:w-[320px] w-full" label={t('company_name')} disabled={!isEdit} />}
                            />
                            <Controller
                                name="mobile"
                                control={control}
                                render={({ field }) => <TextField {...field} label={t('telephone')} type="tel" fullWidth className="lg:w-[320px] w-full" disabled={!isEdit} />}
                            />
                            <Controller
                                name="employees"
                                control={control}
                                render={({ field }) => (
                                    <FormControl fullWidth className="lg:w-[320px] w-full">
                                        <InputLabel className={`${!isEdit ? 'text-[#9F9F9F]' : ''} mt-1`} disabled={field.disabled}>
                                            {t('number_of_employee')}
                                        </InputLabel>
                                        <Select
                                            {...field}
                                            label={t('number_of_employee')}
                                            renderValue={(selected) => NoOfEmployeesData.find((option) => option.value === selected)?.label || t('select_number_of_employee')}
                                            disabled={!isEdit}
                                        >
                                            {NoOfEmployeesData.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </div>

                        <div className="flex gap-10 flex-wrap mb-16 lg:flex-nowrap">
                            <Controller
                                name="web_site"
                                control={control}
                                render={({ field }) => <TextField {...field} label={t('website')} disabled={!isEdit} fullWidth className="lg:w-[320px] w-full" />}
                            />
                            <Controller
                                name="app_category"
                                control={control}
                                render={({ field }) => (
                                    <FormControl fullWidth className="lg:w-[320px] w-full">
                                        <InputLabel id="app-category-label">{t('apps_category')}</InputLabel>
                                        <Select
                                            {...field}
                                            labelId="app-category-label"
                                            disabled={!isEdit}
                                            label={t('apps_category')}
                                            placeholder={t('select_apps_category')}
                                            onChange={(event) => field.onChange(event.target.value)}
                                            MenuProps={MenuProps}
                                        >
                                            {appCategories.map((app) => (
                                                <MenuItem key={app.category_id} value={app.category_id}>
                                                    {i18n.language === 'kr_KR' ? app.category_kr : app.category_en}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                            <div className="lg:w-[320px] w-full"></div>
                        </div>
                    </Box>
                </div>
            </div>
        </ContentLayout>
    );
};

export default Account;
