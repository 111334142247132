import React, { useEffect, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface Option {
    [key: string]: { text: string };
}

interface FilterField {
    name: string;
    type: 'input' | 'number' | 'datepicker' | 'select';
    label: string;
    placeholder?: string;
    options?: Option;
    disablePast?: any;
}

interface Props {
    fields: FilterField[];
    control: Control<any>;
    actionElement?: React.ReactNode;
    maxDate?: moment.Moment;
    onChange?: (key: string, value: string) => void;
    periodFrom?: moment.Moment;
}

const FilterSearch: React.FC<Props> = ({ fields, control, actionElement = false, maxDate, onChange = false, periodFrom }) => {
    const { t } = useTranslation();
    const [fromDate, setFromDate] = useState<moment.Moment | null>(periodFrom ?? null);
    const [toDate, setToDate] = useState<moment.Moment | null>(null);

    const handleOnChange = (key: string, value: string) => {
        if (typeof onChange === 'function') {
            onChange(key, value);
        }

        if(key === 'period_from') {
            setFromDate(moment(value));
        }
        if(key === 'period_to') {
            setToDate(moment(value));
        }

    }

    const handleMinDate = (item: any) => {
        if(item.name === 'period_from') {
            return item.disablePast || undefined
        }else{
            return fromDate || undefined
        }
    }
    return (
        <div className="flex flex-auto flex-wrap xl:flex-nowrap xl:mt-0 my-4 w-full">
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 w-full">
                    {fields.map((item) => {
                        switch (item.type) {
                            case 'input':
                                return (
                                    <Controller
                                        key={item.name}
                                        name={item.name}
                                        control={control}
                                        render={({ field }) => <TextField {...field} size="small" label={t(item.label)} placeholder={t(item.placeholder || '')} fullWidth />}
                                    />
                                );
                            case 'number':
                                return (
                                    <Controller
                                        key={item.name}
                                        name={item.name}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                size="small"
                                                type="number"
                                                label={t(item.label)}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                placeholder={t(item.placeholder || '')}
                                                fullWidth
                                            />
                                        )}
                                    />
                                );
                            case 'datepicker':
                                return (
                                    <Controller
                                        key={item.name}
                                        name={item.name}
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <DatePicker
                                                    {...field}
                                                    slotProps={{ textField: { size: 'small', value: item.name === 'period_from' ? fromDate : toDate } }}
                                                    label={t(item.label)}
                                                    minDate={handleMinDate(item)}
                                                    maxDate={maxDate ? maxDate : undefined}
                                                    views={['year', 'month']}
                                                    openTo="month"
                                                    defaultValue={item.name === 'period_from' ? fromDate || undefined : undefined}
                                                    format="YYYY-MM"
                                                    disableFuture
                                                    onChange={(date) => handleOnChange(item.name, date)}
                                                />
                                                {!!error && <FormHelperText>{t('please_enter_from_period')}</FormHelperText>}
                                            </>
                                        )}
                                    />
                                );
                            case 'select':
                                return (
                                    <Controller
                                        key={item.name}
                                        name={item.name}
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl fullWidth size="small">
                                                <InputLabel id={item.name}>{t(item.label)}</InputLabel>
                                                <Select {...field} labelId={item.name} size="small" label={item.label} placeholder={t(item.placeholder || '')}>
                                                    {item.options
                                                        ? Object.entries(item.options).map(
                                                              ([key, { text }]) =>
                                                                  key !== 'N/A' && (
                                                                      <MenuItem key={key} value={key}>
                                                                          {text}
                                                                      </MenuItem>
                                                                  ),
                                                          )
                                                        : null}
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                );
                            default:
                                return null;
                        }
                    })}
                </div>
            </LocalizationProvider>
            {actionElement && <div>{actionElement}</div>}
        </div>
    );
};

export default FilterSearch;
